import z from 'zod'
import { MediaType } from '../../types'
import { nullSafeFirestoreTimestamp } from '../shared/schema'

// Base schema with common fields
const baseSchema = z.object({
  mediaTitle: z.string(),
  mediaStorageURL: z.string(),
  mediaAltText: z.string().optional(),
  mediaFileName: z.string().optional(),
  updatedAt: nullSafeFirestoreTimestamp(),
  mediaUploadedAt: nullSafeFirestoreTimestamp(),
  hidden: z.boolean().optional(),
})

// Video-specific schema
const videoSchema = baseSchema.extend({
  mediaType: z.literal(MediaType.Video),
  mediaVideoURL: z.string(),
  mediaVideoDuration: z.number().optional(),
  mediaVideoError: z.string().optional(),
  // Read-only fields
  mediaVideoStreamingURL: z.string().optional(),
  mediaStorageStreamingURL: z.string().optional(),
})

// Image-specific schema
const imageSchema = baseSchema.extend({
  mediaType: z.literal(MediaType.Image),
  mediaImageURL: z.string(),
  // These fields should not exist for images
  mediaVideoURL: z.undefined(),
  mediaVideoDuration: z.undefined(),
  mediaVideoError: z.undefined(),
  mediaVideoStreamingURL: z.undefined(),
  mediaStorageStreamingURL: z.undefined(),
})

// Write schema for video
const writeVideoSchema = videoSchema.extend({
  mediaVideoStreamingURL: z.undefined(),
  mediaStorageStreamingURL: z.undefined(),
})

// Combined write schema
export const writeSchema = z.discriminatedUnion('mediaType', [
  writeVideoSchema,
  imageSchema,
])

// Combined schema
const schema = z.discriminatedUnion('mediaType', [
  videoSchema.extend({
    mediaType: z.literal(MediaType.Video),
    mediaTitle: z.string(),
    mediaAltText: z.string().optional(),
    mediaStorageURL: z.string(),
  }),
  imageSchema,
])

export const empty = Object.freeze(
  schema.parse({
    mediaType: MediaType.Video,
    mediaStorageURL: '',
    mediaAltText: '',
    mediaTitle: '',
    mediaUploadedAt: { seconds: 0, nanoseconds: 0 },
    mediaVideoURL: '',
    mediaVideoDuration: 0,
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

// Types
export type FirestoreMedia = z.infer<typeof schema>
export type FirestoreVideoMedia = z.infer<typeof videoSchema>
export type FirestoreImageMedia = z.infer<typeof imageSchema>

export { schema }
