import type { UserPromotion } from '@breakoutlearning/firebase-repository/models/UserPromotion'
import { Pill } from 'components/breakout/Pill'
import { Dialog } from 'components/dialogs/Dialog'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { DialogCloseButton } from './dialogs/DialogCloseButton'
import { DoubleCheckIcon } from './icons/DoubleCheck'

type PropsWithUserPromotion = {
  promotion: UserPromotion
}

const UserPromotionBox = observer(function SectionBox({
  promotion,
}: PropsWithUserPromotion) {
  const { t } = useTranslation()
  const expirationDateTime = DateTime.fromJSDate(promotion.data.expirationDate)
  return (
    <div className="mx-auto my-8  h-[250px] w-[250px] rounded-2xl bg-core-secondary shadow-3xl">
      <div className="flex h-full flex-col p-2">
        <div className="text-right">
          <Pill
            icon={<DoubleCheckIcon size={14} className="-mt-1 inline" />}
            label={'Applied'}
          />
        </div>
        <div className="flex flex-1 flex-col items-start justify-center pl-2">
          <div className="text-headline-small">
            {promotion.data.promotionName}
          </div>
          <div className="text-body-medium text-grey-text">
            {t('promotion.consumable_count', {
              count: promotion.data.consumableCount,
            })}
          </div>
        </div>
        <div className="ml-2 h-10 flex-grow-0 text-left text-body-medium text-grey-text">
          {t('promotion.valid_till', {
            date: expirationDateTime.toLocaleString(DateTime.DATE_MED),
          })}
        </div>
      </div>
    </div>
  )
})

export function PromotionAcceptedDialog({ promotion }: PropsWithUserPromotion) {
  const { t } = useTranslation()
  return (
    <Dialog size="xs" className="text-center text-core-primary">
      <DialogCloseButton />
      <div>
        <div className="mb-2 text-body-medium text-grey-text">
          {t('promotion.promotion')}
        </div>
        <div className="text-headline-medium">
          {t('promotion.promotion_applied')}
        </div>
      </div>
      <div className="my-20">
        <UserPromotionBox promotion={promotion} />
      </div>
    </Dialog>
  )
}
