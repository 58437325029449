import { Spinner } from 'components/Spinner'
import { useBreakoutUser } from 'hooks/profile'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import { InstructorLoader } from 'pages/instructor/InstructorLoader'
import { StudentLibrary } from 'pages/student/library/StudentLibrary'
import { useMemo } from 'react'

export const HomePage = observer(function HomePage() {
  const user = useBreakoutUser()
  const store = useRootStore()

  const [isAssignmentRoute, isHomeRoute] = useMemo(() => {
    const path = store.router.currentRoute?.path || ''
    return [path.startsWith('/assignment'), path.includes('/home')]
  }, [store.router.currentRoute?.path])

  if (user.isLoading)
    return (
      <div className="flex h-full w-full flex-row items-center justify-center">
        <Spinner />
      </div>
    )

  if (user.isFaculty && !isAssignmentRoute && !isHomeRoute) {
    return <InstructorLoader page="instructorClasses" />
  }
  return <StudentLibrary />
})
