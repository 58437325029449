import classNames from 'classnames'
import { LockOn } from 'components/icons/LockOn'
import { useStudentAssignmentCubit } from 'hooks/cubits/studentAssignment'
import { useDialogs } from 'hooks/dialogs'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { MaterialsModal } from './MaterialsModal'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { useMemo } from 'react'
import { LibraryObjectState } from '@breakoutlearning/firebase-repository/types'

export const StudentAssignmentInfoLoading = () => {
  return (
    <div className="mt-5 w-full flex-grow rounded-3xl bg-core-tertiary py-10 lg:mr-5 lg:mt-0 lg:h-full lg:max-w-[35%]">
      <div className="h-full max-h-full w-full">
        <div className="flex h-full flex-col items-center justify-center overflow-auto">
          Loading...
        </div>
      </div>
    </div>
  )
}

export const StudentAssignmentInfo = observer(function StudentAssignmentInfo() {
  const cubit = useStudentAssignmentCubit()
  const libraryObject = cubit.libraryObject
  const { showDialog } = useDialogs()
  const { t } = useTranslation()
  const section = libraryObject.section
  const assignment = libraryObject.assignment
  const slideDeckData = libraryObject.slideDeck.data

  const lockMaterials =
    !libraryObject.hasAccessToSlideDeckContents ||
    assignment.assignedAtIsInFuture ||
    assignment.data.assignmentState < 1 //draft or unconfigured

  const showMaterials = useMemo(() => {
    // if materials are locked, we are only showing an icon, so always render lock icon
    if (lockMaterials) {
      return true
    }
    const materials = cubit.materials.models
    // if no materials do not render
    if (materials.length === 0) {
      return false
    }
    // if assignment is completed, alaways show materials
    if (libraryObject.libraryObjectState === LibraryObjectState.completed) {
      return true
    }

    // if not completed but there are materials that are not post work, show materials
    if (materials.some((m) => !m.isPostWork)) return true

    return false
  }, [cubit.materials.models, libraryObject.libraryObjectState, lockMaterials])

  return (
    <div className="mt-5 flex-grow rounded-3xl bg-core-tertiary md:h-full lg:mr-5 lg:mt-0 lg:max-w-[50%]">
      <div className="max-h-full md:h-full">
        <div className="items-left flex flex-col justify-between md:h-full md:overflow-auto">
          <div className="top mt-8 flex items-center px-8">
            <div>
              {slideDeckData.slideDeckImageURL && (
                <img
                  src={slideDeckData.slideDeckImageURL}
                  alt={slideDeckData.slideDeckName}
                  className="max-h-[50px] w-auto"
                />
              )}
            </div>
            <div className="mx-5 h-full w-[1px] bg-surface-dim" />
            <div className="flex-grow">
              <div className="text-label-large">
                {section.data.className} - {section.data.sectionName}
              </div>
              <div className="text-label-small text-fixed-grey">
                {section.instructor.fullName}
              </div>
            </div>
            {cubit.estimatedExperienceDurationInMinutes > 0 && (
              <div className="flex flex-col items-center rounded-xl border border-outline-variant px-2 py-1">
                <div className="text-label-large">
                  {cubit.estimatedExperienceDurationInMinutes}
                </div>
                <div className="text-label-small text-fixed-grey">
                  {t('student_assignment.mins')}
                </div>
              </div>
            )}
          </div>
          <div className="mb-1 mt-5 px-9 text-body-medium text-fixed-grey">
            {t('student_assignment.experience_description')}
          </div>
          <div
            tabIndex={0}
            className="description isolate mb-2 mr-6 mt-2 h-[200px] flex-grow overflow-y-auto bg-core-tertiary px-9 pr-5 text-xs leading-5 text-core-on-tertiary md:h-auto md:min-h-32"
          >
            {slideDeckData.slideDeckDescription}
          </div>
          <div className="bottom flex-grow-0 px-8 pb-8">
            <div className="flex min-h-[100px] flex-row gap-1 md:mt-2">
              <div className="flex w-full flex-1  items-center justify-center rounded-lg bg-surface">
                <div>
                  {assignment.assignedAt && (
                    <BreakoutTooltip
                      content={assignment.formattedAssignedAtWithTime}
                    >
                      <p className="mb-1 cursor-default text-body-medium text-fixed-grey">
                        {t('student_assignment.start_date')}:{' '}
                        <strong className="text-label-medium text-on-surface">
                          {assignment.formattedAssignedAt}
                        </strong>
                      </p>
                    </BreakoutTooltip>
                  )}
                  <BreakoutTooltip
                    content={assignment.formattedExpiresAtWithTime}
                    enabled={!!assignment.formattedExpiresAt}
                  >
                    {assignment.formattedExpiresAt && (
                      <p className="cursor-default text-body-medium text-fixed-grey">
                        {t('student_assignment.deadline')}:{' '}
                        <strong className="text-label-medium text-on-surface">
                          {assignment.formattedExpiresAt}
                        </strong>
                      </p>
                    )}
                  </BreakoutTooltip>
                </div>
              </div>
              {showMaterials && (
                <div className="w-full flex-1 rounded-lg bg-surface">
                  <div
                    tabIndex={0}
                    role={!lockMaterials ? 'button' : undefined}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault()
                        if (lockMaterials) return
                        showDialog(() => (
                          <div>{<MaterialsModal cubit={cubit} />}</div>
                        ))
                      }
                    }}
                    onClick={
                      lockMaterials
                        ? undefined
                        : () => {
                            showDialog(() => (
                              <div>{<MaterialsModal cubit={cubit} />}</div>
                            ))
                          }
                    }
                    className={classNames(
                      'flex h-full flex-col items-center justify-center p-4',
                      {
                        'cursor-pointer': !lockMaterials,
                      }
                    )}
                  >
                    {lockMaterials && (
                      <LockOn size={20} className="mt-4 stroke-grey-text" />
                    )}
                    <h3
                      className={classNames('text-xs text-grey-text', {
                        'mt-4': !lockMaterials,
                        'mb-4': lockMaterials,
                      })}
                    >
                      {t('student_assignment.course_materials')}
                    </h3>
                    {!lockMaterials && (
                      <strong className="mb-4 mt-3 cursor-pointer text-label-medium text-core-on-tertiary">
                        {t('student_assignment.view_details')} &gt;
                      </strong>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
