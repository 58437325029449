import React, { useId, forwardRef, useMemo, createRef, useState } from 'react'

// utils
import { RippleEffect } from './RippleEffect'
import type { FormFieldDefaults } from './types'
import { FormError } from './form/FormError'
import classNames from 'classnames'

interface CheckboxProps extends Omit<FormFieldDefaults<'input'>, 'label'> {
  labelProps?: React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >
  containerProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
  value?: boolean
  labelPosition?: 'left' | 'right'
  inputRef?: React.Ref<HTMLInputElement>
  label?: string | React.ReactNode
}

export const BreakoutCheckbox = forwardRef<HTMLInputElement, CheckboxProps>(
  function BreakoutCheckbox(
    {
      label,
      labelPosition = 'right',
      disabled,
      containerProps,
      labelProps,
      inputRef: passedRef,
      error,
      errorClass,
      testId,
      name,
      checked: checkedProp,
      value,
      onChange,
      ...rest
    },
    ref
  ) {
    // Works with either controlled or uncontrolled state.
    const [internalChecked, setInternalChecked] = useState(
      value || rest.defaultChecked || false
    )

    const checkedValue =
      checkedProp !== undefined ? checkedProp : internalChecked

    const checkboxId = useId()

    const rippleEffect = new RippleEffect()

    const inputRef = useMemo(() => {
      if (passedRef && typeof passedRef !== 'function') {
        return passedRef
      }
      return createRef<HTMLInputElement>()
    }, [passedRef])

    const handleToggle = () => {
      if (disabled) return

      const newChecked = !checkedValue

      if (checkedProp === undefined) {
        // We only update internal state if we're uncontrolled
        setInternalChecked(newChecked)
      }

      typeof onChange === 'function' && onChange(newChecked)
    }

    return (
      <>
        <div ref={ref}>
          <div
            {...containerProps}
            className={classNames(
              'inline-flex items-center',
              {
                'cursor-pointer': !disabled,
                'cursor-not-allowed': disabled,
              },
              containerProps?.className
            )}
            onMouseDown={handleToggle}
          >
            {label && labelPosition === 'left' && (
              <label
                {...labelProps}
                className={classNames('select-none text-body-medium', {
                  'cursor-pointer': !disabled,
                  'cursor-not-allowed': disabled,
                })}
                htmlFor={rest.id || checkboxId}
              >
                {typeof label === 'string' ? label : <div>{label}</div>}
              </label>
            )}
            <label
              className={classNames(
                'relative flex shrink-0 items-center rounded-full p-3',
                {
                  'cursor-not-allowed': disabled,
                }
              )}
              title={typeof label === 'string' ? label : undefined}
              htmlFor={rest.id || checkboxId}
              onMouseDown={(e) => {
                if (rippleEffect) rippleEffect.createFromEvent(e, 'dark')
              }}
            >
              <input
                {...rest}
                checked={checkedValue}
                ref={inputRef}
                type="checkbox"
                disabled={disabled}
                onChange={function () {}}
                data-testid={testId || name ? `${name}-checkbox` : undefined}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleToggle()
                  }
                }}
                className={classNames(
                  "border-blue-gray-200 before:content[''] before:bg-blue-gray-500 peer relative h-5 w-5 shrink-0 appearance-none rounded-md border bg-core-tertiary transition-all before:absolute before:left-2/4 before:top-2/4 before:block before:h-12 before:w-12 before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-full before:opacity-0 before:transition-opacity checked:border-core-primary checked:bg-core-primary checked:before:bg-core-primary hover:before:opacity-10",
                  {
                    'opacity-50': disabled,
                    'cursor-pointer': !disabled,
                    'cursor-not-allowed': disabled,
                  }
                )}
                id={rest.id || checkboxId}
              />
              <span
                className={classNames(
                  'pointer-events-none absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-core-on-primary opacity-0 transition-opacity peer-checked:opacity-100'
                )}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth={1}
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </label>
            {label && labelPosition === 'right' && (
              <label
                {...labelProps}
                className={classNames('select-none text-body-medium', {
                  'cursor-pointer': !disabled,
                  'cursor-not-allowed': disabled,
                })}
                htmlFor={rest.id || checkboxId}
              >
                {typeof label === 'string' ? label : <div>{label}</div>}
              </label>
            )}
          </div>
          <div className="block w-full">
            <FormError error={error} errorClass={errorClass} />
          </div>
        </div>
      </>
    )
  }
)
