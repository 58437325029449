import { AssignmentGroupingType } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import { InfoIcon } from 'components/icons/Info'
import type { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { calculateGroupingDate } from './calculateGroupingDate'

export const GroupingMethodDetails = ({
  groupingMethod,
  assignedAt,
  expiresAt,
}: {
  groupingMethod: AssignmentGroupingType
  expiresAt: DateTime | null
  assignedAt: DateTime | null
}) => {
  const { t } = useTranslation()

  const groupingDate = useMemo(() => {
    const date = calculateGroupingDate(
      expiresAt?.toISO(),
      assignedAt?.toISO()
    )?.toJSDate()

    if (!date) return undefined

    const datePart = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }).format(date)

    const timePart = new Intl.DateTimeFormat('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    }).format(date)

    return `${datePart} (${timePart})`
  }, [assignedAt, expiresAt])

  return (
    <div className="flex flex-col gap-1 px-4">
      <div className="flex flex-row items-center gap-1">
        <InfoIcon size={20} className="fill-fixed-accent-color" />
        <strong className="text-body-medium font-bold">
          {t('instructor_library.how_it_works')}
        </strong>
      </div>
      <ul className="ml-4.5 list-outside list-disc text-body-medium">
        {groupingMethod === AssignmentGroupingType.manual && (
          <>
            <li>{t('instructor_library.grouping_method_details.manual.1')}</li>
            <li>{t('instructor_library.grouping_method_details.manual.2')}</li>
            <li>
              <Trans
                i18nKey="instructor_library.grouping_method_details.manual.3"
                components={{
                  bold: <strong className="font-bold" />,
                }}
              />
            </li>
          </>
        )}
        {groupingMethod === AssignmentGroupingType.automaticRandom && (
          <>
            <li>
              {t('instructor_library.grouping_method_details.automatic.1')}
            </li>
            {groupingDate && (
              <li>
                <Trans
                  i18nKey="instructor_library.grouping_method_details.automatic.2"
                  components={{
                    bold: <strong className="text-nowrap font-bold" />,
                  }}
                  values={{ date: groupingDate }}
                />
              </li>
            )}
            <li>
              <Trans
                i18nKey="instructor_library.grouping_method_details.automatic.3"
                components={{
                  bold: <strong className="font-bold" />,
                }}
              />
            </li>
          </>
        )}
      </ul>
    </div>
  )
}
