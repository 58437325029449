import { useBreakoutUser } from 'hooks/profile'
import type { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { ChevronRight } from 'components/icons/ChevronRight'
import { ProfilePages } from '../../types/ProfilePages'
import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'

export const ProfileNavigation = ({
  selectedPage,
  setSelectedPage,
}: {
  selectedPage: ProfilePages
  setSelectedPage: Dispatch<SetStateAction<ProfilePages>>
}) => {
  const breakoutUser = useBreakoutUser()
  const { t } = useTranslation()

  return (
    <>
      <div className="block md:hidden">
        <BreakoutSelect
          kind="secondary"
          inputClassName="mb-5"
          value={selectedPage}
          onChange={(value) => setSelectedPage(value)}
          options={
            [
              {
                value: ProfilePages.details,
                label: t('profile.personal_details'),
              },
              {
                value: ProfilePages.transactions,
                label: t('profile.transactions'),
              },
              { value: ProfilePages.avTest, label: t('profile.av_test') },
              {
                value: ProfilePages.podcast,
                label: t('profile.your_podcasts'),
              },
              breakoutUser.isInstructor && {
                value: ProfilePages.promotions,
                label: t('profile.promotions'),
              },
              breakoutUser.isInternal && {
                value: ProfilePages.settings,
                label: t('profile.settings'),
              },
            ].filter(Boolean) as { value: ProfilePages; label: string }[]
          }
        />
      </div>
      <div className="hidden h-full w-full max-w-[400px] rounded-3xl  bg-surface p-5 md:block">
        <div className="h-full overflow-y-auto p-5">
          <div className="flex items-center">
            <div className="mr-2 shrink-0">
              <BreakoutUserAvatar radius={25} user={breakoutUser.publicUser} />
            </div>
            <div>
              <div className="text-title-large text-core-on-secondary">
                {breakoutUser.firstName} {breakoutUser.lastName}
              </div>
              <div className="text-body-medium text-on-surface-var">
                {breakoutUser.emailAddress}
              </div>
            </div>
          </div>
          <div className="mt-5 space-y-2 ">
            <NavigationOption
              label={t('profile.personal_details')}
              isSelected={selectedPage === ProfilePages.details}
              onClick={() => setSelectedPage(ProfilePages.details)}
              dataTestId="profile-personal-details"
            />
            <NavigationOption
              label={t('profile.transactions')}
              isSelected={selectedPage === ProfilePages.transactions}
              onClick={() => setSelectedPage(ProfilePages.transactions)}
              dataTestId="profile-transactions"
            />
            <NavigationOption
              label={t('profile.av_test')}
              isSelected={selectedPage === ProfilePages.avTest}
              onClick={() => setSelectedPage(ProfilePages.avTest)}
              dataTestId="profile-av-test"
            />
            <NavigationOption
              label={t('profile.your_podcasts')}
              isSelected={selectedPage === ProfilePages.podcast}
              onClick={() => setSelectedPage(ProfilePages.podcast)}
              dataTestId="profile-podcasts"
            />
            {breakoutUser.isInstructor && (
              <NavigationOption
                label={t('profile.promotions')}
                isSelected={selectedPage === ProfilePages.promotions}
                onClick={() => setSelectedPage(ProfilePages.promotions)}
                dataTestId="profile-promotions"
              />
            )}
            {breakoutUser.isInternal && (
              <NavigationOption
                label={t('profile.settings')}
                isSelected={selectedPage === ProfilePages.settings}
                onClick={() => setSelectedPage(ProfilePages.settings)}
                dataTestId="profile-settings"
              />
            )}
            <NavigationOption
              label={t('profile.privacy_policy')}
              isSelected={false}
              onClick={() => {
                window.open(
                  'https://www.breakoutlearning.com/legal/privacy-policy',
                  '_blank'
                )
              }}
              dataTestId="profile-privacy-policy"
            />
          </div>
        </div>
      </div>
    </>
  )
}

const NavigationOption = ({
  label,
  isSelected,
  onClick,
  dataTestId,
}: {
  label: string
  isSelected: boolean
  onClick: () => void
  dataTestId?: string
}) => {
  return (
    <button
      data-testid={dataTestId}
      className={classNames(
        'group flex h-[52px] w-full cursor-pointer items-center justify-between rounded-2xl bg-core-tertiary px-4 text-label-medium text-core-on-tertiary hover:ring-1 hover:ring-outline-variant',
        {
          'ring-1 ring-outline-variant': isSelected,
        }
      )}
      onClick={onClick}
    >
      {label}
      {isSelected && (
        <div>
          <ChevronRight size={15} />
        </div>
      )}
    </button>
  )
}
