import type { FirebaseRepository } from '../models/FirebaseRepository'
import { Cubit } from './core'
import {
  createMedia,
  fetchAllMedia,
  getMedia,
  getMediaList,
  hideMedia,
  unhideMedia,
  updateMedia,
  updateMediaFile,
} from '../firestore/Media'
import { action } from 'mobx'
import { Media } from '../models/Media'
import type { FirestoreMedia } from '../firestore/Media/schema'
import type { StaticModelCollection } from '../firestore-mobx/model'
import { MediaType } from '../types'
import { getDownloadURL, ref } from 'firebase/storage'

export class MediaCubit extends Cubit {
  repository: FirebaseRepository
  media: StaticModelCollection<Media>
  stream: boolean = false

  constructor(
    repository: FirebaseRepository,
    options?: {
      stream?: boolean
    }
  ) {
    super()
    this.repository = repository
    this.stream = options?.stream ?? false
    this.media = Media.emptyCollection(repository)
  }

  initialize(): void {
    if (this.stream) {
      this.addStream(getMediaList(this.repository), (media) => {
        const sortedMedia = media.sort((a, b) => {
          if (!a.data.updatedAt || !b.data.updatedAt) {
            return 0
          }
          return b.data.updatedAt.getTime() - a.data.updatedAt.getTime()
        })
        this.media.replaceModels(sortedMedia)
      })
    }
  }

  @action
  async fetchAllMedia(): Promise<Media[]> {
    try {
      return await fetchAllMedia(this.repository)
    } catch (error) {
      console.error('Error fetching all media:', error)
      return []
    }
  }

  @action
  createMedia = (
    media: Pick<FirestoreMedia, 'mediaType' | 'mediaTitle' | 'mediaAltText'>,
    file: File
  ) => {
    return createMedia(this.repository, media, file)
  }

  getMedia = (mediaId: string) => {
    return getMedia(this.repository, { mediaId })
  }

  @action
  updateMedia = (
    mediaId: string,
    media: Pick<
      FirestoreMedia,
      'mediaTitle' | 'mediaAltText' | 'mediaVideoError' | 'mediaVideoDuration'
    >
  ) => {
    return updateMedia(this.repository, mediaId, media)
  }

  @action
  updateMediaFile = (mediaId: string, file: File) => {
    return updateMediaFile(this.repository, mediaId, file)
  }

  @action
  hideMedia = (mediaId: string) => {
    return hideMedia(this.repository, mediaId)
  }

  @action
  unhideMedia = (mediaId: string) => {
    return unhideMedia(this.repository, mediaId)
  }

  @action
  downloadMedia = async (media: Media) => {
    let url
    switch (media.data.mediaType) {
      case MediaType.Image:
        url = media.data.mediaImageURL
        break
      case MediaType.Video:
        url = media.data.mediaVideoURL
        break
      default:
        throw new Error('Invalid media type')
    }

    // Get a download url from firebase storage
    const downloadUrl = await getDownloadURL(ref(this.repository.storage, url))

    // Fetch the file
    const response = await fetch(downloadUrl)
    const blob = await response.blob()

    // Create object URL and trigger download
    const objectUrl = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = objectUrl
    a.download = media.data.mediaTitle
    document.body.appendChild(a)
    a.click()

    // Clean up
    document.body.removeChild(a)
    window.URL.revokeObjectURL(objectUrl)
  }
}
