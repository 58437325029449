import type { MeetingResultsSlide } from '@breakoutlearning/firebase-repository/cubits/meeting/slides/MeetingResultsSlide'
import { MeetingResultsCubit } from '@breakoutlearning/firebase-repository/cubits/MeetingResultsCubit'
import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { useRepository } from 'hooks/auth'
import { useCubitBuilder } from 'hooks/cubits'
import {
  MeetingResultsCubitProvider,
  useMeetingResultsCubit,
} from 'hooks/cubits/meetingsResults'
import { useBreakoutUser } from 'hooks/profile'
import { PreviewMeetingResultsCubit } from '@breakoutlearning/firebase-repository/cubits/PreviewMeetingResultsCubit'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { ConversationMap } from './MeetingResultsSlideView2/ConversationMap'
import { Disclaimer } from './MeetingResultsSlideView2/Disclaimer'
import { GroupBalanceScore } from './MeetingResultsSlideView2/GroupBalanceScore'
import { Header } from './MeetingResultsSlideView2/Header'
import { IndividualEvaluation } from './MeetingResultsSlideView2/IndividualEvaluation'
import type { RoomStateEngagement } from '@breakoutlearning/firebase-repository/models/RoomStateEngagement'
import type { RoomStateRubricResultDetail } from '@breakoutlearning/firebase-repository/models/RoomStateRubricResultDetail'
import type { RoomStateRubricResult } from '@breakoutlearning/firebase-repository/models/RoomStateRubricResult'
import { useTranslation } from 'react-i18next'
import { QuizResultsSection } from './MeetingResultsSlideView2/QuizResultsSection'

export const MeetingResultsSlideView2 = observer(
  function MeetingResultsSlideView2({
    slide,
    inDialog,
    seedData,
  }: {
    slide: MeetingResultsSlide
    inDialog: boolean
    /**
     * Seed data provided to the meeting results cubit. When supplied it populate the cubit models
     * and skip fetching all data with the exception of slide rubrics
     */
    seedData?: {
      roomStateEngagement: RoomStateEngagement[]
      rubricResultDetails: RoomStateRubricResultDetail[]
      rubricResults: RoomStateRubricResult[]
    }
  }) {
    const user = useBreakoutUser()
    const cubit: MeetingResultsCubit = useCubitBuilder(() => {
      /**
       * when rendering results view from a preview meeting
       * we use the PreviewMeetingResultsCubit, which inits the mock data
       */
      const ResultsCubitForEnvironment = slide.meeting.useMockResults
        ? PreviewMeetingResultsCubit
        : MeetingResultsCubit

      return new ResultsCubitForEnvironment(slide.meeting.repository, {
        roomStateId: slide.meeting.roomState.id,
        slideDeckId: slide.meeting.slideDeckId,
        meetingCubit: slide.meeting,
        userId: user.user.id,
        inDialog,
        seedData,
      })
    }, [slide, user])

    const showUserSelect =
      !cubit.meetingCubit.useMockResults &&
      (user.isCorre || (user.isInstructor && cubit.meetingCubit.isPreview))

    return (
      <MeetingResultsCubitProvider value={cubit}>
        <div
          data-testid="meeting-results-view-2"
          className="flex h-full flex-col gap-3 overflow-auto p-10"
        >
          <>
            <div className="flex w-full flex-row items-center gap-2">
              <div className="flex-1" tabIndex={0}>
                <Disclaimer />
              </div>
              {showUserSelect && (
                <div>
                  <AdminInstructorsUserSelect />
                </div>
              )}
            </div>
            <div className="px-6 pb-3 pt-6" tabIndex={0}>
              <Header />
            </div>

            <QuizResultsSection />

            <div className="flex w-full flex-wrap gap-3">
              <div
                className="min-w-[300px] flex-1 rounded-2xl border border-outline-variant p-6"
                tabIndex={0}
              >
                <ConversationMap />
              </div>
              <div
                className="flex min-w-[300px] flex-1 flex-col rounded-2xl border border-outline-variant p-6"
                tabIndex={0}
              >
                <GroupBalanceScore />
              </div>
            </div>
            <div
              className="rounded-2xl border border-outline-variant p-6"
              tabIndex={0}
            >
              <IndividualEvaluation />
            </div>
          </>
        </div>
      </MeetingResultsCubitProvider>
    )
  }
)

const AdminInstructorsUserSelect = observer(function AdminUserSelect() {
  const repository = useRepository()
  const cubit = useMeetingResultsCubit()
  const { t } = useTranslation()
  const participantIds = cubit.meetingCubit.roomState.userIds

  const users = participantIds.map((id) => {
    const repUser = repository.userStore.getUser(id)
    if (repUser.isNotEmpty) return repUser
    return cubit.meetingCubit.users.find((user) => user.id === id) || repUser
  })

  const userId = cubit.userId

  useEffect(() => {
    const found = users.find((user) => user.id === userId)
    const first = users[0]
    if (!found && first) {
      cubit.changeUserId(first.id)
    }
  }, [users, userId, cubit])

  return (
    <div className="min-w-[200px]">
      <BreakoutSelect
        fullWidth
        kind="secondary"
        options={users.map((user) => ({
          value: user.id,
          label: user.fullName,
        }))}
        aria-label={t('meeting.results_select_user')}
        value={userId}
        onChange={(value) => {
          cubit.changeUserId(value)
        }}
      />
    </div>
  )
})
