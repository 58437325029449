import type { SlideDeckMaterial } from '@breakoutlearning/firebase-repository/models/SlideDeckMaterial'
import { Spinner } from 'components/Spinner'
import { Dialog, InlineDialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { LinkIcon } from 'components/icons/Link'
import type { DocumentData } from 'firebase/firestore'
import { getDownloadURL, ref } from 'firebase/storage'
import { useStorage } from 'hooks/auth'
import { useRootStore } from 'hooks/rootStore'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

function clearMediaSessionMetadata() {
  if ('mediaSession' in navigator) {
    navigator.mediaSession.metadata = null
  }
}
function setMediaSessionMetadata(
  slideDeckName: string,
  material: DocumentData,
  imageURL: string | null
) {
  if (!material.data) return
  if ('mediaSession' in navigator) {
    navigator.mediaSession.metadata = new MediaMetadata({
      title: material.data.materialName,
      artist: 'Breakout Learning',
      album: slideDeckName,
      artwork: imageURL
        ? [
            {
              src: imageURL,
              sizes: '512x512',
              type: 'image/png',
            },
          ]
        : [],
    })
  }
}

export const MaterialPlayerDialog = ({
  slideDeckId,
  slideDeckName,
  materialData,
  inline = false,
  onDismiss,
}: {
  slideDeckId: string
  slideDeckName: string
  materialData: Pick<
    SlideDeckMaterial['data'],
    'imageUrl' | 'materialLink' | 'materialName' | 'materialDescription'
  >
  inline?: boolean
  onDismiss?: () => void
}) => {
  const rootStore = useRootStore()
  const { t } = useTranslation()
  const [audioURL, setAudioURL] = useState<string | null>(null)
  const [imageURL, setImageURL] = useState<string | null>(null)
  const storage = useStorage()

  useEffect(() => {
    return () => {
      clearMediaSessionMetadata()
    }
  }, [])

  useEffect(() => {
    const run = async () => {
      if (!materialData.imageUrl) {
        setImageURL('/assets/images/podcast_card.jpg')
        return
      }
      const imageRef = ref(storage, materialData.imageUrl)
      const imageURL = await getDownloadURL(imageRef)
      setImageURL(imageURL)
    }
    run()
  }, [storage, slideDeckId, materialData.imageUrl])

  useEffect(() => {
    const run = async () => {
      const audioRef = ref(storage, materialData.materialLink)
      const audioURL = await getDownloadURL(audioRef)
      setAudioURL(audioURL)
    }
    run()
  }, [storage, slideDeckId, materialData.materialLink])

  const DialogToUse = inline ? InlineDialog : Dialog

  if (!imageURL || !audioURL)
    return (
      <DialogToUse size="xs">
        <div className="flex min-h-[300px] w-full items-center justify-center">
          <Spinner />
        </div>
      </DialogToUse>
    )

  return (
    <DialogToUse size="xs" className="!bg-core-tertiary" onDismiss={onDismiss}>
      <DialogCloseButton
        onClick={() => {
          if (onDismiss) onDismiss()
          return !inline
        }}
      />
      <div className="box-border max-h-[80vh] ">
        {imageURL && (
          <img src={imageURL} className="mx-auto max-h-[30vh] rounded-2xl" />
        )}
        <h1 className="mt-4 font-bold">{materialData.materialName}</h1>
        <p>{materialData.materialDescription}</p>

        {audioURL && (
          <audio
            controls
            onPlay={() =>
              setMediaSessionMetadata(slideDeckName, materialData, imageURL)
            }
            className="podcast-audio mt-4 w-full"
            controlsList="nodownload nofullscreen noremoteplayback"
          >
            <source src={audioURL} />
          </audio>
        )}
        <div
          tabIndex={0}
          aria-label={t('onboarding.link_podcast_app')}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') e.currentTarget.click()
          }}
          onClick={() => {
            rootStore.navigateToPodcastsPage({
              returnTo: window.location.pathname + window.location.search,
            })
          }}
          className="mt-5 flex cursor-pointer flex-row items-center gap-2 text-nowrap rounded-2xl bg-surface p-3 text-body-medium"
        >
          <LinkIcon size={14} />
          {t('onboarding.link_podcast_app')}
        </div>
      </div>
    </DialogToUse>
  )
}
