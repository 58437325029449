import type { AudioAnalyserOptions, LocalTrack, Track } from 'livekit-client'

// This is lifted from the livekit-client package to avoid importing livekit-client in the firebase-repository package
/**
 * @internal
 */
export function getNewAudioContext(): AudioContext | void {
  const AudioContext =
    typeof window !== 'undefined' &&
    // @ts-expect-error - vendor prefixes are not recognized by typescript
    (window.AudioContext || window.webkitAudioContext)
  if (AudioContext) {
    return new AudioContext({ latencyHint: 'interactive' })
  }
}

/**
 * Creates and returns an analyser web audio node that is attached to the provided track.
 * Additionally returns a convenience method `calculateVolume` to perform instant volume readings on that track.
 * Call the returned `cleanup` function to close the audioContext that has been created for the instance of this helper
 */
export function createAudioAnalyser(
  track: LocalTrack<Track.Kind.Audio>,
  options?: AudioAnalyserOptions
) {
  const opts = {
    cloneTrack: false,
    fftSize: 2048,
    smoothingTimeConstant: 0.8,
    minDecibels: -100,
    maxDecibels: -80,
    ...options,
  }
  const audioContext = getNewAudioContext()

  if (!audioContext) {
    throw new Error('Audio Context not supported on this browser')
  }
  const streamTrack = opts.cloneTrack
    ? track.mediaStreamTrack.clone()
    : track.mediaStreamTrack
  const mediaStreamSource = audioContext.createMediaStreamSource(
    new MediaStream([streamTrack])
  )
  const analyser = audioContext.createAnalyser()
  analyser.minDecibels = opts.minDecibels
  analyser.maxDecibels = opts.maxDecibels
  analyser.fftSize = opts.fftSize
  analyser.smoothingTimeConstant = opts.smoothingTimeConstant

  mediaStreamSource.connect(analyser)
  const dataArray = new Uint8Array(analyser.frequencyBinCount)

  /**
   * Calculates the current volume of the track in the range from 0 to 1
   */
  const calculateVolume = () => {
    analyser.getByteFrequencyData(dataArray)
    let sum = 0
    for (const amplitude of dataArray) {
      sum += Math.pow(amplitude / 255, 2)
    }
    const volume = Math.sqrt(sum / dataArray.length)
    return volume
  }

  const cleanup = async () => {
    await audioContext.close()
    if (opts.cloneTrack) {
      streamTrack.stop()
    }
  }

  return { calculateVolume, analyser, cleanup }
}
