import { makeObservable } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import type { FirebaseRepository } from './FirebaseRepository'
import {
  type FirestoreSettingsSectionPassPricing,
  empty,
} from '../firestore/SettingsSectionPassPricing/schema'

export class SettingsSectionPassPricing extends ObservableModelWithDecorators<FirestoreSettingsSectionPassPricing> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreSettingsSectionPassPricing>
  ) {
    super(repository, doc)
    makeObservable(this)
  }

  get sectionPassPriceInt() {
    if (!this.hasData) return 0
    return this.data?.sectionPassPrice
  }

  get sectionPassPriceDollars() {
    // convert to dollars fix at 2 decimals
    return (this.sectionPassPriceInt / 100).toFixed(2)
  }

  get sectionPassPriceDollarsUnfixedDecimals() {
    return this.sectionPassPriceInt % 100 === 0
      ? (this.sectionPassPriceInt / 100).toString()
      : this.sectionPassPriceDollars
  }

  get enabled() {
    return this.hasData && this.data.sectionPassPrice > 0
  }
}
