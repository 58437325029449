import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { InfoIcon } from 'components/icons/Info'
import { PercentageIcon } from 'components/icons/Percentage'
import { SparklesIcon } from 'components/icons/Sparkles'
import {
  GradingTooltipAttendance,
  GradingTooltipCompletion,
  GradingTooltipCriticalThinking,
  GradingToolTipQuizScore,
} from 'pages/instructor/shared/GradingTooltips'
import {
  useCallback,
  useState,
  useRef,
  forwardRef,
  type ForwardedRef,
  useMemo,
  useEffect,
  type ReactNode,
} from 'react'
import { useTranslation } from 'react-i18next'

export const ConfigureGradingScalars = forwardRef(
  function ConfigureGradingScalars(
    {
      defaults,
      hasQuizQuestions,
      hasRubrics,
      disableAll,
      onChange,
    }: {
      defaults?: {
        participationScore: number
        quizScore: number
        totalRubricScore: number
        attendanceScore: number
      }
      onChange?: (values: {
        participationScore: number
        quizScore: number
        totalRubricScore: number
        attendanceScore: number
        /**
         * if the values are valid (add up to 100)
         */
        isValid: boolean
        /**
         * sum of all values as an integer
         * valid if sum is 100
         */
        sumAsInt: number
      }) => void
      hasQuizQuestions: boolean
      hasRubrics: boolean
      disableAll?: boolean
    },
    argsRef?: ForwardedRef<typeof defaults>
  ) {
    const [values, setValues] = useState(
      defaultsValid({ defaults, hasQuizQuestions, hasRubrics })
        ? convertToIntegers(defaults!)
        : generateDefaults({ hasQuizQuestions, hasRubrics })
    )

    const disabled = useMemo(
      () => getDisabled({ hasQuizQuestions, hasRubrics }),
      [hasQuizQuestions, hasRubrics]
    )

    const { t } = useTranslation()
    const tScoped = useCallback(
      (key: string) => t(`instructor_library.${key}`),
      [t]
    )

    const localRef = useRef<typeof values>(values)
    const refToUse = argsRef ?? localRef

    // focus first input on mount if form is not disabled
    const firstInputRef = useRef<HTMLInputElement>(null)

    const textInputOnChange = useCallback(
      (k: keyof typeof values, value: string) => {
        const parsed = value ? Number.parseInt(value) : 0
        if (parsed < 0 || parsed > 100) return
        setValues((prevScalars) => ({
          ...prevScalars,
          [k]: isNaN(parsed) ? 0 : parsed,
        }))
      },
      []
    )

    useEffect(() => {
      if (disableAll) return
      firstInputRef.current?.focus()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // update ref on scalars change and fire on change event if provided
    useEffect(() => {
      const newScalarFloats = convertToFloats(values)
      if (onChange) {
        const sumAsInt = Object.values(values).reduce((a, b) => a + b, 0)
        onChange({
          ...newScalarFloats,
          sumAsInt,
          isValid: sumAsInt === 100,
        })
      }
      if (!('current' in refToUse)) return
      refToUse.current = convertToFloats(values)
    }, [onChange, refToUse, values])

    return (
      <div className="flex w-full flex-col gap-3">
        {hasQuizQuestions && (
          <GradingTextInput
            label={tScoped('quiz_scores')}
            labelDescription={tScoped('quiz_scores_description')}
            value={values.quizScore.toString()}
            disabled={disabled.has('quizScore') || disableAll}
            onChange={(e) => textInputOnChange('quizScore', e.target.value)}
            ref={firstInputRef}
            extra={
              <BreakoutTooltip content={<GradingToolTipQuizScore />}>
                <div>
                  <InfoIcon
                    size={15}
                    className="fill-on-surface-var"
                    strokeWidth={1.25}
                  />
                </div>
              </BreakoutTooltip>
            }
          />
        )}
        <GradingTextInput
          label={tScoped('attendance')}
          labelDescription={tScoped('attendance_description')}
          disabled={disableAll}
          value={values.attendanceScore.toString()}
          onChange={(e) => textInputOnChange('attendanceScore', e.target.value)}
          // this is first input if no quiz questions
          ref={hasQuizQuestions ? undefined : firstInputRef}
          extra={
            <BreakoutTooltip content={<GradingTooltipAttendance />}>
              <div>
                <InfoIcon
                  size={15}
                  className="fill-on-surface-var"
                  strokeWidth={1.25}
                />
              </div>
            </BreakoutTooltip>
          }
        />

        <GradingTextInput
          label={tScoped('participation')}
          labelDescription={tScoped('participation_description')}
          disabled={disableAll}
          value={values.participationScore.toString()}
          onChange={(e) =>
            textInputOnChange('participationScore', e.target.value)
          }
          extra={
            <BreakoutTooltip content={<GradingTooltipCompletion />}>
              <div>
                <InfoIcon
                  size={15}
                  className="fill-on-surface-var"
                  strokeWidth={1.25}
                />
              </div>
            </BreakoutTooltip>
          }
        />
        {hasRubrics && (
          <GradingTextInput
            label={tScoped('critical_thinking')}
            labelDescription={tScoped('critical_thinking_description')}
            value={values.totalRubricScore.toString()}
            disabled={disabled.has('totalRubricScore') || disableAll}
            onChange={(e) =>
              textInputOnChange('totalRubricScore', e.target.value)
            }
            extra={
              <div className="flex flex-row items-center gap-1 pl-1 text-label-small">
                <SparklesIcon className="stroke-fixed-accent-color" size={15} />
                <span className="mr-1">{tScoped('ai_assessment')}</span>
                <BreakoutTooltip content={<GradingTooltipCriticalThinking />}>
                  <div>
                    <InfoIcon
                      size={15}
                      className="fill-on-surface-var"
                      strokeWidth={1.25}
                    />
                  </div>
                </BreakoutTooltip>
              </div>
            }
          />
        )}
      </div>
    )
  }
)

const GradingTextInput = forwardRef(function GradingTextInput(
  {
    label,
    labelDescription,
    value,
    onChange,
    disabled,
    extra,
  }: {
    label: string
    labelDescription: string
    value: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    disabled?: boolean
    extra?: ReactNode
  },
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <div className="flex flex-row justify-between gap-2">
      <style>
        {/* disable spin buttons on number inputs*/}
        {`
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type='number'] {
            -moz-appearance: textfield;
          }
        `}
      </style>
      <div className="flex flex-col">
        {!extra && <strong className="text-label-medium">{label}</strong>}
        {extra && (
          <div className="flex flex-row gap-1">
            <strong className="text-label-medium">{label}</strong>
            {extra}
          </div>
        )}
        <strong className="text-body-medium text-on-surface-var">
          {labelDescription}
        </strong>
      </div>
      <BreakoutTextInput
        kind="secondary"
        value={value}
        disabled={disabled}
        onChange={onChange}
        type="number"
        min={0}
        max={100}
        TrailingIcon={PercentageIcon}
        iconSize={10}
        iconClassName={'text-on-surface-var !right-[15px]'}
        inputClassName="text-right !pr-[26px] pl-2.5 max-w-14"
        ref={ref}
      />
    </div>
  )
})

function convertToIntegers<T extends Record<string, number>>(values: T): T {
  // if any val is float multiply all by 100
  return Object.fromEntries(
    Object.entries(values).map(([key, val]) => [key, Math.round(val * 100)])
  ) as T
}

function convertToFloats<T extends Record<string, number>>(values: T): T {
  return Object.fromEntries(
    Object.entries(values).map(([key, val]) => [key, val / 100])
  ) as T
}

function defaultsValid({
  defaults,
  hasQuizQuestions,
  hasRubrics,
}: {
  defaults?: {
    attendanceScore: number
    participationScore: number
    quizScore: number
    totalRubricScore: number
  }
  hasQuizQuestions: boolean
  hasRubrics: boolean
}) {
  if (!defaults) return false
  const { quizScore, totalRubricScore, participationScore, attendanceScore } =
    defaults
  if (!hasQuizQuestions && defaults.quizScore !== 0) return false
  if (!hasRubrics && defaults.totalRubricScore !== 0) return false
  if (
    quizScore < 0 ||
    participationScore < 0 ||
    totalRubricScore < 0 ||
    attendanceScore < 0
  )
    return false
  if (quizScore + participationScore + totalRubricScore + attendanceScore !== 1)
    return false
  return true
}

function getDisabled({
  hasQuizQuestions,
  hasRubrics,
}: {
  hasQuizQuestions: boolean
  hasRubrics: boolean
}): Set<'quizScore' | 'totalRubricScore'> {
  const set = new Set<'totalRubricScore' | 'quizScore'>()
  if (!hasQuizQuestions) set.add('quizScore')
  if (!hasRubrics) set.add('totalRubricScore')
  return set
}

function generateDefaults({
  hasQuizQuestions,
  hasRubrics,
}: {
  hasQuizQuestions: boolean
  hasRubrics: boolean
}) {
  if (hasQuizQuestions && hasRubrics)
    return {
      quizScore: 40,
      participationScore: 15,
      totalRubricScore: 30,
      attendanceScore: 15,
    }

  // no quiz no rubric
  if (!hasQuizQuestions && !hasRubrics) {
    return {
      participationScore: 50,
      totalRubricScore: 0,
      quizScore: 0,
      attendanceScore: 50,
    }
  }

  // no quiz
  if (!hasQuizQuestions) {
    return {
      participationScore: 15,
      totalRubricScore: 70,
      quizScore: 0,
      attendanceScore: 15,
    }
  }

  // no rubrics
  return {
    participationScore: 15,
    quizScore: 70,
    attendanceScore: 15,
    totalRubricScore: 0,
  }
}
