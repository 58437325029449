import classNames from 'classnames'
import { CheckIcon } from 'components/icons/Check'
import { Close } from 'components/icons/Close'

import { useTranslation } from 'react-i18next'

export function QuizResultComponent({
  question,
  answers,
  selectedAnswer,
  correctAnswer,
  explanation,
}: {
  question: string
  answers: string[]
  selectedAnswer: number
  correctAnswer?: number
  explanation?: string | null
}) {
  const { t } = useTranslation()

  return (
    <div className="flex h-full flex-col">
      <div className="min-h-0 flex-1 overflow-auto">
        <div className="rounded-lg bg-surface p-3">
          <h3 className="text-title-medium">{question}</h3>
        </div>

        <div className="mt-2 px-4">
          {answers.map((answer, index) => (
            <div
              key={index}
              className={classNames(
                'flex items-center justify-between p-4 py-2',
                {
                  'rounded-lg border': index === selectedAnswer,
                  'bg-breakout-light-green border-breakout-dark-green':
                    index === correctAnswer && index === selectedAnswer,
                  'bg-breakout-light-red border-breakout-red':
                    index === selectedAnswer && index !== correctAnswer,
                }
              )}
            >
              <div className={classNames('text-body-medium')}>{answer}</div>

              {index === correctAnswer ? (
                <CheckIcon className="h-4 w-4 text-breakout-dark-green" />
              ) : index === selectedAnswer ? (
                <Close className="h-4 w-4 text-breakout-red" />
              ) : (
                <div className="h-4 w-4" />
              )}
            </div>
          ))}
        </div>
      </div>

      {explanation ? (
        <div className="mt-2 max-h-[7rem] overflow-auto">
          <div className="rounded-lg bg-surface p-3">
            <h4 className="mb-1 text-label-medium">
              {t('meeting.quiz_results.explanation')}
            </h4>
            <p className="text-body-medium">{explanation}</p>
          </div>
        </div>
      ) : null}
    </div>
  )
}
