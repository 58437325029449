import { observer } from 'mobx-react-lite'
import { useDialogs } from 'hooks/dialogs'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useMemo } from 'react'
import { reaction } from 'mobx'
import { useBreakoutUser } from 'hooks/profile'
import { useStudentAssignmentCubit } from 'hooks/cubits/studentAssignment'
import {
  EnrollWithSectionPassDialog,
  PurchaseOrEnrollDialog,
  RedeemCouponDialog,
  SelectCouponOrStripeDialog,
} from './dialogs/PurchaseOrEnrollDialog'
import classNames from 'classnames'
import { SectionPaymentMethod } from '@breakoutlearning/firebase-repository/types'
import { useRootStore } from 'hooks/rootStore'
import { BreakoutButton } from 'components/design-system/BreakoutButton'

export const StudentAssignmentEnroll = observer(
  function StudentAssignmentEnroll() {
    const cubit = useStudentAssignmentCubit()
    const { showDialog, dialogStore } = useDialogs()
    const { t } = useTranslation()
    const user = useBreakoutUser()
    const slideDeckPrice = cubit.slideDeck.data.slideDeckPrice
    const tokensAvailable = user.availableTokens
    const tokensRequired = slideDeckPrice - user.availableTokens
    const useSectionPassPricing =
      cubit.section.usesSectionPass &&
      cubit.sectionPassPricing.sectionPassPriceInt > 0

    // TODO!: Fix issue where the modal reappears after X seconds?

    // TODO: Do we need to be sensitive to this if useSectionPassPricing is true?
    const rootStore = useRootStore()
    const showPaymentLoading =
      rootStore.router.queryParams?.payment === 'success'

    const buttonLabel = useMemo(() => {
      const { sectionPassPriceDollars } = cubit.libraryObject
      const sectionPassPriceDollarsFormatted = sectionPassPriceDollars.endsWith(
        '.00'
      )
        ? sectionPassPriceDollars.substring(
            0,
            sectionPassPriceDollars.length - 3
          )
        : sectionPassPriceDollars

      return t('student_assignment.enroll_price', {
        price: useSectionPassPricing
          ? t('student_library.dollars', {
              dollars: sectionPassPriceDollarsFormatted,
            })
          : t('student_library.token_number', { count: slideDeckPrice }),
      })
    }, [cubit.libraryObject, slideDeckPrice, t, useSectionPassPricing])

    const showPurchaseOrEnrollDialog = useCallback(
      ({
        forceCoupon,
        forceStripe,
      }: { forceCoupon?: boolean; forceStripe?: boolean } = {}) => {
        showDialog(() => {
          const { usesStripePurchase, usesCouponCodes } = cubit.section

          if (useSectionPassPricing)
            return (
              <EnrollWithSectionPassDialog
                cubit={cubit}
                usesStripePurchase={usesStripePurchase}
                usesCouponCodes={usesCouponCodes}
              />
            )

          if (forceStripe && usesStripePurchase)
            return <PurchaseOrEnrollDialog cubit={cubit} />

          if (forceCoupon && usesCouponCodes)
            return <RedeemCouponDialog cubit={cubit} />

          if (usesStripePurchase && usesCouponCodes) {
            if (showPaymentLoading)
              return <PurchaseOrEnrollDialog cubit={cubit} />
            return (
              <SelectCouponOrStripeDialog
                cubit={cubit}
                showStripeDialog={() =>
                  showDialog(() => <PurchaseOrEnrollDialog cubit={cubit} />)
                }
                showCouponDialog={() =>
                  showDialog(() => <RedeemCouponDialog cubit={cubit} />)
                }
              />
            )
          }

          if (usesStripePurchase)
            return <PurchaseOrEnrollDialog cubit={cubit} />

          return <RedeemCouponDialog cubit={cubit} />
        })
      },
      [showDialog, cubit, useSectionPassPricing, showPaymentLoading]
    )

    useEffect(() => {
      return reaction(
        () => ({
          canPopEnrollDialog:
            cubit.canPopEnrollDialog && dialogStore.dialogs.length === 0,
          tokensAvailable: user.availableTokens,
          tokensLoading: user.tokensLoading,
        }),
        ({ canPopEnrollDialog, tokensAvailable, tokensLoading }, prev) => {
          // if tokens are still loading, don't proceed as available will be 0
          if (tokensLoading || !canPopEnrollDialog) return
          const didTokensUpdate = tokensAvailable !== prev?.tokensAvailable
          const shouldPop =
            canPopEnrollDialog !== prev?.canPopEnrollDialog || didTokensUpdate
          if (!shouldPop) return
          showPurchaseOrEnrollDialog()
        },
        { fireImmediately: true }
      )
    }, [dialogStore, user, cubit, showDialog, showPurchaseOrEnrollDialog])

    return (
      <div className="flex h-full flex-col items-center justify-center rounded-3xl bg-core-tertiary px-20">
        <h1 className="mb-[4px] text-title-large">
          {t('student_assignment.enroll_in_case')}
        </h1>
        <p className="mb-[16px] text-center text-body-medium">
          {useSectionPassPricing
            ? t('student_assignment.section_pass_description')
            : t('student_assignment.token_description')}
        </p>
        <div className="flex w-full flex-row justify-center gap-3">
          {!useSectionPassPricing && cubit.section.usesStripePurchase && (
            <BreakoutButton
              size="large"
              kind="primary"
              className={classNames({
                // if both buttons render, try and match width
                'min-w-[165px]':
                  cubit.section.data.sectionPaymentMethod ===
                  SectionPaymentMethod.sectionPassStripeCoupon,
              })}
              onClick={() => showPurchaseOrEnrollDialog({ forceStripe: true })}
            >
              {buttonLabel}
            </BreakoutButton>
          )}
          {!useSectionPassPricing && cubit.section.usesCouponCodes && (
            <BreakoutButton
              size="large"
              kind="primary"
              className={classNames({
                // if both buttons render, try and match width
                'min-w-[165px]':
                  cubit.section.data.sectionPaymentMethod ===
                  SectionPaymentMethod.sectionPassStripeCoupon,
              })}
              onClick={() => showPurchaseOrEnrollDialog({ forceCoupon: true })}
            >
              Redeem Coupon
            </BreakoutButton>
          )}
          {useSectionPassPricing && (
            <BreakoutButton
              size="large"
              kind="primary"
              className={classNames({
                // if both buttons render, try and match width
                'min-w-[165px]':
                  cubit.section.data.sectionPaymentMethod ===
                  SectionPaymentMethod.sectionPassStripeCoupon,
              })}
              onClick={() => showPurchaseOrEnrollDialog()}
            >
              {buttonLabel}
            </BreakoutButton>
          )}
        </div>
        {!useSectionPassPricing && (
          <div className="mt-1 flex items-center">
            <p className="text-body-medium">
              {t('student_assignment.tokens_available_title')}=
            </p>
            <p
              className={`text-label-medium ${
                tokensRequired > 0 ? 'text-red-500' : '' // Change text color conditionally
              }`}
            >
              {t('student_library.token_number', { count: tokensAvailable })}
            </p>
          </div>
        )}
      </div>
    )
  }
)
