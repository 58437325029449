import {
  CollectionReference,
  type DocumentData,
  deleteField,
  writeBatch,
  collection,
  doc,
  getCountFromServer,
  orderBy,
  query,
  serverTimestamp,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { FirestoreSlide } from './schema'
import { SlideType } from './types'
import { schema } from './schema'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { getFileExtensionFromStorageUrl } from '../../util/image'
import {
  convertDocumentSnapshotToModel,
  modelListStream,
} from '../../firestore-mobx/stream'
import { SlideModel } from '../../models/SlideModel'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { safeDeleteStorageObject } from '../../util/safeDeleteStorageObject'
import {
  addDocWithError,
  deleteDocWithError,
  getDocsWithError,
  getDocWithError,
  updateDocWithError,
} from '../../firestore-mobx/fetch'
import { fetchMedia } from '../Media'
import { MediaType } from '../../types'

export * from './schema'

const mediaTypePropertiesByMimeType = new Map([
  [
    'image/jpeg',
    {
      ext: 'jpg',
      path: 'image',
    },
  ],
  [
    'video/webm',
    {
      ext: 'webm',
      path: 'video',
    },
  ],
])

const converter: FirestoreDataConverter<FirestoreSlide> = {
  toFirestore: (data) => {
    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore,
  params: { slideDeckId: string }
): CollectionReference<FirestoreSlide, DocumentData> => {
  return collection(
    firestore,
    'slide_deck',
    params.slideDeckId,
    'slide'
  ).withConverter(converter)
}

export const fetchSlides = async (
  repository: FirebaseRepository,
  params: { slideDeckId: string }
) => {
  const docRef = getColRef(repository.firestore, params)
  const q = query(docRef, orderBy('slideOrder'))

  const docs = await getDocsWithError(q, 'FetchSlidesError')
  return docs.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, SlideModel)
  })
}

export const getSlides = (
  repository: FirebaseRepository,
  params: { slideDeckId: string }
) => {
  const docRef = getColRef(repository.firestore, params)
  const q = query(docRef, orderBy('slideOrder'))
  return modelListStream(repository, q, SlideModel)
}

export const fetchSlideCount = async (
  repository: FirebaseRepository,
  params: { slideDeckId: string }
) => {
  const colRef = getColRef(repository.firestore, params)
  const snap = await getCountFromServer(colRef)

  return snap.data().count
}

export const saveSlide = async (
  repository: FirebaseRepository,
  {
    slideDeckId,
    slideDescription,
    slideDuration,
    slideName,
    slideType,
    slideImageAltText,
    slideId,
    slideImageURL,
    slideVideoURL,
    mediaId,
    slideMediaFilename,
  }: {
    slideDeckId: string
    slideDescription: string
    slideDuration: number
    slideName: string
    slideType: SlideType
    slideImageAltText?: string
    slideId?: string
    slideImageURL?: string
    slideVideoURL?: string
    mediaId?: string
    slideMediaFilename?: string
  }
) => {
  const slideData: Partial<Record<keyof FirestoreSlide, unknown>> = {
    slideDescription: slideDescription,
    slideDuration: slideDuration,
    slideName: slideName,
    slideType: slideType,
    updatedAt: serverTimestamp(),
  }

  const isNewSlide = !slideId

  if (slideImageURL) {
    slideData.slideImageURL = slideImageURL
  }
  if (slideVideoURL) {
    slideData.slideVideoURL = slideVideoURL
  }

  // if we are using global media
  if (typeof mediaId !== 'undefined' && mediaId !== '') {
    slideData.mediaId = mediaId
    const media = await fetchMedia(repository, { mediaId })
    slideData.slideStorageURL = media.data.mediaStorageURL
    if (media.data.mediaType === MediaType.Video) {
      slideData.slideStorageStreamingURL = media.data.mediaStorageStreamingURL
      slideData.slideVideoStreamingURL = media.data.mediaVideoStreamingURL
      slideData.slideVideoURL = media.data.mediaVideoURL
      slideData.slideImageURL = isNewSlide ? undefined : deleteField()
      slideData.slideVideoDuration = media.data.mediaVideoDuration
      slideData.slideImageAltText = isNewSlide ? undefined : deleteField()
    } else {
      slideData.slideImageURL = media.data.mediaImageURL
      slideData.slideVideoURL = isNewSlide ? undefined : deleteField()
      slideData.slideVideoDuration = isNewSlide ? undefined : deleteField()
      slideData.slideVideoError = isNewSlide ? undefined : deleteField()
      slideData.slideVideoStreamingURL = isNewSlide ? undefined : deleteField()
      slideData.slideStorageStreamingURL = isNewSlide
        ? undefined
        : deleteField()
      slideData.slideImageAltText = media.data.mediaAltText
    }
    // if no global media
  } else {
    slideData.mediaId = ''
    if (slideType === SlideType.processingData) {
      // delete video data as it's not possible to have local video upload
      slideData.slideStorageURL = isNewSlide ? undefined : deleteField()
      slideData.slideVideoURL = isNewSlide ? undefined : deleteField()
      slideData.slideVideoDuration = isNewSlide ? undefined : deleteField()
      slideData.slideVideoStreamingURL = isNewSlide ? undefined : deleteField()
      slideData.slideStorageStreamingURL = isNewSlide
        ? undefined
        : deleteField()
      // if no local image upload then delete image data
      if (!slideData.slideImageURL) {
        slideData.slideImageURL = isNewSlide ? undefined : deleteField()
        slideData.slideImageAltText = isNewSlide ? undefined : deleteField()
      }
    }
  }

  if (slideMediaFilename) {
    slideData.slideMediaFilename = slideMediaFilename
  }

  if (!slideId) {
    slideData.slideOrder = 999
  }

  if (slideImageAltText) {
    slideData.slideImageAltText = slideImageAltText
  }

  if (slideType === SlideType.endOfSession) {
    if (slideImageURL) {
      slideData.slideVideoURL = isNewSlide ? undefined : deleteField()
      slideData.slideVideoStreamingURL = isNewSlide ? undefined : deleteField()
      slideData.slideVideoDuration = isNewSlide ? undefined : deleteField()
      slideData.slideVideoError = isNewSlide ? undefined : deleteField()
    }
    if (slideVideoURL) {
      slideData.slideImageURL = isNewSlide ? undefined : deleteField()
      slideData.slideImageAltText = isNewSlide ? undefined : deleteField()
    }
  }

  const colRef = getColRef(repository.firestore, { slideDeckId: slideDeckId })
  const slideRef = slideId ? doc(colRef, slideId) : colRef

  if (slideRef instanceof CollectionReference) {
    const newSlide = await addDocWithError(
      slideRef,
      slideData,
      'SaveSlideError'
    )
    reorderSlides(repository, slideDeckId)
    return newSlide
  }

  return await updateDocWithError(slideRef, slideData, 'SaveSlideError')
}

const deleteSlideFile = async (
  repository: FirebaseRepository,
  {
    slideDeckId,
    slideId,
    path,
    fieldName,
    fileExtension,
  }: {
    slideDeckId: string
    slideId: string
    path: 'image' | 'video'
    fieldName: keyof FirestoreSlide
    fileExtension: string
  }
) => {
  const slideRef = doc(
    getColRef(repository.firestore, { slideDeckId }),
    slideId
  )
  const fileRef = ref(
    repository.storage,
    `slide_deck/${slideDeckId}/slide/${path}/${slideId}.${fileExtension}`
  )
  await safeDeleteStorageObject(fileRef)

  const updatedToRun = {
    [fieldName]: deleteField(),
    slideVideoDuration: deleteField(),
    slideVideoError: deleteField(),
    slideStorageURL: deleteField(),
  }
  if (fieldName === 'slideVideoURL') {
    // append slideVideoStreamingURL and slideStorageStreamingURL to updatedToRun
    updatedToRun.slideVideoStreamingURL = deleteField()
    updatedToRun.slideStorageStreamingURL = deleteField()
  }
  updateDocWithError(slideRef, updatedToRun, 'DeleteSlideFileError')
}

export const deleteSlideImage = async (
  repository: FirebaseRepository,
  params: Omit<Parameters<typeof deleteSlideFile>[1], 'path' | 'fieldName'>
) => {
  return deleteSlideFile(repository, {
    ...params,
    path: 'image',
    fieldName: 'slideImageURL',
  })
}
export const deleteSlideVideo = async (
  repository: FirebaseRepository,
  params: Omit<Parameters<typeof deleteSlideFile>[1], 'path' | 'fieldName'>
) => {
  return deleteSlideFile(repository, {
    ...params,
    path: 'video',
    fieldName: 'slideVideoURL',
  })
}

export const deleteSlide = async (
  repository: FirebaseRepository,
  {
    slideDeckId,
    slideId,
  }: {
    slideDeckId: string
    slideId: string
  }
) => {
  const slide = await getDocWithError(
    doc(getColRef(repository.firestore, { slideDeckId }), slideId),
    'FetchForDeleteSlideError'
  )

  if (!slide.exists()) {
    return
  }

  const { slideImageURL, slideVideoURL } = slide.data()

  const fileExtension = slideImageURL
    ? getFileExtensionFromStorageUrl(slideImageURL)
    : getFileExtensionFromStorageUrl(slideVideoURL)

  // delete media files first
  slideImageURL
    ? await deleteSlideImage(repository, {
        slideDeckId,
        slideId,
        fileExtension,
      })
    : await deleteSlideVideo(repository, {
        slideDeckId,
        slideId,
        fileExtension,
      })

  // delete slide document
  await deleteDocWithError(
    doc(getColRef(repository.firestore, { slideDeckId }), slideId),
    'DeleteSlideError'
  )
}

const uploadSlideFile = async (
  repository: FirebaseRepository,
  {
    slideDeckId,
    slideId,
    file,
    fieldName,
  }: {
    file: File
    fieldName: keyof FirestoreSlide
    slideId: string
    slideDeckId: string
  }
) => {
  // get extension from file
  const mediaProps = mediaTypePropertiesByMimeType.get(file.type)
  if (!mediaProps) {
    throw new Error('Unsupported media type: ' + file.type)
  }
  const { ext, path } = mediaProps

  const fileRef = ref(
    repository.storage,
    `slide_deck/${slideDeckId}/slide/${path}/${slideId}.${ext}`
  )
  await uploadBytes(fileRef, file, { contentType: file.type })

  // get the download url and strip the token param
  const urlWithoutToken = (await getDownloadURL(fileRef)).replaceAll(
    /&token=[a-z0-9-]{36}/g,
    ''
  )

  // write url to slide doc
  const slideRef = doc(
    getColRef(repository.firestore, { slideDeckId }),
    slideId
  )

  const updateData: Partial<Record<keyof FirestoreSlide, unknown>> = {
    [fieldName]: urlWithoutToken,
    updatedAt: serverTimestamp(),
    slideVideoDuration: deleteField(),
    slideVideoError: deleteField(),
    slideMediaFilename: file.name,
    // when we upload a file, it means we are not using the mediaId anymore
    mediaId: deleteField(),
    slideStorageURL: `gs://${fileRef.bucket}/${fileRef.fullPath}`,
  }

  await updateDocWithError(slideRef, updateData, 'UploadSlideFileError')
  return urlWithoutToken
}

export const uploadSlideVideo = async (
  repository: FirebaseRepository,
  params: Omit<Parameters<typeof uploadSlideFile>[1], 'fieldName'>
) => {
  return uploadSlideFile(repository, {
    ...params,
    fieldName: 'slideVideoURL',
  })
}

export const uploadSlideImage = async (
  repository: FirebaseRepository,
  params: Omit<Parameters<typeof uploadSlideFile>[1], 'fieldName'>
) => {
  return uploadSlideFile(repository, {
    ...params,
    fieldName: 'slideImageURL',
  })
}

const reorderSlides = async (
  repository: FirebaseRepository,
  slideDeckId: string
) => {
  const colRef = getColRef(repository.firestore, {
    slideDeckId: slideDeckId,
  })

  // Query to order by slideOrder
  const q = query(colRef, orderBy('slideOrder'))
  const docs = await getDocsWithError(q, 'FetchForReorderSlidesError')
  const batch = writeBatch(repository.firestore)

  // Sort documents locally by materialOrder and then by updatedAt
  const sortedDocs = docs.docs.sort((a, b) => {
    const aData = a.data()
    const bData = b.data()

    const aSlideOrder = aData.slideOrder !== undefined ? aData.slideOrder : 0
    const bSlideOrder = bData.slideOrder !== undefined ? bData.slideOrder : 0

    if (aSlideOrder === bSlideOrder) {
      const aUpdatedAt = aData.updatedAt
        ? new Date(aData.updatedAt).getTime()
        : 0
      const bUpdatedAt = bData.updatedAt
        ? new Date(bData.updatedAt).getTime()
        : 0
      return aUpdatedAt - bUpdatedAt
    } else {
      return aSlideOrder - bSlideOrder
    }
  })

  // Reorder based on the locally sorted documents
  sortedDocs.forEach((doc, index) => {
    if (doc.data().slideOrder === index) return
    batch.update(doc.ref, { slideOrder: index })
  })

  await batch.commit()
}

export const sortSlides = async (
  repository: FirebaseRepository,
  {
    currentOrder,
    oldIndex,
    newIndex,
    slideDeckId,
  }: {
    slideDeckId: string
    currentOrder: string[]
    oldIndex?: number
    newIndex?: number
  }
) => {
  const slideIds = [...currentOrder]

  // if old index and new index supplied then reorder
  if (oldIndex !== undefined && newIndex !== undefined) {
    //remove at old index
    const targetId = slideIds.splice(oldIndex, 1)[0]
    //insert at new index
    slideIds.splice(newIndex, 0, targetId)
  }
  const batch = writeBatch(repository.firestore)
  slideIds.forEach((slideId, index) => {
    // get the slide doc ref
    const slideRef = doc(
      getColRef(repository.firestore, { slideDeckId }),
      slideId
    )
    const orderUpdate: Partial<Record<keyof FirestoreSlide, unknown>> = {
      slideOrder: index,
    }
    batch.update(slideRef, orderUpdate)
  })

  // perform the batch write
  await batch.commit()
}

export const touchSlide = async (
  repository: FirebaseRepository,
  {
    slideDeckId,
    slideId,
  }: {
    slideDeckId: string
    slideId: string
  }
) => {
  const slideRef = doc(
    getColRef(repository.firestore, { slideDeckId }),
    slideId
  )
  await updateDocWithError(slideRef, { updatedAt: serverTimestamp() })
}

export type SlideFieldsForUpload = Pick<
  FirestoreSlide,
  | 'slideName'
  | 'slideType'
  | 'slideDescription'
  | 'slideDuration'
  | 'slideImageURL'
  | 'slideVideoURL'
>
