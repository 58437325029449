import { Spinner } from 'components/Spinner'
import { Suspense, lazy } from 'react'
import type { InstructorPage } from './InstructorEntrypoint'

const InstructorEntrypoint = lazy(() => import('./InstructorEntrypoint'))

// This file is the boundary which allows vite to code split the instructor pages
export function InstructorLoader({ page }: { page: InstructorPage }) {
  return (
    <Suspense fallback={<Spinner className="m-8" />}>
      <InstructorEntrypoint page={page} />
    </Suspense>
  )
}
