import type {
  CollectionReference,
  DocumentReference,
  FieldValue,
  Firestore,
  PartialWithFieldValue,
} from 'firebase/firestore'
import {
  collection,
  deleteField,
  doc,
  query,
  serverTimestamp,
  where,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { modelItemStream, modelListStream } from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import type { FirestoreMedia } from './schema'
import { schema } from './schema'
import {
  getDocsWithError,
  setDocWithError,
  updateDocWithError,
} from '../../firestore-mobx/fetch'
import { Media } from '../../models/Media'
import { DocumentDoesNotExistError } from '../../types'
import { getDocWithError } from '../../firestore-mobx/fetch'
import { convertDocumentSnapshotToModel } from '../../firestore-mobx/stream'
import { MediaType } from '../../types'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { runTransaction } from 'firebase/firestore'

const converter: FirestoreDataConverter<FirestoreMedia> = {
  toFirestore: (data) => {
    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore
): CollectionReference<FirestoreMedia> => {
  return collection(firestore, 'media').withConverter(converter)
}

const getDocRef = (
  firestore: Firestore,
  mediaId: string
): DocumentReference<FirestoreMedia> => {
  return doc(getColRef(firestore), mediaId)
}

export const getMediaList = (repository: FirebaseRepository) => {
  const ref = getColRef(repository.firestore)
  const q = query(ref)
  return modelListStream(repository, q, Media)
}

export const getMedia = (
  repository: FirebaseRepository,
  { mediaId }: { mediaId: string }
) => {
  const ref = getDocRef(repository.firestore, mediaId)
  return modelItemStream(repository, ref, Media)
}

export const deleteMedia = async () => {
  throw Error('Media should never be deleted in its current form.')
}

export const updateMediaFile = async (
  repository: FirebaseRepository,
  mediaId: string,
  file: File
) => {
  try {
    await runTransaction(repository.firestore, async (t) => {
      const docRef = getDocRef(repository.firestore, mediaId)
      const doc = await t.get(docRef)
      const media = doc.data()
      if (!media) throw new Error('Media not found')

      const { urlWithoutToken, googleStorageUrl } = await uploadMedia(
        repository,
        { mediaId, file }
      )

      t.update(docRef, { mediaStorageURL: '' })

      const updatedMedia: {
        mediaFileName: string
        mediaImageURL?: string | FieldValue
        mediaStorageStreamingURL?: FieldValue
        mediaStorageURL: string
        mediaUploadedAt: FieldValue
        mediaVideoDuration?: number | FieldValue
        mediaVideoError?: string | FieldValue
        mediaVideoStreamingURL?: FieldValue
        mediaVideoURL?: string | FieldValue
        updatedAt: FieldValue
      } = {
        mediaFileName: file.name,
        mediaImageURL: deleteField(),
        mediaStorageStreamingURL: deleteField(),
        mediaStorageURL: googleStorageUrl,
        mediaUploadedAt: serverTimestamp(),
        mediaVideoDuration: deleteField(),
        mediaVideoError: deleteField(),
        mediaVideoStreamingURL: deleteField(),
        mediaVideoURL: deleteField(),
        updatedAt: serverTimestamp(),
      }

      if (media.mediaType === MediaType.Video) {
        updatedMedia.mediaVideoURL = urlWithoutToken
      }

      if (media.mediaType === MediaType.Image) {
        updatedMedia.mediaImageURL = urlWithoutToken
      }

      t.update(docRef, updatedMedia)
    })
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(`Failed to update media file: ${error.message}`)
    }
    throw new Error('Failed to update media file')
  }
}

export const fetchMedia = async (
  repository: FirebaseRepository,
  {
    mediaId,
    returnEmptyOnNotExists = false,
  }: { mediaId: string; returnEmptyOnNotExists?: boolean }
) => {
  const docRef = getDocRef(repository.firestore, mediaId)

  const doc = await getDocWithError(docRef, 'FetchMediaError')

  if (!doc.exists()) {
    if (returnEmptyOnNotExists) return Media.empty(repository)
    throw new DocumentDoesNotExistError()
  }

  return convertDocumentSnapshotToModel(repository, doc, Media)
}

export const fetchAllMedia = async (repository: FirebaseRepository) => {
  const colRef = getColRef(repository.firestore)
  const q = query(colRef)
  const docs = await getDocsWithError(q, 'FetchAllMediaError')
  return docs.docs.map((doc) =>
    convertDocumentSnapshotToModel(repository, doc, Media)
  )
}

export const fetchMediaByType = async (
  repository: FirebaseRepository,
  mediaType: MediaType
) => {
  const colRef = getColRef(repository.firestore)
  const q = query(colRef, where('mediaType', '==', mediaType))
  const docs = await getDocsWithError(q, 'FetchMediaByTypeError')
  return docs.docs.map((doc) =>
    convertDocumentSnapshotToModel(repository, doc, Media)
  )
}

export const updateMedia = async (
  repository: FirebaseRepository,
  mediaId: string,
  media: Pick<FirestoreMedia, 'mediaTitle' | 'mediaAltText'>
) => {
  const docRef = getDocRef(repository.firestore, mediaId)
  const mediaPayload = {
    ...media,
    updatedAt: serverTimestamp(),
  }

  return await updateDocWithError(docRef, mediaPayload, 'UpdateMediaError')
}

export const hideMedia = async (
  repository: FirebaseRepository,
  mediaId: string
) => {
  const docRef = getDocRef(repository.firestore, mediaId)
  return await updateDocWithError(docRef, { hidden: true }, 'HideMediaError')
}

export const unhideMedia = async (
  repository: FirebaseRepository,
  mediaId: string
) => {
  const docRef = getDocRef(repository.firestore, mediaId)
  return await updateDocWithError(docRef, { hidden: false }, 'UnhideMediaError')
}

export const createMedia = async (
  repository: FirebaseRepository,
  media: Omit<
    FirestoreMedia,
    'updatedAt' | 'mediaStorageURL' | 'mediaUploadedAt'
  >,
  file: File
) => {
  const colRef = getColRef(repository.firestore)
  const docRef = doc(colRef)

  const now = serverTimestamp()
  const mediaPayload: {
    mediaType: MediaType
    mediaTitle: string
    mediaAltText?: string
    mediaFileName: string
    mediaUploadedAt: FieldValue
    updatedAt: FieldValue
    mediaStorageURL?: string
    mediaImageURL?: string
    mediaVideoURL?: string
  } = {
    mediaType: media.mediaType,
    mediaTitle: media.mediaTitle,
    mediaAltText: media.mediaAltText,
    mediaFileName: file.name,
    mediaUploadedAt: now,
    updatedAt: now,
  }

  const { urlWithoutToken, googleStorageUrl } = await uploadMedia(repository, {
    mediaId: docRef.id,
    file,
  })

  mediaPayload.mediaStorageURL = googleStorageUrl

  if (media.mediaType === MediaType.Video) {
    mediaPayload.mediaVideoURL = urlWithoutToken
  }

  if (media.mediaType === MediaType.Image) {
    mediaPayload.mediaImageURL = urlWithoutToken
  }

  await setDocWithError(
    docRef,
    mediaPayload as unknown as PartialWithFieldValue<FirestoreMedia>,
    {
      errorName: 'CreateMediaError',
    }
  )

  return docRef.id
}

export const uploadMedia = async (
  repository: FirebaseRepository,
  {
    mediaId,
    file,
  }: {
    mediaId: string
    file: File
  }
) => {
  const fileMimeType = file.type

  const extension = file.type === 'video/webm' ? 'webm' : 'jpg'

  const storageRef = ref(
    repository.storage,
    `media/${mediaId}/${mediaId}.${extension}`
  )

  const googleStorageUrl = `gs://${storageRef.bucket}/${storageRef.fullPath}`

  await uploadBytes(storageRef, file, {
    contentType: fileMimeType,
  })

  const urlWithoutToken = (await getDownloadURL(storageRef)).replaceAll(
    /&token=[a-z0-9-]{36}/g,
    ''
  )

  return { googleStorageUrl, urlWithoutToken }
}
