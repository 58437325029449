import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useMeetingResultsCubit } from 'hooks/cubits/meetingsResults'
import { SlideQuestionType } from '@breakoutlearning/firebase-repository/models/SlideQuestionType'
import type { SlideQuestion } from '@breakoutlearning/firebase-repository/models/SlideQuestion'
import { QuizResultsCarousel } from './QuizResultsCarousel'
import { QuizResultComponent } from './QuizResultComponent'

export const QuizResultsSection = observer(function QuizResultsSection() {
  const cubit = useMeetingResultsCubit()
  const { t } = useTranslation()

  const multipleChoiceQuestions = cubit.meetingCubit.slideDeckQuestions.models
    .filter(
      (question) => question.questionType === SlideQuestionType.multipleChoice
    )
    .sort((a, b) => sortQuestions(a, b, cubit.meetingCubit.slides))

  if (!cubit.meetingCubit.quizDataIsReadyForUser(cubit.userId)) {
    return null
  }

  if (multipleChoiceQuestions.length === 0) return null

  const anyQuestionHasExplanation = multipleChoiceQuestions.some(
    (question) => question.data.explanation
  )

  const userAnswers = cubit.meetingCubit.roomStateAnswersPerUser.get(
    cubit.userId
  )

  return (
    <div className="flex flex-col rounded-2xl border border-outline-variant p-6">
      <div className="mb-4">
        <h2 className="text-title-large">{t('meeting.quiz_results.title')}</h2>
        <p className="text-body-medium">
          {t('meeting.quiz_results.description')}
        </p>
      </div>

      <div className="h-full flex-1">
        <QuizResultsCarousel
          key={cubit.userId}
          anyQuestionHasExplanation={anyQuestionHasExplanation}
          components={multipleChoiceQuestions.map((question, index) => (
            <QuizResultComponent
              key={`${question.id}-${cubit.userId}-${index}`}
              question={question.data.question}
              answers={question.data.answers}
              selectedAnswer={
                userAnswers?.models.find(
                  (a) => a.data.slideQuestionId === question.id
                )?.data.answer ?? -1
              }
              correctAnswer={question.data.correct}
              explanation={question.data.explanation}
            />
          ))}
        />
      </div>
    </div>
  )
})

function sortQuestions(
  a: SlideQuestion,
  b: SlideQuestion,
  slides: Array<{ id: string; order: number }>
) {
  const slideIdA = a.data.slideId ?? ''
  const slideIdB = b.data.slideId ?? ''

  if (slideIdA === 'pre-meeting-quiz' && slideIdB === 'pre-meeting-quiz') {
    return a.id.localeCompare(b.id)
  }

  if (a.data.slideId === 'pre-meeting-quiz') return -1
  if (b.data.slideId === 'pre-meeting-quiz') return 1

  const slideA = slides.find((slide) => slide.id === a.data.slideId)
  const slideB = slides.find((slide) => slide.id === b.data.slideId)

  if (!slideA || !slideB) {
    return slideIdA.localeCompare(slideIdB)
  }

  const orderComparison = slideA.order - slideB.order
  if (orderComparison !== 0) {
    return orderComparison
  }

  return slideIdA.localeCompare(slideIdB)
}
