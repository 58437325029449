import { type ReactNode, useState, useCallback } from 'react'
import classNames from 'classnames'
import { ChevronLeft } from 'components/icons/ChevronLeft'
import { ChevronRight } from 'components/icons/ChevronRight'
import { SlideProjector } from 'components/SlideProjector'

export function QuizResultsCarousel({
  components,
  className,
  anyQuestionHasExplanation,
}: {
  components: ReactNode[]
  className?: string
  anyQuestionHasExplanation: boolean
}) {
  const [currentIndex, setCurrentIndex] = useState(0)

  if (components.length === 1) {
    return <div className={className}>{components[0]}</div>
  }

  return (
    <div className="relative flex h-full max-h-full flex-col">
      <div className={anyQuestionHasExplanation ? 'h-[18rem]' : 'h-[12.5rem]'}>
        <SlideProjector className={className} index={currentIndex}>
          {components[currentIndex]}
        </SlideProjector>
      </div>

      <CarouselNavigation
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        componentsLength={components.length}
      />
    </div>
  )
}

function CarouselNavigation({
  currentIndex,
  setCurrentIndex,
  componentsLength,
}: {
  currentIndex: number
  setCurrentIndex: (index: number) => void
  componentsLength: number
}) {
  const nextPage = useCallback(() => {
    setCurrentIndex((currentIndex + 1) % componentsLength)
  }, [currentIndex, componentsLength, setCurrentIndex])

  const lastPage = useCallback(() => {
    setCurrentIndex((currentIndex - 1 + componentsLength) % componentsLength)
  }, [currentIndex, componentsLength, setCurrentIndex])

  return (
    <div className="flex items-center justify-center gap-4 py-2">
      <button
        onClick={lastPage}
        disabled={componentsLength <= 1}
        className={classNames(
          'flex h-8 w-8 items-center justify-center rounded-full transition-colors',
          {
            'hover:bg-grey-50': componentsLength > 1,
          }
        )}
        aria-label="Previous question"
      >
        <ChevronLeft
          className="h-5 w-5 stroke-[2]"
          color={componentsLength <= 1 ? '#E0E0E0' : '#000000'}
        />
      </button>

      <div className="flex gap-2">
        {Array.from({ length: componentsLength }).map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={classNames(
              'relative flex h-[15px] w-[15px] min-w-[15px] items-center justify-center rounded-full border transition-colors',
              {
                'border-fixed-accent-color': currentIndex === index,
                'border-on-surface-disabled': currentIndex !== index,
              }
            )}
            aria-label={`Go to question ${index + 1}`}
          >
            {currentIndex === index && (
              <div className="h-[11px] w-[11px] rounded-full bg-fixed-accent-color" />
            )}
          </button>
        ))}
      </div>

      <button
        onClick={nextPage}
        disabled={componentsLength <= 1}
        className={classNames(
          'flex h-8 w-8 items-center justify-center rounded-full transition-colors',
          {
            'hover:bg-grey-50': componentsLength > 1,
          }
        )}
        aria-label="Next question"
      >
        <ChevronRight
          className="h-5 w-5 stroke-[2]"
          color={componentsLength <= 1 ? '#E0E0E0' : '#000000'}
        />
      </button>
    </div>
  )
}
