import { zodResolver } from '@hookform/resolvers/zod'
import {
  BreakoutAsyncButton,
  BreakoutButton,
} from 'components/design-system/BreakoutButton'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { TrashCanIcon } from 'components/icons/TrashCan'
import { useDialogs } from 'hooks/dialogs'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import type { FormValues } from './schema'
import { getSchema } from './schema'
import { PlusIcon } from 'components/icons/Plus'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { useOrganizationCubit } from 'hooks/cubits/organization'
import { type OrganizationCubit } from '@breakoutlearning/firebase-repository/cubits/OrganizationCubit'
import {
  OrganizationInvoiceStatus,
  OrganizationState,
} from '@breakoutlearning/firebase-repository/types'
import { Spinner } from 'components/Spinner'
import { toast } from 'react-hot-toast'
import { EyeOpen } from 'components/icons/EyeOpen'
import { EyeClosed } from 'components/icons/EyeClosed'
import {
  BreakoutSelect,
  type SelectOption,
} from 'components/design-system/BreakoutSelect'
import { useBreakoutUser } from 'hooks/profile'
import { BreakoutMultiSelect } from 'components/design-system/BreakoutMultiSelect'
import { LinkCopiedToClipboardDialog } from 'components/LinkCopiedToClipboard'
import type { PublicUser } from '@breakoutlearning/firebase-repository/models/PublicUser'

export const OrganizationDetailsView = observer(
  function OrganizationDetailsView() {
    const cubit = useOrganizationCubit()
    const rootStore = useRootStore()

    useEffect(() => {
      if (
        cubit.organization.isLoaded &&
        cubit.organization.data.organizationState === OrganizationState.deleted
      ) {
        toast.error('Organization deleted')
        rootStore.navigateTo('organizations')
      }
    }, [
      cubit.organization.data.organizationState,
      cubit.organization.isLoaded,
      rootStore,
    ])

    return (
      <div className="h-full rounded-3xl bg-surface p-5">
        <div className="flex h-full flex-row gap-5 ">
          <div className="flex-0 h-full w-full overflow-y-auto rounded-2xl bg-surface-bright">
            <div className="h-full p-5 px-8">
              {cubit.organization.isLoading ||
              cubit.catalogs.isLoading ||
              cubit.organizationCatalogs.isLoading ? (
                <Spinner />
              ) : (
                <div className="flex h-full flex-col gap-2">
                  <OrganizationForm />
                </div>
              )}
            </div>
          </div>
          <div className="flex h-full w-full flex-col gap-5">
            <div className="flex-1 flex-shrink overflow-y-hidden rounded-2xl bg-surface-bright">
              <div className="h-full p-5 px-8">
                <AuthorizedUsersView />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

const OrganizationForm = observer(function OrganizationForm() {
  const { t } = useTranslation()

  const schema = useMemo(() => getSchema(t), [t])

  const cubit = useOrganizationCubit()

  const { isCorre } = useBreakoutUser()

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    setValue,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      institutionId: cubit.organization.data.institutionId,
      organizationName: cubit.organization.data?.organizationName ?? '',
      organizationInvoiceStatus:
        cubit.organization.data?.organizationInvoiceStatus,
      organizationCatalogIds: cubit.organizationCatalogIds,
      organizationDefaultCatalogIds: cubit.organizationDefaultCatalogIds,
    },
  })

  const catalogIdsToSelectForDefaults = useWatch({
    control,
    name: 'organizationCatalogIds',
  })

  const currentDefaults = useWatch({
    control,
    name: 'organizationDefaultCatalogIds',
  })

  useEffect(() => {
    const filteredDefaults = currentDefaults.filter((id) =>
      catalogIdsToSelectForDefaults.includes(id)
    )
    if (filteredDefaults.length !== currentDefaults.length) {
      setValue('organizationDefaultCatalogIds', filteredDefaults)
    }
  })

  const catalogsToSelectForDefaults = useMemo(() => {
    const options: SelectOption<string>[] = []
    for (const catalogId of catalogIdsToSelectForDefaults) {
      const catalog = cubit.catalogs.models.find(
        (catalog) => catalog.id === catalogId
      )
      if (catalog) {
        options.push({
          label: catalog.data.catalogName,
          value: catalog.id,
        })
      }
    }
    return options
  }, [catalogIdsToSelectForDefaults, cubit.catalogs.models])

  const isArchived =
    cubit.organization.data.organizationState === OrganizationState.inactive

  const submit = useCallback(
    async (data: FormValues) => {
      await cubit.updateOrganization(data)
      toast.success(t('organization.organization_updated'))
    },
    [cubit, t]
  )

  return (
    <>
      <div className="mb-6 text-title-large">
        {t('organization.organization_details')}
      </div>
      <form onSubmit={handleSubmit(submit, console.error)}>
        <BreakoutSelect
          disabled={true}
          value={cubit.organization.data.institutionId}
          label={t('organizations.organization_institution')}
          options={[
            {
              value: cubit.organization.data.institutionId,
              label: cubit.organization.data.organizationInstitution,
            },
          ]}
        />

        <div className="mt-3">
          <Controller
            control={control}
            name="organizationName"
            render={({ field, fieldState }) => (
              <BreakoutTextInput
                {...field}
                disabled={isArchived}
                kind="secondary"
                error={fieldState.error}
                type="text"
                label={t('organizations.organization_name')}
              />
            )}
          />
        </div>
        <div className="mt-3">
          <Controller
            control={control}
            name="organizationInvoiceStatus"
            render={({ field, fieldState }) => (
              <BreakoutSelect
                {...field}
                disabled={isArchived}
                kind="secondary"
                error={fieldState.error}
                label={t('organizations.organization_invoice_status')}
                options={[
                  {
                    label: t('organizations.no_invoices'),
                    value: OrganizationInvoiceStatus.noInvoices,
                  },
                  {
                    label: t('organizations.admin_approved'),
                    value: OrganizationInvoiceStatus.adminApproved,
                  },
                ]}
              />
            )}
          />
        </div>
        <div className="mt-3">
          <Controller
            control={control}
            name="organizationDefaultCatalogIds"
            render={({ field, fieldState }) => (
              <BreakoutMultiSelect
                {...field}
                disabled={isArchived}
                kind="secondary"
                error={fieldState.error}
                label={t('organization.default_catalogs')}
                options={catalogsToSelectForDefaults}
                placeholder={t('organization.catalogs_selected.zero')}
                labelGenerator={({ length }) => {
                  if (!length) return t('organization.catalogs_selected.zero')
                  if (length === 1)
                    return t('organization.catalogs_selected.one')
                  return t('organization.catalogs_selected.many', {
                    count: length,
                  })
                }}
              />
            )}
          />
        </div>
        {isCorre && (
          <div className="mt-3">
            <Controller
              control={control}
              name="organizationCatalogIds"
              render={({ field, fieldState }) => (
                <BreakoutMultiSelect
                  {...field}
                  disabled={isArchived}
                  kind="secondary"
                  error={fieldState.error}
                  label={t('organization.catalog_access')}
                  options={cubit.catalogs.models.map((catalog) => ({
                    label: catalog.data.catalogName,
                    value: catalog.id,
                  }))}
                  placeholder={t('organization.catalogs_selected.zero')}
                  labelGenerator={({ length }) => {
                    if (!length) return t('organization.catalogs_selected.zero')
                    if (length === 1)
                      return t('organization.catalogs_selected.one')
                    return t('organization.catalogs_selected.many', {
                      count: length,
                    })
                  }}
                />
              )}
            />
          </div>
        )}
        <div className="mt-4 flex justify-end gap-6">
          {/* We have no user  */}
          {false && isCorre && (
            <BreakoutButton
              kind={isArchived ? 'success' : 'error'}
              icon={
                isArchived ? <EyeOpen size={14} /> : <EyeClosed size={14} />
              }
              onClick={async () => {
                await cubit.updateOrganizationState(
                  isArchived
                    ? OrganizationState.active
                    : OrganizationState.inactive
                )
              }}
              data-testid="delete-organization-button"
            >
              {t(
                `organization.${isArchived ? 'unarchive_organization' : 'archive_organization'}`
              )}
            </BreakoutButton>
          )}
          <BreakoutButton
            disabled={isArchived}
            type="submit"
            kind="secondary"
            data-testid="update-organization-button"
            loading={isSubmitting}
          >
            {t('organization.save_edits')}
          </BreakoutButton>
        </div>
      </form>
    </>
  )
})

const AuthorizedUsersView = observer(function AuthorizedUsersView() {
  const { showDialog, clearAllDialogs } = useDialogs()
  const { t } = useTranslation()
  const cubit = useOrganizationCubit()
  const { loading, users } = cubit.adminsForOrganization

  const isArchived =
    cubit.organization.data.organizationState === OrganizationState.inactive

  const handleInviteNewOrgAdmin = useCallback(async () => {
    try {
      const invitationId = await cubit.createOrgInvitation({
        organizationAdmin: true,
      })
      const invitationUrl = `${window.location.origin}/invitation/${invitationId}`
      showDialog(() => (
        <LinkCopiedToClipboardDialog
          url={invitationUrl}
          warning={t('organization.invite_instructor_warning')}
        />
      ))
    } catch (e) {
      clearAllDialogs()
      toast.error('Invitation creation failure')
      throw e
    }
  }, [cubit, t, showDialog, clearAllDialogs])

  return (
    <div className="flex h-full max-h-full flex-col gap-5">
      <div className="text-title-large">{t(`organization.administrators`)}</div>
      <BreakoutAsyncButton
        kind="secondary"
        size="large"
        loading={loading}
        disabled={loading || isArchived}
        icon={<PlusIcon size={14} />}
        data-testid="add-new-organization-user-button"
        fullWidth
        onClick={handleInviteNewOrgAdmin}
      >
        {t('organization.add_new_administrator')}
      </BreakoutAsyncButton>
      <div className="mt-3 h-full flex-1 space-y-3 overflow-y-auto">
        {users.map((user) => (
          <AuthorizedUserRow key={user.id} user={user} cubit={cubit} />
        ))}
      </div>
    </div>
  )
})

const AuthorizedUserRow = observer(function AuthorizedUserRow({
  cubit,
  user,
}: {
  cubit: OrganizationCubit
  user: PublicUser
}) {
  const { showDialog } = useDialogs()
  const { t } = useTranslation()
  const isArchived =
    cubit.organization.data.organizationState === OrganizationState.inactive

  const breakoutUser = useBreakoutUser()
  return (
    <div
      key={user.id}
      className="group flex flex-row items-center gap-3 rounded-2xl bg-surface p-3"
    >
      <div>
        <BreakoutUserAvatar user={user} radius={18} />
      </div>
      <div className="flex-1">
        <div className="text-label-medium">{user.fullName}</div>
      </div>
      {breakoutUser.uid !== user.id && (
        <div>
          <BreakoutButton
            disabled={isArchived}
            size="small"
            type="submit"
            kind="secondary"
            data-testid="update-catalog-button"
            onClick={() => {
              showDialog(() => (
                <ConfirmationDialog
                  onConfirm={async () => {
                    await cubit.removeOrganizationalAdmin(user)
                  }}
                  buttonText={t('organization.remove_user_dialog_button')}
                  text={t('organization.remove_user_dialog_title')}
                  subtitle={t('organization.remove_user_dialog_subtitle')}
                />
              ))
            }}
            icon={<TrashCanIcon size={16} />}
          />
        </div>
      )}
    </div>
  )
})
