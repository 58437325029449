import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
  PartialWithFieldValue,
  Firestore,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore'
import {
  collection,
  deleteField,
  doc,
  or,
  query,
  serverTimestamp,
  where,
} from 'firebase/firestore'
import {
  convertDocumentSnapshotToModel,
  modelItemStream,
  modelListStream,
  partitionedQueryStream,
} from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { Section } from '../../models/Section'
import type { ImpersonationInfo, RoomStateVideoMethod } from '../../types'
import { SectionState } from '../../types'
import type { FirestoreSection } from './schema'
import { SectionPaymentMethod } from './schema'
import { schema, writeSchema } from './schema'
import {
  addDocWithError,
  getDocsWithError,
  getDocWithError,
  updateDocWithError,
} from '../../firestore-mobx/fetch'
import type { FieldValue } from 'firebase/firestore'
import { partition } from '../../util/arrays'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreSection> = {
  toFirestore: (data: PartialWithFieldValue<FirestoreSection>) => {
    writeSchema.partial().parse(data)

    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore
): CollectionReference<FirestoreSection> => {
  return collection(firestore, 'section').withConverter(converter)
}

const getDocRef = (
  firestore: Firestore,
  { sectionId }: { sectionId: string }
): DocumentReference<FirestoreSection, DocumentData> => {
  return doc(getColRef(firestore), sectionId)
}

export const getSections = (repository: FirebaseRepository) => {
  const q = query(
    getColRef(repository.firestore),
    or(
      where('userIds', 'array-contains', repository.uid),
      where('instructorUserId', '==', repository.uid)
    )
  )
  return modelListStream(repository, q, Section)
}

export const getSectionsStreamForInstructor = (
  repository: FirebaseRepository,
  impersonationInfo?: ImpersonationInfo
) => {
  const ref = getColRef(repository.firestore)
  const userId = impersonationInfo?.userId || repository.uid

  const q = query(ref, where('instructorUserId', '==', userId))

  return modelListStream(repository, q, Section)
}

export const getSectionsStreamForInstructors = (
  repository: FirebaseRepository,
  instructorIds: string[]
) => {
  return partitionedQueryStream({
    repository,
    ref: getColRef(repository.firestore),
    matchArray: instructorIds,
    model: Section,
    queryBuilder: (ref, ids) =>
      query(ref, where('instructorUserId', 'in', ids)),
  })
}

export const fetchSectionsForInstructors = async (
  repository: FirebaseRepository,
  { instructorIds }: { instructorIds: string[] }
) => {
  if (!instructorIds.length) return []
  const ref = getColRef(repository.firestore)
  const parts = partition(instructorIds, 15)
  const sections = await Promise.all(
    parts.map(async (part) => {
      const q = query(ref, where('instructorUserId', 'in', part))
      const snapshot = await getDocsWithError(
        q,
        'FetchSectionsForInstructorsError'
      )
      return snapshot.docs.map((doc) =>
        convertDocumentSnapshotToModel(repository, doc, Section)
      )
    })
  )
  return sections.flat()
}

export const fetchInstructorSections = async (
  repository: FirebaseRepository,
  {
    instructorUserId,
  }: {
    instructorUserId?: string
  }
) => {
  const q = query(
    getColRef(repository.firestore),
    or(where('instructorUserId', '==', instructorUserId || repository.uid))
  )

  const snapshot = await getDocsWithError(q, 'FetchInstructorSectionsError')

  return snapshot.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, Section)
  })
}

export const getSection = (
  repository: FirebaseRepository,
  { sectionId }: { sectionId: string }
) => {
  const ref = getColRef(repository.firestore)
  const docRef = doc(ref, sectionId)

  return modelItemStream(repository, docRef, Section)
}

export const getSectionsBydId = (
  repository: FirebaseRepository,
  sectionIds: string[]
) => {
  return partitionedQueryStream({
    repository,
    ref: getColRef(repository.firestore),
    matchArray: sectionIds,
    model: Section,
  })
}

export const fetchSection = async (
  repository: FirebaseRepository,
  { sectionId }: { sectionId: string }
) => {
  const ref = getColRef(repository.firestore)

  const docRef = doc(ref, sectionId)

  const snapshot = await getDocWithError(docRef, 'FetchSectionError')

  return convertDocumentSnapshotToModel(repository, snapshot, Section)
}

export const createSection = (
  repository: FirebaseRepository,
  {
    className,
    sectionName,
    userId,
    organizationId,
    sectionPaymentMethod,
    sectionPassPrice,
  }: {
    userId: string
    className: string
    sectionName: string
    organizationId?: string
    sectionPaymentMethod: SectionPaymentMethod
    sectionPassPrice?: number
  }
) => {
  const payload: Omit<FirestoreSection, 'updatedAt' | 'sectionPassPrice'> & {
    updatedAt: FieldValue
    sectionPassPrice?: number | FieldValue
  } = {
    className,
    sectionName,
    instructorUserId: userId,
    userIds: [],
    sectionState: SectionState.notStarted,
    updatedAt: serverTimestamp(),
    sectionPaymentMethod: sectionPaymentMethod,
  }

  if (
    [
      SectionPaymentMethod.sectionPassStripe,
      SectionPaymentMethod.sectionPassStripeCoupon,
    ].includes(sectionPaymentMethod) &&
    sectionPassPrice
  ) {
    payload.sectionPassPrice = sectionPassPrice
  }

  if (organizationId) {
    payload.organizationId = organizationId
  }

  return addDocWithError(
    getColRef(repository.firestore),
    payload,
    'CreateSectionError'
  )
}

export const updateSection = (
  repository: FirebaseRepository,
  sectionId: string,
  {
    className,
    sectionName,
    organizationId,
    sectionPaymentMethod,
    sectionPassPrice,
  }: {
    className: string
    sectionName: string
    organizationId?: string
    sectionPaymentMethod?: SectionPaymentMethod
    sectionPassPrice?: number
  }
) => {
  const payload: {
    className: string
    sectionName: string
    organizationId?: string | FieldValue
    updatedAt: FieldValue
    sectionPaymentMethod?: SectionPaymentMethod
    sectionPassPrice?: number | FieldValue
  } = {
    className,
    sectionName,
    updatedAt: serverTimestamp(),
  }

  if (sectionPaymentMethod !== undefined) {
    payload.sectionPaymentMethod = sectionPaymentMethod
    if (
      ![
        SectionPaymentMethod.sectionPassStripe,
        SectionPaymentMethod.sectionPassStripeCoupon,
      ].includes(sectionPaymentMethod)
    ) {
      payload.sectionPassPrice = deleteField()
    } else if (sectionPassPrice) {
      payload.sectionPassPrice = sectionPassPrice
    }
  }

  if (organizationId === '') {
    payload.organizationId = deleteField()
  } else if (organizationId) {
    payload.organizationId = organizationId
  }

  const docRef = getDocRef(repository.firestore, { sectionId })
  return updateDocWithError(docRef, payload, 'UpdateSectionError')
}

export const updateSectionDealDetails = async (
  repository: FirebaseRepository,
  {
    sectionId,
    hubspotDealId,
    sectionDealExpiresAt,
  }: {
    sectionId: string
    hubspotDealId: string | null
    sectionDealExpiresAt: Timestamp | null
  }
) => {
  const payload: {
    hubspotDealId?: string | FieldValue
    sectionDealExpiresAt?: Timestamp | FieldValue
    updatedAt: FieldValue
  } = {
    updatedAt: serverTimestamp(),
  }

  if (hubspotDealId === null) {
    payload.hubspotDealId = deleteField()
  } else {
    payload.hubspotDealId = hubspotDealId
  }

  if (sectionDealExpiresAt === null) {
    payload.sectionDealExpiresAt = deleteField()
  } else {
    payload.sectionDealExpiresAt = sectionDealExpiresAt
  }

  const docRef = getDocRef(repository.firestore, { sectionId })
  return updateDocWithError(docRef, payload, 'UpdateSectionDealDetailsError')
}

export const touchSection = (
  repository: FirebaseRepository,
  sectionId: string
) => {
  const payload = {
    updatedAt: serverTimestamp(),
  }
  const docRef = getDocRef(repository.firestore, { sectionId })
  return updateDocWithError(docRef, payload, 'TouchSectionError')
}

export async function setSectionState(
  repository: FirebaseRepository,
  params: {
    sectionId: string
    sectionState: SectionState
  }
) {
  const colRef = getColRef(repository.firestore)

  const docRef = doc(colRef, params.sectionId)

  return updateDocWithError(
    docRef,
    {
      sectionState: params.sectionState,
      updatedAt: serverTimestamp(),
    },
    'SetSectionStateError'
  )
}

export async function setSectionInvoiced(
  repository: FirebaseRepository,
  params: {
    sectionId: string
  }
) {
  const colRef = getColRef(repository.firestore)

  const docRef = doc(colRef, params.sectionId)

  return updateDocWithError(
    docRef,
    {
      invoiced: true,
      updatedAt: serverTimestamp(),
    },
    'SetSectionInvoicedError'
  )
}

export async function setSectionNotInvoiced(
  repository: FirebaseRepository,
  params: {
    sectionId: string
  }
) {
  const colRef = getColRef(repository.firestore)

  const docRef = doc(colRef, params.sectionId)

  return updateDocWithError(
    docRef,
    {
      invoiced: false,
      updatedAt: serverTimestamp(),
    },
    'SetSectionInvoicedError'
  )
}

export function getSharedSections(repository: FirebaseRepository) {
  const ref = getColRef(repository.firestore)
  const q = query(ref, where('shareable', '==', true))
  return modelListStream(repository, q, Section)
}

export const getSectionsByOrganizationId = (
  repository: FirebaseRepository,
  organizationId: string
) => {
  const ref = getColRef(repository.firestore)

  const q = query(ref, where('organizationId', '==', organizationId))

  return modelListStream(repository, q, Section)
}

export const setSectionPassPriceForSection = async (
  repository: FirebaseRepository,
  {
    sectionId,
    priceInCents,
  }: {
    sectionId: string
    priceInCents: number | null
  }
) => {
  const docRef = getDocRef(repository.firestore, { sectionId })
  updateDocWithError(
    docRef,
    {
      sectionPassPrice:
        priceInCents !== null && priceInCents >= 100
          ? priceInCents
          : deleteField(),
      updatedAt: serverTimestamp(),
    },
    'setSectionPassPriceForSection'
  )
}

export const updateSectionVideoMethod = async (
  repository: FirebaseRepository,
  {
    sectionId,
    videoMethod,
  }: {
    sectionId: string
    videoMethod: RoomStateVideoMethod | null
  }
) => {
  const ref = getDocRef(repository.firestore, { sectionId })
  return await updateDocWithError(
    ref,
    {
      videoMethod: videoMethod || deleteField(),
    },
    'updateSectionVideoMethodError'
  )
}

export const updateSectionPaymentMethod = async (
  repository: FirebaseRepository,
  {
    sectionId,
    sectionPaymentMethod,
  }: {
    sectionId: string
    sectionPaymentMethod: SectionPaymentMethod
  }
) => {
  const docRef = getDocRef(repository.firestore, { sectionId })
  return updateDocWithError(
    docRef,
    {
      sectionPaymentMethod,
      updatedAt: serverTimestamp(),
    },
    'UpdateSectionPaymentMethodError'
  )
}
