import { z } from 'zod'
import {
  nullSafeFirestoreTimestamp,
  nullSafeOptionalString,
} from '../shared/schema'

export const defaultTokenPrice = 10

export const schema = z.object({
  stripeProductId: nullSafeOptionalString(),
  tokenPrice: z.coerce
    .number()
    .or(z.string())
    .transform((val) => {
      if (typeof val === 'string') {
        return parseInt(val, 10)
      } else {
        return val
      }
    })
    .refine((val) => !isNaN(val) && Number.isInteger(val), {
      message: 'tokenPrice must be a valid integer',
    }),
  updatedAt: nullSafeFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    stripeProductId: '',
    tokenPrice: defaultTokenPrice,
    updatedAt: null,
  })
)

export interface FirestoreSettingsPricing extends z.infer<typeof schema> {}
