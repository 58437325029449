import z from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeFirestoreTimestamp,
  nullSafeOptionalBoolean,
  nullSafeOptionalNumber,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalString,
  nullSafeStringArray,
} from '../shared/schema'
import { RoomStateVideoMethod } from '../RoomState/types'

export enum SectionState {
  unconfigured = -1,
  notStarted = 0,
  inProgress = 1,
  completed = 2,
}

export enum SectionPaymentMethod {
  token = 0,
  sectionPassStripe = 1,
  sectionPassCoupon = 2,
  sectionPassStripeCoupon = 3,
}

export const schema = z.object({
  className: z.string(),
  instructorUserId: z.string(),
  invoiced: nullSafeOptionalBoolean(false),
  canvasCourseId: nullSafeOptionalString(),
  sectionName: z.string(),
  sectionState: z.preprocess((val) => {
    return val ?? SectionState.notStarted
  }, z.nativeEnum(SectionState)),
  sectionPaymentMethod: z.preprocess(
    (val) => val ?? SectionPaymentMethod.token,
    z.nativeEnum(SectionPaymentMethod)
  ),
  shareable: nullSafeOptionalBoolean(false),
  userIds: nullSafeStringArray([]),
  sectionPassPrice: nullSafeOptionalNumber(),
  organizationId: nullSafeOptionalString(),
  updatedAt: nullSafeFirestoreTimestamp(),
  videoMethod: z
    .preprocess(
      (val) => (val as RoomStateVideoMethod) ?? RoomStateVideoMethod.broadcast,
      z.nativeEnum(RoomStateVideoMethod)
    )
    .optional(),
  hubspotDealId: nullSafeOptionalString(),
  sectionDealExpiresAt: nullSafeOptionalFirestoreTimestamp(),
})

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    instructorUserId: '',
    className: '',
    sectionName: '',
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export type FirestoreSectionInferredType = z.infer<typeof schema>

export interface FirestoreSection extends FirestoreSectionInferredType {}
