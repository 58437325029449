import z from 'zod'
import {
  firestoreTimestamp,
  firestoreWriteTimestamp,
  nullSafeFirestoreTimestamp,
  nullSafeString,
} from '../shared/schema'

export enum FirestoreRoomMessageStatus {
  Delivered = 'delivered',
  Error = 'error',
  Seen = 'seen',
  Sending = 'sending',
  Sent = 'sent',
}

export enum FirestoreRoomMessageType {
  Audio = 'audio',
  Custom = 'custom',
  File = 'file',
  Image = 'image',
  System = 'system',
  Unsupported = 'unsupported',
  Video = 'video',

  /// SUPPORTED
  /** only used by meeting */
  Text = 'text',
  /** scheduling dialog messages by users */
  Scheduling = 'scheduling',
  /** system message generated by server -- only to be shown in scheduling dialog */
  SchedulingBot = 'schedulingBot',
}

export const schema = z.object({
  authorId: z.string(),
  type: z.preprocess(
    (val) => val ?? FirestoreRoomMessageType.Text,
    z.nativeEnum(FirestoreRoomMessageType)
  ),
  status: z.preprocess(
    (val) => val ?? FirestoreRoomMessageStatus.Sending,
    z.nativeEnum(FirestoreRoomMessageStatus)
  ),
  seenBy: z.record(z.string(), firestoreTimestamp).optional(),
  // guarantee text to be a string
  text: nullSafeString(''),
  createdAt: nullSafeFirestoreTimestamp(),
  updatedAt: nullSafeFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    authorId: '',
    type: FirestoreRoomMessageType.Text,
    status: FirestoreRoomMessageStatus.Sending,
    text: '',
    createdAt: { seconds: 0, nanoseconds: 0 },
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export const writeSchema = schema
  .extend({
    seenBy: z.record(z.string(), firestoreWriteTimestamp).optional(),
    createdAt: firestoreWriteTimestamp,
    updatedAt: firestoreWriteTimestamp,
  })
  .omit({ status: true })

export type FirestoreRoomMessage = z.infer<typeof schema>

export type FirestoreRoomMessageWrite = Omit<FirestoreRoomMessage, 'status'>
