import type { CollectionReference, DocumentData } from 'firebase/firestore'
import {
  collection,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { FirestoreSlideCaption } from './schema'
import { schema } from './schema'
import { convertDocumentSnapshotToModel } from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { SlideCaption } from '../../models/SlideCaption'
import { getDocsWithError } from '../../firestore-mobx/fetch'
import type { SlideModel } from '../../models/SlideModel'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreSlideCaption> = {
  toFirestore: () => {
    throw new Error('Client cannot write FirestoreRoomStateSummary')
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getCollectionRef = (
  firestore: Firestore,
  params: {
    slideDeckId: string
    slideId: string
  }
): CollectionReference<FirestoreSlideCaption, DocumentData> => {
  return collection(
    firestore,
    'slide_deck',
    params.slideDeckId,
    'slide',
    params.slideId,
    'slide_caption'
  ).withConverter(converter)
}

export const fetchSlideCaptions = async (
  repository: FirebaseRepository,
  params: {
    slideDeckId: string
    slide: SlideModel
  }
) => {
  const ref = getCollectionRef(repository.firestore, {
    slideDeckId: params.slideDeckId,
    slideId: params.slide.id,
  })

  const { mediaId } = params.slide.data

  if (mediaId) {
    // Get captions from the media file
    const mediaRef = collection(
      repository.firestore,
      `media/${mediaId}/media_caption`
    ).withConverter(converter)

    const snapshot = await getDocsWithError(mediaRef, 'FetchSlideCaptionsError')
    return snapshot.docs.map((doc) => {
      return convertDocumentSnapshotToModel(repository, doc, SlideCaption)
    })
  } else {
    const snapshot = await getDocsWithError(ref, 'FetchSlideCaptionsError')
    return snapshot.docs.map((doc) => {
      return convertDocumentSnapshotToModel(repository, doc, SlideCaption)
    })
  }
}
