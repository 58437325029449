import { httpsCallable } from 'firebase/functions'
import type { DateTime } from 'luxon'
import { action, computed, makeObservable, observable, when } from 'mobx'
import type { StaticModelCollection } from '../firestore-mobx/model'
import { assert } from '../firestore-mobx/utils'
import type { RoomStateVideoMethod } from '../firestore/RoomState/types'
import {
  createRoomState,
  forceAIProcessing,
  getRoomState,
  getRoomStates,
  leaveRoom,
  roomStateAddGroupLeader,
  roomStateAddUser,
  roomStateReset,
  updateRoomState,
} from '../firestore/RoomState'
import {
  getRoomStateAnswers,
  submitUserAnswer,
  upsertUserAnswer,
} from '../firestore/RoomStateAnswer'
import { getSection } from '../firestore/Section'
import {
  fetchSectionAssignments,
  getSectionAssignment,
} from '../firestore/SectionAssignment'
import { fetchSlideDeck, fetchSlideDecksById } from '../firestore/SlideDeck'
import { fetchSlideDeckMaterialsForStudent } from '../firestore/SlideDeckMaterial'
import { fetchSlideQuestions } from '../firestore/SlideQuestion'
import type { BreakoutUser } from '../models/BreakoutUser'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { RoomState } from '../models/RoomState'
import { RoomStateAnswer } from '../models/RoomStateAnswer'
import { Section } from '../models/Section'
import { SectionAssignment } from '../models/SectionAssignment'
import { SlideDeck } from '../models/SlideDeck'
import { SlideDeckMaterial } from '../models/SlideDeckMaterial'
import { SlideQuestion } from '../models/SlideQuestion'
import { ValidLibraryObject } from '../stores/ValidLibraryObject'
import { LibraryObjectState } from '../types'
import { Cubit } from './core'
import { ManagedDialogState } from './shared/ManagedDialogState'
import { getRoomStateSummary } from '../firestore/RoomStateSummary'
import { RoomStateSummary } from '../models/RoomStateSummary'
import { SlideQuestionType } from '../models/SlideQuestionType'
import { captureException } from '@sentry/core'
import { SlideModel } from '../models/SlideModel'
import { fetchSlides } from '../firestore/Slide'
import { calculateTimeLeftInSession } from '../util/time'
import { getSettingsSectionPassPricing } from '../firestore/SettingsSectionPassPricing'
import { SettingsSectionPassPricing } from '../models/SettingsSectionPassPricing'
import { UserProfileSectionPass } from '../models/UserProfileSectionPass'
import { getSectionPassForSection } from '../firestore/UserProfileSectionPass'

export class StudentAssignmentCubit extends Cubit {
  repository: FirebaseRepository
  user: BreakoutUser

  sectionId: string
  assignmentId: string
  @observable roomStateId?: string

  section: Section
  assignment: SectionAssignment
  slideDeck: SlideDeck
  roomState: RoomState
  roomStates: StaticModelCollection<RoomState>
  roomStateSummary: RoomStateSummary
  slides: StaticModelCollection<SlideModel>
  sectionPassPricing: SettingsSectionPassPricing
  sectionPass: UserProfileSectionPass

  questions: StaticModelCollection<SlideQuestion>
  answers: StaticModelCollection<RoomStateAnswer>
  private _materials: StaticModelCollection<SlideDeckMaterial>
  private _assignments: StaticModelCollection<SectionAssignment>
  private _slideDecks: StaticModelCollection<SlideDeck>
  _joiningSession = false

  joinDialogState = new ManagedDialogState()
  enrollDialogState = new ManagedDialogState()

  @observable paymentUrlLoading = false
  @observable paymentUrl = ''

  constructor(
    repository: FirebaseRepository,
    {
      sectionId,
      assignmentId,
      roomStateId,
    }: {
      sectionId: string
      assignmentId: string
      roomStateId?: string
    }
  ) {
    super()
    this.repository = repository
    const user = repository.breakoutUser
    assert(user, 'User must be logged in')
    this.user = user

    makeObservable(this)

    this.sectionId = sectionId
    this.assignmentId = assignmentId
    this.roomStateId = roomStateId

    this.section = Section.empty(this.repository)
    this.assignment = SectionAssignment.empty(this.repository)
    this.slideDeck = SlideDeck.empty(this.repository)
    this.roomState = RoomState.empty(this.repository)
    this.slides = SlideModel.emptyCollection(repository)

    this.questions = SlideQuestion.emptyCollection(this.repository)
    this.answers = RoomStateAnswer.emptyCollection(this.repository)
    this._materials = SlideDeckMaterial.emptyCollection(this.repository)
    this._assignments = SectionAssignment.emptyCollection(this.repository)
    this._slideDecks = SlideDeck.emptyCollection(this.repository)

    this.roomStateSummary = RoomStateSummary.empty(repository)
    this.roomStates = RoomState.emptyCollection(repository)
    this.sectionPassPricing = SettingsSectionPassPricing.empty(repository)
    this.sectionPass = UserProfileSectionPass.empty(repository)
  }

  initialize(): void {
    this.addStream(
      getSection(this.repository, { sectionId: this.sectionId }),
      (section) => {
        this.section.replaceModel(section)
        const sectionPassKey = 'section-pass-pricing'

        if (!this.section.usesSectionPass || this.hasStream(sectionPassKey))
          return

        this.addStream(
          getSettingsSectionPassPricing(this.repository),
          (pricing) => {
            this.sectionPassPricing.replaceModel(pricing)
          },
          {
            name: sectionPassKey,
          }
        )

        this.addStream(
          getSectionPassForSection(this.repository, {
            userId: this.repository.uid,
            sectionId: this.sectionId,
          }),
          (pass) => {
            this.sectionPass.replaceModel(pass)

            const hasSectionPass = pass.hasData

            if (!hasSectionPass) {
              fetchSectionAssignments(this.repository, {
                sectionId: this.sectionId,
                onlyPublished: true,
              }).then((assignments) => {
                this._assignments.replaceModels(assignments)

                const slideDeckIds = assignments.map((a) => a.data.slideDeckId)

                if (slideDeckIds.length > 0) {
                  fetchSlideDecksById(this.repository, {
                    slideDeckIds,
                  }).then((slideDecks) => {
                    this._slideDecks.replaceModels(slideDecks)
                  })
                }
              })
            }
          }
        )
      },
      {
        name: 'section',
      }
    )

    this.addStream(
      getSectionAssignment(this.repository, {
        sectionId: this.sectionId,
        assignmentId: this.assignmentId,
      }),
      (assignment) => {
        this.assignment.replaceModel(assignment)

        this.fetchSlideDeck(assignment.data.slideDeckId)

        // only fetch the rest if the assignment is either free or paid for
        if (this.libraryObject.hasAccessToSlideDeckContents) {
          this.fetchQuestions(assignment.data.slideDeckId) // questions never change
          this.fetchSlides(assignment.data.slideDeckId)
          this.fetchMaterials(assignment.data.slideDeckId)
        }
      },
      {
        name: 'assignment',
      }
    )

    this.addStream(
      getRoomStates(this.repository, {
        sectionId: this.sectionId,
        assignmentId: this.assignmentId,
      }),
      (roomStates) => {
        this.roomStates.replaceModels(roomStates)
        let foundRoom = false
        if (this.roomStateId) {
          // room was selected in the router
          const room = this.roomStates._models.find(
            (room) => room.id === this.roomStateId
          )
          if (room && this.userCanViewRoom(room, this.user)) {
            // room was found
            foundRoom = true
          } else {
            // room was not found
            this.changeRoomStateId(undefined)
          }
        }
        if (!foundRoom) {
          // no rooms were selected in the router, find a room
          const participantRoom = this.roomStates._models.find((room) =>
            room.userIds.includes(this.repository.uid)
          )
          const roomId = participantRoom?.id || undefined
          if (roomId !== this.roomStateId) {
            this.changeRoomStateId(roomId)
          }
        }
      }
    )

    this.restartRoomStateStream()
    this.restartUserAnswersStream()
    this.restartRoomStateSummaryStream()

    this.addReaction({
      whenThisChanges: () => this.roomStateId,
      thenRunThisCode: () => {
        this.restartRoomStateStream()
        this.restartUserAnswersStream()
        this.restartRoomStateSummaryStream()
      },
    })

    this.addReaction({
      whenThisChanges: () => this.userIsInCurrentRoom,
      thenRunThisCode: () => {
        this.restartUserAnswersStream()
      },
    })

    this.addReaction({
      whenThisChanges: () => !this.libraryObject.requiresPayment,
      thenRunThisCode: (shouldRun) => {
        if (shouldRun) {
          this.fetchSlideDeck(this.libraryObject.slideDeckId)
        }
      },
    })

    this.addReaction({
      whenThisChanges: () => this.libraryObject.hasAccessToSlideDeckContents,
      thenRunThisCode: (shouldRun) => {
        if (shouldRun) {
          this.fetchQuestions(this.libraryObject.slideDeckId)
          this.fetchSlides(this.libraryObject.slideDeckId)
          this.fetchMaterials(this.libraryObject.slideDeckId)
        }
      },
    })
  }

  async fetchSlides(slideDeckId: string) {
    if (!slideDeckId) return

    const slides = await fetchSlides(this.repository, {
      slideDeckId: slideDeckId,
    })
    this.slides.replaceModels(slides)
  }

  @action
  changeRoomStateId(roomStateId: string | undefined) {
    this.roomStateId = roomStateId
  }

  restartRoomStateStream(retryCount: number = 0): void {
    const key = 'room-state'
    if (this.hasStream(key)) this.removeStream(key)

    if (!this.roomStateId) {
      this.roomState.replaceModel(RoomState.empty(this.repository))
      return
    }

    this.addStream(
      getRoomState(this.repository, { roomStateId: this.roomStateId }),
      (roomState) => {
        // if the user was removed change roomStateId to undefined
        // this will trigger a redirect to the assignment page sans room
        const currentUserId = this.repository.currentUser?.uid
        const previousUserIds = this.roomState.data.userIds
        const updatedUserIds = roomState.data.userIds
        if (
          currentUserId &&
          previousUserIds.includes(currentUserId) &&
          !updatedUserIds.includes(currentUserId)
        ) {
          this.changeRoomStateId(undefined)
        }
        // if room state is now invalid, don't update
        if (this.roomStateId === roomState.id)
          this.roomState.replaceModel(roomState)
      },
      {
        name: key,
        disableCaptureException: true,
        onError: (err) => {
          this.roomStateSummary.changeLoadingState(true)
          if (retryCount < 5) {
            setTimeout(() => {
              this.restartRoomStateStream(retryCount + 1)
            }, 400 * retryCount)
          } else {
            console.error(err)
            captureException(err)
          }
        },
      }
    )
  }

  restartRoomStateSummaryStream(retryCount: number = 0) {
    const key = 'summary'
    if (this.hasStream(key)) this.removeStream(key)

    if (!this.roomStateId) {
      this.roomState.replaceModel(RoomState.empty(this.repository))
      return
    }

    this.addStream(
      getRoomStateSummary(this.libraryObject.repository, {
        roomId: this.roomStateId,
      }),
      (summary) => {
        this.roomStateSummary.replaceModel(summary)
      },
      {
        disableCaptureException: true,
        onError: (err) => {
          this.roomStateSummary.changeLoadingState(true)
          if (retryCount < 5) {
            setTimeout(() => {
              this.restartRoomStateSummaryStream(retryCount + 1)
            }, 400 * retryCount)
          } else {
            console.error(err)
            captureException(err)
          }
        },
      }
    )
  }

  @computed
  get estimatedExperienceDurationInMinutes(): number {
    const durationInMinutes = (slide: SlideModel) => {
      let duration = 0
      if (slide.data.slideVideoDuration) {
        duration = Math.ceil(slide.data.slideVideoDuration / 60)
      } else {
        duration = Math.ceil(slide.data.slideDuration / 60)
      }
      return duration
    }

    return calculateTimeLeftInSession({
      slides: this.slides._models.map((slide) => ({
        durationInMinutes: durationInMinutes(slide),
      })),
      roundToNearest: 5,
    })
  }

  // semaphore to guarantee single fetch at a time
  private fetchingMaterials = false
  async fetchMaterials(slideDeckId: string) {
    // if we have them, abort
    if (this._materials.isLoaded) return
    if (this.fetchingMaterials) return
    if (!slideDeckId) return

    this.fetchingMaterials = true

    try {
      const materials = await fetchSlideDeckMaterialsForStudent(
        this.repository,
        {
          slideDeckId,
        }
      )

      this._materials.replaceModels(materials)
    } finally {
      this.fetchingMaterials = false
    }
  }

  private slideDeckFetched?: string
  async fetchSlideDeck(slideDeckId: string) {
    // guard - only fetch the slide deck once
    if (this.slideDeckFetched === slideDeckId) return
    this.slideDeckFetched = slideDeckId

    try {
      const slideDeck = await fetchSlideDeck(this.repository, {
        slideDeckId: slideDeckId,
      })

      this.slideDeck.replaceModel(slideDeck)
    } catch (e) {
      // but if we fail, we should try again next time
      this.slideDeckFetched = undefined
    }
  }

  private questionsFetched?: string
  async fetchQuestions(slideDeckId: string) {
    // guard - only fetch the questions once
    if (this.questionsFetched === slideDeckId) return
    this.questionsFetched = slideDeckId

    try {
      const questions = await fetchSlideQuestions(this.repository, {
        slideDeckId: slideDeckId,
      })

      this.questions.replaceModels(questions)

      // TODO: here subscribe to answers for each question
    } catch (e) {
      // but if we fail, we should try again next time
      this.questionsFetched = undefined
    }
  }

  restartUserAnswersStream(retryCount: number = 0) {
    const key = 'user-answers'
    if (this.hasStream(key)) this.removeStream(key)
    if (!this.roomStateId || !this.userIsInCurrentRoom) {
      this.answers.replaceModels([])
      this.answers.changeLoadingState(true)
      return
    }
    this.addStream(
      getRoomStateAnswers(this.repository, {
        roomId: this.roomStateId,
        userId: this.repository.uid,
      }),
      (answers) => {
        this.answers.replaceModels(answers)
      },
      {
        name: key,
        disableCaptureException: true,
        onError: (err) => {
          this.answers.changeLoadingState(true)
          if (retryCount < 5) {
            setTimeout(() => {
              this.restartUserAnswersStream(retryCount + 1)
            }, 400 * retryCount)
          } else {
            console.error(err)
            captureException(err)
          }
        },
      }
    )
  }

  async updateRoomVideoMethod(videoMethod: RoomStateVideoMethod) {
    if (!this.roomStateId) return

    return updateRoomState(this.repository.firestore, this.roomStateId, {
      videoMethod,
    })
  }

  async leaveRoom(): Promise<void> {
    if (!this.roomStateId) return Promise.resolve()

    await leaveRoom(this.repository.firestore, {
      roomId: this.roomStateId,
      userId: this.repository.uid,
    })

    this.logEvent('room_leave', { room_id: this.roomStateId })
    this.changeRoomStateId(undefined)
  }

  async joinRoom(
    roomId: string,
    { isGroupLeader = false }: { isGroupLeader: boolean }
  ): Promise<void> {
    await roomStateAddUser(this.repository.firestore, {
      roomId,
      userId: this.repository.uid,
      isGroupLeader: isGroupLeader,
    })
    this.logEvent('room_state_joined', { room_id: roomId })
    this.changeRoomStateId(roomId)
  }

  async updateRoom(newName: string, newDateTime: DateTime) {
    if (!this.roomStateId) {
      const newRoomState = await createRoomState(this.repository.firestore, {
        userId: this.repository.uid,
        scheduled: newDateTime.toJSDate(),
        name: newName,
        assignmentId: this.assignmentId,
        sectionId: this.sectionId,
        slideDeckId: this.slideDeck.id,
      })
      this.changeRoomStateId(newRoomState.id)
      this.logEvent('room_state_created', { room_id: newRoomState.id })
    } else {
      if (
        !this.currentUserIsGroupLeader &&
        this.roomState.data.userIds.includes(this.repository.uid)
      ) {
        await roomStateAddGroupLeader(
          this.repository.firestore,
          this.roomStateId,
          this.repository.uid
        )
      }
      this.logEvent('room_state_updated', { room_id: this.roomStateId })
      if (!this.roomState.data.scheduledAt && newDateTime) {
        this.logEvent('room_schedule', { room_id: this.roomStateId })
      }
      return updateRoomState(this.repository.firestore, this.roomStateId, {
        roomStateName: newName,
        scheduledAt: newDateTime.toJSDate(),
      })
    }
  }

  async answerQuestion(payload: {
    questionId: string
    answerId?: string
    answer: number
    answerList?: number[]
  }) {
    if (!this.roomStateId) return Promise.resolve()

    this.logEvent('room_answer_question', {
      slide_question_id: payload.questionId,
    })

    return upsertUserAnswer(this.repository, payload.questionId, {
      userId: this.repository.uid,
      roomId: this.roomStateId,
      assignmentId: this.assignmentId,
      sectionId: this.sectionId,
      answer: payload.answer,
      answerId: payload.answerId || '',
      answerList: payload.answerList || [],
      isGroupAnswer: false,
    })
  }

  async submitAnswer(payload: { answerId: string }) {
    if (!this.roomStateId) return

    this.logEvent('room_answer_submitted', {
      answerId: payload.answerId,
    })

    submitUserAnswer(this.repository.firestore, {
      roomId: this.roomStateId,
      userId: this.repository.uid,
      answerId: payload.answerId,
      isGroupAnswer: false,
    })
  }

  async startMeeting() {
    const roomStateId = this.roomStateId
    if (!roomStateId) return

    this.roomState.startRoomIfNotStarted()

    this.logEvent('room_start', { room_id: roomStateId })
  }

  @computed
  get userIsInCurrentRoom() {
    return this.roomState.userIds.includes(this.repository.uid)
  }

  get materials() {
    return this._materials
  }

  @computed
  get roomStateStatus() {
    return this.roomState.getRoomStateStatus(
      this.assignment.expiresAt,
      this.section.data.sectionState,
      this.slideDeck.data.slideDeckSlideCount,
      this.assignment.data.assignmentState
    )
  }

  @computed
  get didRoomStart() {
    return this.roomState.didStart
  }

  @computed
  get hasPreWorkQuiz(): boolean {
    return this.questions.isLoaded && this.preWorkQuestions.length > 0
  }

  @computed
  get isReady(): boolean {
    let extrasLoaded = false // TODO: Name this better?
    if (!this.section.usesSectionPass) {
      extrasLoaded = true
    } else if (this.sectionPass.isLoaded && this.sectionPass.hasData) {
      extrasLoaded = true
    } else if (this._assignments.isLoaded && this._slideDecks.isLoaded) {
      extrasLoaded = true
    }

    return (
      this.section.isLoaded &&
      (!this.section.usesSectionPass || this.sectionPassPricing.isLoaded) &&
      this.assignment.isLoaded &&
      this.slideDeck.isLoaded &&
      this.user.tokensLoaded &&
      extrasLoaded
    )
  }

  @computed
  get hasQuizData(): boolean {
    return this.questions.isLoaded && this.answers.isLoaded
  }

  @computed
  get preWorkQuestions(): SlideQuestion[] {
    const validQuestions = this.questions.models
      .filter((question) => question.data.slideId === 'pre-meeting-quiz')
      .filter(
        (question) =>
          question.data.questionType === 1 || question.data.questionType === 4
      )
    const sortedQuestions = validQuestions.sort((a, b) =>
      a.data.question.localeCompare(b.data.question)
    )
    return sortedQuestions
  }

  @computed
  get preWorkAnswersByQuestionId() {
    const answersByQuestionId: Record<string, RoomStateAnswer | undefined> = {}
    const preWorkQuestionIds = this.preWorkQuestions.map((q) => q.id)
    for (const a of this.answers.models) {
      const slideQuestionId = a.data.slideQuestionId
      if (preWorkQuestionIds.includes(slideQuestionId)) {
        answersByQuestionId[a.data.slideQuestionId] = a
      }
    }
    return answersByQuestionId
  }

  @computed
  get allPreWorkQuestionsAnswered(): boolean {
    const answerLookupByQuestionId: Record<string, boolean> = {}
    for (const a of this.answers.models) {
      answerLookupByQuestionId[a.data.slideQuestionId] = !!a.data.submitted
    }

    return this.preWorkQuestions.every(
      (q) => answerLookupByQuestionId[q.id] === true
    )
  }

  @computed
  get preWorkQuizResult() {
    const questions = this.preWorkQuestions
    const answersByQuestionId = this.preWorkAnswersByQuestionId
    let questionCount = 0
    let total = 0
    questions.map((question) => {
      questionCount++
      const answer = answersByQuestionId[question.id]
      if (question.data.questionType === SlideQuestionType.multipleChoice) {
        if (answer?.data.answer === question.data.correct) {
          total += 1
        }
      } else if (question.data.questionType === SlideQuestionType.sorting) {
        const answerList = answer?.data.answerList || []

        const sum = answerList
          .filter((answer): answer is number => typeof answer === 'number')
          .map((answer, index) => Math.abs(index - answer))
          .reduce((value, element) => value + element, 0)

        const worst = question.worstPossibleScore

        const result = (worst - (sum ?? 0)) / worst

        if (answerList.length === question.data.answers.length) {
          total += result
        }
      }
    })

    return questionCount === 0 ? 0 : Math.floor((total / questionCount) * 100)
  }

  @computed
  get sortedRoomStateUsers() {
    return this.roomState.users.sort((a, b) => {
      return a.fullName.localeCompare(b.fullName)
    })
  }

  @computed
  get currentUserIsGroupLeader() {
    return this.roomState.data.groupLeaderUserIds.includes(this.repository.uid)
  }

  @computed
  get currentUserIsInSection() {
    return this.section.data.userIds.includes(this.repository.uid)
  }

  @computed
  get currentUserIsInstructor() {
    return this.section.data.instructorUserId === this.repository.uid
  }

  @computed
  get libraryObject() {
    return new ValidLibraryObject({
      repository: this.repository,
      section: this.section,
      assignment: this.assignment,
      slideDeck: this.slideDeck,
      roomState: this.roomState,
      quizData: {
        slideQuestions: this.questions.models,
        userAnswers: this.answers.models,
      },
      sectionPassPricing: this.sectionPassPricing,
    })
  }

  @computed
  get sectionAssignmentsWithSlideDecks() {
    const assignments = this._assignments.models
    const slideDecks = this._slideDecks.models

    const slideDeckMap = new Map(slideDecks.map((deck) => [deck.id, deck]))

    return assignments.map((assignment) => ({
      assignment,
      slideDeck: slideDeckMap.get(assignment.data.slideDeckId),
    }))
  }

  // Dialogs
  /// if the dialog has not popped / is not popped and session started recently
  get canPopJoinDialog() {
    const { activeSlide } = this.roomState.data
    const sessionLive =
      this.libraryObject.libraryObjectState === LibraryObjectState.live
    typeof activeSlide === 'number' && activeSlide < 2

    return (
      !this._joiningSession &&
      sessionLive &&
      this.joinDialogState.canShow &&
      !this.libraryObject.requiresPayment &&
      // don't pop join if user not in room
      this.roomState.userIds.includes(this.repository.uid) &&
      // need below data to be loaded to determine room state status
      this.slideDeck.isLoaded &&
      this.roomState.isLoaded &&
      this.assignment.isLoaded
    )
  }

  get canPopEnrollDialog() {
    return this.libraryObject.requiresPayment && this.enrollDialogState.canShow
  }

  setJoiningSession() {
    this._joiningSession = true
  }

  onDialogMountUnmount(
    event: 'mount' | 'unmount',
    dialogType: 'join' | 'enroll'
  ) {
    const manager =
      dialogType === 'join' ? this.joinDialogState : this.enrollDialogState
    if (event === 'mount') {
      manager.mounted()
    } else {
      manager.unmounted()
    }
  }

  // Payments

  /// create new stripe checkout page and return the sessionId
  /// to the caller
  async createStripeSession(
    quantity: number,
    successUrl: string,
    sectionPassSectionId?: string
  ) {
    const createCheckoutSession = httpsCallable<
      {
        quantity: number
        userId: string
        successUrl: string
        sectionPassSectionId?: string
      },
      string
    >(this.repository.functions, 'stripeCreateCheckoutSessionPayment', {
      timeout: 15 * 1000, // 15 seconds
    })

    try {
      const sessionId = await createCheckoutSession({
        quantity: quantity,
        userId: this.repository.uid,
        successUrl: successUrl,
        sectionPassSectionId: sectionPassSectionId,
        // todo: analytics hookup
        // analyticsClientId: analyticsClientId,
      })
      if (sessionId.data === null) {
        return ''
      }
      return sessionId.data as string
    } catch (e) {
      console.error('error creating stripe session', e)
      return ''
    }
  }

  async createPayment(quantityToBuy: number, sectionPassSectionId?: string) {
    if (this.libraryObject.slideDeckId === '') {
      throw Error('Slide deck not found.')
    }
    this.paymentUrlLoading = true
    const url = new URL(window.location.href)
    url.searchParams.set('payment', 'success')
    const successUrl = url.toString()
    const paymentUrl = await this.createStripeSession(
      quantityToBuy,
      successUrl,
      sectionPassSectionId
      //todo: analytics hookup
      //analyticsClientId: getAnalyticsClientId(),
    )

    this.paymentUrl = paymentUrl
    this.paymentUrlLoading = false
    // this.showPurchaseDialog = false;

    if (paymentUrl !== '') {
      window.location.href = paymentUrl
      // await _firebaseAnalytics.logBeginCheckout(
      //   value: price / 100,
      //   currency: 'USD',
      //   items: [
      //     AnalyticsEventItem(
      //       itemId: state.sectionAssignment.slideDeckId,
      //       itemName: slideDeck.slideDeckName,
      //       locationId: assignmentId,
      //       quantity: quantityToBuy,
      //       price: price / 100,
      //     ),
      //   ],
      // );
    } else {
      throw Error('Stripe failure.')
    }
  }

  async redeemSectionPassCoupon(couponId: string) {
    const redeemSectionPassCoupon = httpsCallable<
      {
        sectionId: string
        couponId: string
      },
      boolean | null | undefined | string
    >(this.repository.functions, 'redeemSectionPassCoupon')

    try {
      const { data: success } = await redeemSectionPassCoupon({
        sectionId: this.sectionId,
        couponId,
      })

      // if didn't success return the error msg or false
      if (success !== true) return success ? success : false

      // wait for section pass to exist
      // and tokens to be created via trigger
      await when(
        () =>
          this.user.sectionPassSectionIds.has(this.sectionId) &&
          this.libraryObject.hasAccessToSlideDeckContents,
        { timeout: 15_000 }
      )
      return true
    } catch (e) {
      captureException(e)
      return false
    }
  }

  async enroll() {
    const consumeTokensForAssignment = httpsCallable<
      { sectionId: string; assignmentId: string; roomId: string },
      string
    >(this.repository.functions, 'consumeTokensForAssignment')

    const { data } = await consumeTokensForAssignment({
      assignmentId: this.assignmentId,
      sectionId: this.sectionId,
      roomId: this.roomStateId || '',
    })
    const { message, success } = JSON.parse(data)

    return {
      success,
      message,
    } as { success: boolean; message: string | undefined | null }
  }

  async resetRoomState() {
    if (!this.roomStateId) return
    await roomStateReset(this.repository, { roomId: this.roomStateId })
    this.logEvent('room_reset')
  }

  /**
   *
   * returns **true** if the operation was successful or **false** if already processing
   */
  async startAIProcessing() {
    if (!this.roomStateId) return
    const success = await forceAIProcessing(this.repository, {
      roomId: this.roomStateId,
    })
    if (success) {
      this.logEvent('ai_processing_forced')
    }
    return success
  }

  private userCanViewRoom(roomState: RoomState, user: BreakoutUser) {
    const { userIds, hiddenUserIds } = roomState.data

    if ([...userIds, ...hiddenUserIds].includes(user.uid)) return true

    if (!user.profile.hasData) return false

    if (user.isFaculty || user.isCorre) return true

    return false
  }

  logEvent(name: string, params?: Record<string, unknown>) {
    this.repository.logEvent(name, {
      room_id: this.roomStateId,
      assignment_id: this.assignmentId,
      section_id: this.sectionId,
      slide_deck_id: this.slideDeck.id,
      ...params,
    })
  }
}
