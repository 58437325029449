import { RubricType } from '@breakoutlearning/firebase-repository/models/SlideRubric'
import {
  BloomScore,
  PassFailScore,
} from '@breakoutlearning/firebase-repository/types'
import type { Translation } from 'i18n/i18n'

export const getRubricBgColorByScore = ({
  type,
  score,
}: {
  type: RubricType
  score: BloomScore | PassFailScore
}) => {
  if (type === RubricType.blooms) {
    switch (score as BloomScore) {
      case BloomScore.none:
        return 'bg-breakout-color-scale-0'
      case BloomScore.remember:
        return 'bg-breakout-color-scale-1'
      case BloomScore.understand:
        return 'bg-breakout-color-scale-2'
      case BloomScore.apply:
        return 'bg-breakout-color-scale-3'
      case BloomScore.analyze:
        return 'bg-breakout-color-scale-4'
      case BloomScore.evaluate:
        return 'bg-breakout-color-scale-5'
      case BloomScore.create:
        return 'bg-breakout-color-scale-6'
      default:
        return 'bg-breakout-color-scale-0'
    }
  }

  switch (score as PassFailScore) {
    case PassFailScore.fail:
      return 'bg-breakout-red'
    case 1:
      return 'bg-breakout-dark-green'
    default:
      return 'bg-breakout-red'
  }
}

export const getRubricScoreLabel = (
  t: Translation,
  rubricType: RubricType,
  score: BloomScore | PassFailScore,
  overrides?: {
    rubricLabel0?: string
    rubricLabel1?: string
  }
) => {
  if (rubricType === RubricType.blooms) {
    switch (score) {
      case 0:
        return t('instructor_assignment.bloom_0')
      case 1:
        return t('instructor_assignment.bloom_1')
      case 2:
        return t('instructor_assignment.bloom_2')
      case 3:
        return t('instructor_assignment.bloom_3')
      case 4:
        return t('instructor_assignment.bloom_4')
      case 5:
        return t('instructor_assignment.bloom_5')
      case 6:
        return t('instructor_assignment.bloom_6')
      default:
        return ''
    }
  }
  switch (score) {
    case 0:
      return overrides?.rubricLabel0 || t('instructor_assignment.pass_fail_0')
    case 1:
      return overrides?.rubricLabel1 || t('instructor_assignment.pass_fail_1')
    default:
      return 'error'
  }
}

export const getRubricScoreDescription = (
  t: Translation,
  rubricType: RubricType,
  score: BloomScore | PassFailScore
) => {
  if (rubricType === RubricType.blooms) {
    switch (score) {
      case 1:
        return t('instructor_assignment.bloom_1_description')
      case 2:
        return t('instructor_assignment.bloom_2_description')
      case 3:
        return t('instructor_assignment.bloom_3_description')
      case 4:
        return t('instructor_assignment.bloom_4_description')
      case 5:
        return t('instructor_assignment.bloom_5_description')
      case 6:
        return t('instructor_assignment.bloom_6_description')
      default:
        return ''
    }
  }
  switch (score) {
    case 0:
      return t('instructor_assignment.pass_fail_0_description')
    case 1:
      return t('instructor_assignment.pass_fail_1_description')
    default:
      return ''
  }
}

export const getBloomLongDescription = (t: Translation, score: BloomScore) => {
  switch (score) {
    case 0:
      return t('instructor_assignment.bloom_0_long_description')
    case 1:
      return t('instructor_assignment.bloom_1_long_description')
    case 2:
      return t('instructor_assignment.bloom_2_long_description')
    case 3:
      return t('instructor_assignment.bloom_3_long_description')
    case 4:
      return t('instructor_assignment.bloom_4_long_description')
    case 5:
      return t('instructor_assignment.bloom_5_long_description')
    case 6:
      return t('instructor_assignment.bloom_6_long_description')
    default:
      return ''
  }
}
