import type { Section } from '@breakoutlearning/firebase-repository/models/Section'
import type { SectionRequest } from '@breakoutlearning/firebase-repository/models/SectionRequest'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutTextArea } from 'components/design-system/BreakoutTextArea'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { CheckIcon } from 'components/icons/Check'
import { CircleX } from 'components/icons/CircleX'
import { useDialogs } from 'hooks/dialogs'
import { useBreakoutUser } from 'hooks/profile'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CircleCheckFillIcon } from '../../components/icons/CircleCheckFill'
import { CircleXFillIcon } from '../../components/icons/CircleXFill'
import { SectionRequestState } from '@breakoutlearning/firebase-repository/types'

export const ViewRequestDialog = observer(function ViewRequestDialog({
  request,
  section,
  approveRequest,
  rejectRequest,
  close,
}: {
  request: SectionRequest
  section: Section
  approveRequest?: (request: SectionRequest) => Promise<void>
  rejectRequest?: (request: SectionRequest, reason: string) => Promise<void>
  close: () => void
}) {
  const { t } = useTranslation()

  const keyValues = useMemo(
    () => [
      [t('organization.instructor_name'), section.instructor.fullName],
      [t('organization.class_title'), section.data.className],
      [t('organization.section_title'), section.data.sectionName],
      [t('organization.est_students'), request.data.sectionRequestStudentCount],
      [
        t('organization.est_experiences'),
        request.data.sectionRequestAssignmentCount,
      ],
      [t('organization.est_cost'), request.estimatedCost],
      [t('organization.date'), request.monthDayYear],
    ],
    [t, section, request]
  )

  const { showDialog } = useDialogs()

  return (
    <Dialog size="md" innerClassName="flex flex-col h-full max-w-[400px]">
      <DialogCloseButton />
      <div className="text-headline-large">
        {t('organization.invoice_request')}
      </div>
      <div className="mt-3 space-y-1">
        {keyValues.map(([key, value]) => (
          <div key={key} className="grid grid-cols-2 gap-12">
            <div className="text-body-large text-on-surface-var">{key}</div>
            <div className="flex justify-end text-end text-label-large">
              {value}
            </div>
          </div>
        ))}
      </div>
      {request.data.sectionRequestState !== SectionRequestState.pending && (
        <ApproveRejectedDisplay request={request} />
      )}

      <div className="flex-1"></div>
      {approveRequest &&
        rejectRequest &&
        request.data.sectionRequestState === SectionRequestState.pending && (
          <ApproveDenyButtons
            onApprove={async () => {
              await approveRequest(request)
              close()
              setTimeout(
                () =>
                  showDialog(() => (
                    <RequestApprovedOrRejectedDialog mode="approved" />
                  )),
                0
              )
            }}
            onReject={() => {
              showDialog(({ remove: removeRejectDialog }) => (
                <RejectReasonDialog
                  onConfirm={async (reason: string) => {
                    await rejectRequest(request, reason)
                    removeRejectDialog()
                    close()
                    setTimeout(
                      () =>
                        showDialog(() => (
                          <RequestApprovedOrRejectedDialog mode="rejected" />
                        )),
                      0
                    )
                  }}
                />
              ))
            }}
          />
        )}
    </Dialog>
  )
})

const RequestApprovedOrRejectedDialog = observer(
  function RequestApprovedOrRejectedDialog({
    mode,
  }: {
    mode: 'approved' | 'rejected'
  }) {
    const { t } = useTranslation()
    return (
      <Dialog
        size="sm"
        innerClassName="flex flex-col gap-3 items-center justify-center text-center"
      >
        <DialogCloseButton />
        {mode === 'approved' ? (
          <>
            <CircleCheckFillIcon
              size={50}
              className="fill-core-success stroke-none"
            />
            <h2 className="text-headline-large">
              {t('organization.request_approved')}
            </h2>
            <strong className="text-body-medium">
              {t('organization.request_approved_sub')}
            </strong>
          </>
        ) : (
          <>
            <CircleXFillIcon
              size={50}
              className="fill-core-error stroke-none"
            />
            <h2 className="text-headline-large">
              {t('organization.request_rejected')}
            </h2>
            <strong className="text-body-medium">
              {t('organization.request_rejected_sub')}
            </strong>
          </>
        )}
      </Dialog>
    )
  }
)

const ApproveRejectedDisplay = observer(function ApproveRejectedDisplay({
  request,
}: {
  request: SectionRequest
}) {
  const { t } = useTranslation()
  const breakoutUser = useBreakoutUser()

  const approvedBy = request.data.updatedByUserId
    ? breakoutUser.repository.userStore.getUser(request.data.updatedByUserId)
        .fullName
    : ''

  const accepted =
    request.data.sectionRequestState === SectionRequestState.approved
  const rejected =
    request.data.sectionRequestState === SectionRequestState.rejected

  return (
    <div className="mt-3 space-y-1 rounded-lg bg-surface px-3 py-4">
      <div className="grid grid-cols-2 gap-y-1">
        <div className="text-label-large">
          {accepted ? t('organization.accepted') : t('organization.rejected')}
        </div>
        <div className="flex justify-end text-label-large">
          {accepted && <CheckIcon size={20} className="text-core-success" />}

          {rejected && <CircleX size={20} className="text-core-error" />}
        </div>

        {approvedBy && (
          <>
            <div className="text-label-medium">{t('organization.by')}</div>
            <div className="flex justify-end text-end text-body-medium">
              {approvedBy}
            </div>
          </>
        )}
        <div className="text-label-medium">{t('organization.date')}</div>
        <div className="flex justify-end text-end text-body-medium">
          {request.monthDayYear}, {request.timeAndTimezone}
        </div>
      </div>
      {request.data.sectionRequestReason && rejected && (
        <div>
          <div className="text-label-medium">
            {t('organization.reject_reason')}
          </div>
          <div className="mt-1 text-body-medium">
            {request.data.sectionRequestReason}
          </div>
        </div>
      )}
    </div>
  )
})

const RejectReasonDialog = observer(function RejectReasonDialog({
  onConfirm,
}: {
  onConfirm: (reason: string) => void
}) {
  const { t } = useTranslation()
  const [reason, setReason] = useState('')

  return (
    <Dialog size="md" innerClassName="flex flex-col h-full">
      <DialogCloseButton />
      <div className="text-headline-large">
        {t('organization.reject_request')}
      </div>
      <div className="text-body-medium">
        {t('organization.reject_request_sub')}
      </div>
      <div className="mt-3">
        <BreakoutTextArea
          kind="secondary"
          label={t('organization.reject_reason')}
          onChange={(e) => setReason(e.target.value)}
        />
      </div>
      <div className="flex-1" />
      <div className="mt-5 flex justify-end gap-3">
        <BreakoutButton
          onClick={() => {
            onConfirm(reason)
          }}
          className="w-full"
        >
          {t('organization.confirm')}
        </BreakoutButton>
      </div>
    </Dialog>
  )
})

const ApproveDenyButtons = observer(function ApproveDenyButtons({
  onApprove,
  onReject,
}: {
  onApprove: () => void
  onReject: () => void
}) {
  const { t } = useTranslation()

  return (
    <div className="mt-5 flex flex-wrap justify-between gap-3">
      <BreakoutButton
        className="!w-[140px] !min-w-0"
        size="medium"
        kind="error"
        onClick={onReject}
      >
        {t('organization.reject')}
      </BreakoutButton>
      <BreakoutButton
        className="!w-[140px] !min-w-0"
        size="medium"
        kind="success"
        onClick={onApprove}
      >
        {t('organization.approve')}
      </BreakoutButton>
    </div>
  )
})
