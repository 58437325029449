import classNames from 'classnames'
import { theme } from 'config/designSystem'
import { useSettings } from 'hooks/settings'
import type { ChangeEvent, ComponentProps } from 'react'
import React, { forwardRef, useEffect, useId, useRef } from 'react'
import { RippleEffect } from './RippleEffect'

interface SwitchProps extends ComponentProps<'input'> {
  label?: string | React.ReactNode
  labelPosition?: 'left' | 'right'
  ripple?: boolean
  className?: string
  disabled?: boolean
  initialValue?: boolean
  testId?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  containerProps?: React.HTMLAttributes<HTMLDivElement>
  labelProps?: React.HTMLAttributes<HTMLLabelElement>
  circleProps?: React.HTMLAttributes<HTMLLabelElement>
  inputRef?: React.Ref<HTMLInputElement>
}

export const BreakoutSwitch = forwardRef<HTMLInputElement, SwitchProps>(
  function BreakoutSwitch(
    {
      label,
      labelPosition = 'right',
      ripple,
      disabled,
      initialValue,
      onChange,
      testId,
      className,
      containerProps,
      circleProps,
      labelProps,
      ...rest
    },
    ref
  ) {
    const { animationsEnabled } = useSettings()
    // 1. init
    const switchId = useId()
    const internalInputRef = useRef<HTMLInputElement | null>(null)

    // 2. set default props
    ripple = !animationsEnabled ? false : (ripple ?? true)
    disabled = disabled ?? false

    // 3. set ripple effect instance
    const rippleEffect = ripple !== false && new RippleEffect()

    // 4. resolve classes
    const themeClasses = theme.switch
    const rootClasses = classNames(
      themeClasses.root.base,
      className,
      disabled ? themeClasses.root.disabled : themeClasses.root.enabled
    )
    const containerClasses = classNames(
      themeClasses.container.base,
      disabled
        ? themeClasses.container.disabled
        : themeClasses.container.enabled
    )
    const inputClasses = classNames(
      themeClasses.input.base,
      disabled ? themeClasses.input.disabled : themeClasses.input.enabled
    )
    const circleClasses = classNames(
      themeClasses.circle.base,
      disabled ? themeClasses.circle.disabled : themeClasses.circle.enabled
    )
    const rippleClasses = classNames(
      themeClasses.ripple.base,
      disabled ? themeClasses.circle.disabled : themeClasses.circle.enabled
    )
    const labelClasses = classNames(
      themeClasses.label.base,
      disabled ? themeClasses.label.disabled : themeClasses.label.enabled
    )

    useEffect(() => {
      if (internalInputRef.current) {
        if (initialValue !== undefined) {
          internalInputRef.current.checked = initialValue
        }
      }
    }, [internalInputRef, initialValue])

    // 4. return
    return (
      <div ref={ref} className={classNames(rootClasses, className)}>
        {label && labelPosition === 'left' && (
          <label
            {...labelProps}
            htmlFor={rest.id || switchId}
            className={labelClasses}
          >
            {label}
          </label>
        )}
        <div {...containerProps} className={containerClasses}>
          <input
            {...rest}
            ref={(ref) => {
              internalInputRef.current = ref
            }}
            type="checkbox"
            disabled={disabled}
            id={rest.id || switchId}
            className={classNames(inputClasses)}
            onChange={onChange}
          />
          <label
            data-testid={testId ? testId : undefined}
            {...circleProps}
            htmlFor={rest.id || switchId}
            className={circleClasses}
          >
            {!disabled && rippleEffect && (
              <div
                className={rippleClasses}
                onMouseDown={(e) => {
                  // setValue(!value)
                  // if (internalInputRef.current) {
                  //   internalInputRef.current.checked = !value
                  // }
                  // onChange && onChange(e)
                  // const onMouseDown = containerProps?.onMouseDown
                  if (ripple) {
                    rippleEffect.createFromEvent(e, 'dark')
                  }
                  // return typeof onMouseDown === 'function' && onMouseDown(e)
                }}
              />
            )}
          </label>
        </div>
        {label && labelPosition === 'right' && (
          <label
            {...labelProps}
            htmlFor={rest.id || switchId}
            className={labelClasses}
          >
            {label}
          </label>
        )}
      </div>
    )
  }
)
