import { useCallback, useEffect, useRef } from 'react'

// // utils
import { useDialogs } from 'hooks/dialogs'
import classNames from 'classnames'
import { AnimatePresence, m } from 'framer-motion'

const backdropClasses = 'fixed inset-0 flex items-center justify-center'

type size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
type DialogSizes = size | 'custom'

const theme = {
  dialog: {
    xs: {
      className:
        'relative shadow-2xl text-core-primary overflow-y-auto bg-surface-bright max-h-[90%] max-w-[90%]  rounded-3xl ',
      innerClassName: 'p-13 min-h-[350px] md:w-[400px]',
    },
    sm: {
      className:
        'relative shadow-2xl text-core-primary overflow-y-auto bg-surface-bright max-h-[90%] max-w-[90%] rounded-3xl ',
      innerClassName: 'p-13 min-h-[350px] md:w-[400px]',
    },
    md: {
      className:
        'relative shadow-2xl text-core-primary overflow-y-auto bg-surface-bright max-h-[90vh] max-w-[90%] rounded-3xl',
      innerClassName: 'p-13 min-h-[550px] rounded-2xl md:w-[400px] h-full',
    },
    lg: {
      className:
        'relative shadow-2xl text-core-primary  overflow-y-auto bg-surface-bright max-h-[90%] max-w-[90%] md:max-w-[800px] rounded-3xl relative',
      innerClassName: 'p-13 min-h-[550px] rounded-2xl',
    },
    xl: {
      className:
        'relative shadow-2xl text-core-primary overflow-y-auto bg-surface-bright max-h-[90%] max-w-[90%] lg:min-w-[1000px] lg:max-w-[1200px] rounded-3xl relative',
      innerClassName: 'p-13 min-h-[550px] rounded-2xl',
    },
    xxl: {
      className:
        'relative shadow-2xl text-core-primary overflow-y-auto flex flex-col w-screen min-w-[100vw] max-w-[100vw] h-screen min-h-[100vh] max-h-[100vh] m-0 rounded-none bg-surface p-3',
      innerClassName: '',
    },
  },
}

function DialogDismissHandler({
  children,
  dismissOnBackdropClick,
  dismissOnEscapeKey,
  forceBackdrop,
  onDismiss,
  zIndex,
}: {
  children: React.ReactNode
  dismissOnBackdropClick: boolean
  dismissOnEscapeKey: boolean
  forceBackdrop?: boolean
  onDismiss: () => void
  zIndex?: number
}) {
  const shouldDismissOnBackdropClick = dismissOnBackdropClick !== false
  const shouldDismissOnEscapeKey = dismissOnEscapeKey !== false

  const handleBackdropClick = useCallback(() => {
    onDismiss()
  }, [onDismiss])

  useEffect(() => {
    if (shouldDismissOnEscapeKey) {
      const closeOnEscapeKey = (e: { key: string }) =>
        e.key === 'Escape' ? onDismiss() : null
      document.body.addEventListener('keydown', closeOnEscapeKey)
      return () => {
        document.body.removeEventListener('keydown', closeOnEscapeKey)
      }
    }
  }, [onDismiss, shouldDismissOnEscapeKey])

  return (
    <div
      ref={(node) => node?.focus()}
      className={classNames(backdropClasses, {
        'bg-transparent': !forceBackdrop,
        'bg-dialog-background': forceBackdrop,
        'z-[9999]': zIndex === undefined,
      })}
      style={zIndex ? { zIndex: `${zIndex} !important` } : undefined}
      onMouseDown={
        shouldDismissOnBackdropClick ? handleBackdropClick : undefined
      }
    >
      {children}
    </div>
  )
}

export function Dialog({
  size = 'md',
  children,
  className,
  innerClassName,
  ignoreBackdropClick,
  ignoreEscapeKey,
  testId,
  forceBackdrop,
  zIndex,
  onDismiss,
  noWrap = false,
  'aria-label': ariaLabel,
}: {
  size?: DialogSizes
  className?: string
  innerClassName?: string
  children: React.ReactNode
  ignoreBackdropClick?: boolean
  ignoreEscapeKey?: boolean
  testId?: string
  forceBackdrop?: boolean
  zIndex?: number
  onDismiss?: () => void
  noWrap?: boolean
  'aria-label'?: string
}) {
  const sizeClasses = size === 'custom' ? '' : theme.dialog[size].className
  const innerClasses =
    size === 'custom' ? 'h-full' : theme.dialog[size].innerClassName

  const dialogClasses = classNames(sizeClasses, className)
  const innerDialogClasses = classNames(innerClasses, innerClassName)

  const { popDialog } = useDialogs()

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const div = ref.current
    if (!div) return

    setTimeout(() => {
      const tabbables: HTMLElement[] = []

      // find all tabbable elements in the dialog context
      div
        .querySelectorAll(
          '[tabindex]:not([tabindex="-1"]), button:not([disabled]), [href], input, select, textarea'
        )
        .forEach((el) => {
          tabbables.push(el as HTMLElement)
        })

      const first = tabbables[0]
      first?.focus()
    }, 16) // 1 frame
  }, [])

  const child = noWrap ? (
    children
  ) : (
    <div
      data-testid={testId}
      className={dialogClasses}
      onMouseDown={(e) => e.stopPropagation()}
      aria-modal="true"
      role="dialog"
      tabIndex={0}
      ref={ref}
      aria-label={ariaLabel}
    >
      <div className={innerDialogClasses}>{children}</div>
    </div>
  )

  return (
    <DialogDismissHandler
      dismissOnBackdropClick={ignoreBackdropClick !== true}
      dismissOnEscapeKey={ignoreEscapeKey !== true}
      forceBackdrop={forceBackdrop}
      onDismiss={onDismiss ?? popDialog}
      zIndex={zIndex}
    >
      {child}
    </DialogDismissHandler>
  )
}

// A special version of a Dialog that can be used within another dialog
// It carries its own backdrop and can be used to show a dialog within a dialog
// It stack backdrops and dialogs on top of each other
export function InlineDialog({
  open = true,
  size = 'md',
  children,
  className,
  ignoreBackdropClick,
  ignoreEscapeKey,
  innerClassName,
  testId,
  onDismiss,
  zIndex,
  noWrap = false,
}: {
  open?: boolean
  size?: DialogSizes
  className?: string
  children: React.ReactNode
  ignoreBackdropClick?: boolean
  ignoreEscapeKey?: boolean
  innerClassName?: string
  testId?: string
  onDismiss?: () => void
  zIndex?: number
  noWrap?: boolean
}) {
  const sizeClasses = size === 'custom' ? '' : theme.dialog[size].className
  const innerClasses =
    size === 'custom' ? 'h-full' : theme.dialog[size].innerClassName

  const dialogClasses = classNames(sizeClasses, className)
  const innerDialogClasses = classNames(innerClasses, innerClassName)

  const child = noWrap ? (
    children
  ) : (
    <div
      data-testid={testId}
      className={dialogClasses}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <div className={innerDialogClasses}>{children}</div>
    </div>
  )

  return (
    <AnimatePresence mode={'wait'} initial={false}>
      <m.div
        key={open ? 'open' : 'closed'}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.1 }}
        exit={{ opacity: 0.2 }}
      >
        {open && (
          <DialogDismissHandler
            dismissOnBackdropClick={ignoreBackdropClick !== true}
            dismissOnEscapeKey={ignoreEscapeKey !== true}
            onDismiss={onDismiss || (() => {})}
            forceBackdrop
            zIndex={zIndex}
          >
            {child}
          </DialogDismissHandler>
        )}
      </m.div>
    </AnimatePresence>
  )
}
