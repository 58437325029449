import type { ObservableMap } from 'mobx'
import { action, computed, makeObservable, observable } from 'mobx'

import { getSlides, touchSlide } from '../firestore/Slide'
import {
  deepCopySlideDeck,
  getSlideDeck,
  saveSlideDeckForm,
  addCatalogToSlideDeck,
  updateSlideDeckFeatured,
  removeCatalogFromSlideDeck,
  uploadSlideDeckImage,
  deleteSlideDeckImage,
  deleteSlideDeck,
  removeCatalogFromSlideDeckAndUnFeature,
  hideSlideDeck,
  showSlideDeck,
  touchSlideDeck,
  forkSlideDeck,
} from '../firestore/SlideDeck'
import { getSlideDecksWithTypeId } from '../firestore/SlideDeck'
import type { ExhibitFieldsForUpload } from '../firestore/SlideDeckExhibit'
import {
  deleteSlideDeckExhibit,
  deleteSlideDeckExhibitImage,
  getSlideDeckExhibits,
  saveSlideDeckExhibit,
  uploadSlideDeckExhibitImage,
} from '../firestore/SlideDeckExhibit'
import {
  deleteSlideDeckMaterial,
  deleteSlideDeckMaterialFile,
  getSlideDeckMaterials,
  saveSlideDeckMaterial,
  sortSlideDeckMaterials,
  uploadSlideDeckMaterialFile,
} from '../firestore/SlideDeckMaterial'
import {
  deleteSlide,
  deleteSlideImage,
  deleteSlideVideo,
  saveSlide,
  sortSlides,
  uploadSlideImage,
  uploadSlideVideo,
} from '../firestore/Slide'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { SlideDeck, SlideDeckState } from '../models/SlideDeck'
import { SlideDeckExhibit } from '../models/SlideDeckExhibit'
import { SlideDeckMaterial } from '../models/SlideDeckMaterial'
import { SlideModel } from '../models/SlideModel'
import { type StaticModelCollection } from '../types'
import { SlideDeckMaterialType } from '../types'
import { Cubit } from './core'
import { SlideDeckAuthor } from '../models/SlideDeckAuthor'
import type { AuthorFieldsForUpload } from '../firestore/SlideDeckAuthor'
import {
  deleteSlideDeckAuthor,
  deleteSlideDeckAuthorImage,
  getSlideDeckAuthors,
  saveSlideDeckAuthor,
  sortSlideDeckAuthors,
  uploadSlideDeckAuthorImage,
} from '../firestore/SlideDeckAuthor'
import type { QuestionFieldsForUpload } from '../firestore/SlideQuestion'
import {
  deleteSlideDeckQuestion,
  getSlideQuestions,
  saveSlideDeckQuestion,
} from '../firestore/SlideQuestion'
import { SlideQuestion } from '../models/SlideQuestion'
import { SlideRubric } from '../models/SlideRubric'
import {
  assignSlideRubricToSlide,
  deleteSlideRubric,
  getSlideRubrics,
  saveSlideRubric,
} from '../firestore/SlideRubric'
import { getSettingsSlideDeckFields } from '../firestore/SettingsSlideDeckFields'
import { SettingsSlideDeckFields } from '../models/SettingsSlideDeckFields'
import { Catalog } from '../models/Catalog'
import { getCatalogs } from '../firestore/Catalog'
import { SlideDeckReference } from '../models/SlideDeckReference'
import {
  deleteSlideDeckReference,
  getSlideDeckReferences,
  saveSlideDeckReference,
  sortSlideDeckReferences,
} from '../firestore/SlideDeckReference'
import {
  deleteTeachingPlanModuleSlideDeck,
  getSlideDeckModulesForSlideDeckType,
  saveTeachingPlanModuleSlideDeck,
} from '../firestore/TeachingPlanModuleSlideDeck'
import { TeachingPlanModuleSlideDeck } from '../models/TeachingPlanModuleSlideDeck'
import { TeachingPlanModule } from '../models/TeachingPlanModule'
import { TeachingPlan } from '../models/TeachingPlan'
import { getTeachingPlan } from '../firestore/TeachingPlan'
import { getTeachingPlanModule } from '../firestore/TeachingPlanModule'
import { runTransaction } from 'firebase/firestore'
import { SettingsSlideDeckTag } from '../models/SettingsSlideDeckTag'
import { getSettingsSlideDeckTags } from '../firestore/SettingsSlideDeckTag'
import { validateSlideDeck } from '../util/slideDeckValidation'
import type { Media } from '../models/Media'
import { getMedia } from '../firestore/Media'

export enum AdminSlideDeckTab {
  experienceDetails = 'experience_details',
  slides = 'slides',
  interactiveElements = 'interactive_elements',
  rubrics = 'rubrics',
  exhibits = 'exhibits',
  courseMaterials = 'course_materials',
  authors = 'authors',
  references = 'references',
}

export class AdminSlideDeckCubit extends Cubit {
  repository: FirebaseRepository

  slideDeck: SlideDeck
  slideDeckId: string

  materials: StaticModelCollection<SlideDeckMaterial>
  exhibits: StaticModelCollection<SlideDeckExhibit>
  settingsSlideDeckFields: SettingsSlideDeckFields
  settingsSlideDeckTags: StaticModelCollection<SettingsSlideDeckTag>
  authors: StaticModelCollection<SlideDeckAuthor>
  private _catalogs: StaticModelCollection<Catalog>
  private _slides: StaticModelCollection<SlideModel>
  private _questions: StaticModelCollection<SlideQuestion>
  private _references: StaticModelCollection<SlideDeckReference>
  private _rubrics: StaticModelCollection<SlideRubric>
  private _moduleSlideDecks: StaticModelCollection<TeachingPlanModuleSlideDeck>

  @observable //key = `teachingPlan-$catalogId-$teachingPlanId`
  private _teachingPlanDataByIds: Record<string, TeachingPlan> = {}

  @observable //key = `module-$catalogId-$teachingPlanId-$moduleId`
  private _teachingPlanModulesByIds: Record<string, TeachingPlanModule> = {}

  slideDecksOfType: StaticModelCollection<SlideDeck>

  @observable
  tab: AdminSlideDeckTab = AdminSlideDeckTab.experienceDetails

  mediaObjects: ObservableMap<string, Media>

  constructor(repository: FirebaseRepository, slideDeckId: string) {
    super()
    makeObservable(this)

    this.slideDeckId = slideDeckId

    this.repository = repository
    this.slideDeck = SlideDeck.empty(repository)

    this.materials = SlideDeckMaterial.emptyCollection(repository)
    this.exhibits = SlideDeckExhibit.emptyCollection(repository)
    this.settingsSlideDeckFields = SettingsSlideDeckFields.empty(repository)
    this.authors = SlideDeckAuthor.emptyCollection(repository)
    this._catalogs = Catalog.emptyCollection(repository)
    this._slides = SlideModel.emptyCollection(repository)
    this._questions = SlideQuestion.emptyCollection(repository)
    this._references = SlideDeckReference.emptyCollection(repository)
    this._rubrics = SlideRubric.emptyCollection(repository)
    this.slideDecksOfType = SlideDeck.emptyCollection(repository)
    this._moduleSlideDecks =
      TeachingPlanModuleSlideDeck.emptyCollection(repository)
    this.settingsSlideDeckTags =
      SettingsSlideDeckTag.emptyCollection(repository)
    this.mediaObjects = observable.map<string, Media>({}, { deep: false })
  }

  initialize(): void {
    this.addStream(
      getSlideDeck(this.repository, {
        slideDeckId: this.slideDeckId,
      }),
      (slideDeck) => {
        const lastType = this.slideDeck.data.slideDeckTypeId
        const updatedType = slideDeck.data.slideDeckTypeId

        // if the type has changed, we need to update the slideDecksOfType collection
        if (lastType !== updatedType) {
          // replace slide decks of type stream
          const keyFromTypeId = (typeId: string) => `slideDecksOfType-${typeId}`
          this.removeStream(keyFromTypeId(lastType))
          this.addStream(
            getSlideDecksWithTypeId(this.repository, {
              slideDeckTypeId: updatedType,
            }),
            (slideDecks) => {
              this.slideDecksOfType.replaceModels(slideDecks)
            },
            { name: keyFromTypeId(updatedType) }
          )

          // replace teachingPlanModulesForSlideDeckType stream
          const keyFromTypeIdForModules = (typeId: string) =>
            `teachingPlanModulesForSlideDeckType-${typeId}`
          this.removeStream(keyFromTypeIdForModules(lastType))
          this.addStream(
            getSlideDeckModulesForSlideDeckType(this.repository, {
              slideDeckTypeId: updatedType,
            }),
            (slideDeckModules) => {
              const teachingPlansToFetch: {
                catalogId: string
                teachingPlanId: string
              }[] = []
              const modulesToFetch: {
                catalogId: string
                teachingPlanId: string
                moduleId: string
              }[] = []
              slideDeckModules.forEach((module) => {
                teachingPlansToFetch.push({
                  catalogId: module.data.catalogId,
                  teachingPlanId: module.data.teachingPlanId,
                })
                modulesToFetch.push({
                  catalogId: module.data.catalogId,
                  teachingPlanId: module.data.teachingPlanId,
                  moduleId: module.data.moduleId,
                })
              })
              this.initTeachingPlanModuleStreams(modulesToFetch)
              this.initTeachingPlanStreams(teachingPlansToFetch)
              this._moduleSlideDecks.replaceModels(slideDeckModules)
            },
            { name: keyFromTypeIdForModules(updatedType) }
          )
        }
        this.slideDeck.replaceModel(slideDeck)
      }
    )

    // load materials on init since we need them for the details tab
    // not just the course materials tab
    this.addStream(
      getSlideDeckMaterials(this.repository, { slideDeckId: this.slideDeckId }),
      (materials) => {
        this.materials.replaceModels(materials)
      }
    )

    // load authors on init since we need them for the details tab
    // when we run validation.
    this.addStream(
      getSlideDeckAuthors(this.repository, { slideDeckId: this.slideDeckId }),
      (authors) => {
        this.authors.replaceModels(authors)
      }
    )

    this.addStream(
      getSlideDeckExhibits(this.repository, { slideDeckId: this.slideDeckId }),
      (exhibits) => {
        this.exhibits.replaceModels(exhibits)
      }
    )

    this.addStream(
      getSlides(this.repository, { slideDeckId: this.slideDeckId }),
      (slides) => {
        this.slides.replaceModels(slides)
        // loop over slides and if any have a global media object load it
        slides.forEach((slide) => {
          const mediaId = slide.data.mediaId
          if (mediaId) {
            if (!this.hasStream(`media-${mediaId}`)) {
              this.addStream(
                getMedia(this.repository, { mediaId }),
                (media) => {
                  this.mediaObjects.set(mediaId, media)
                },
                { namespace: `media`, name: `media-${mediaId}` }
              )
            }
          }
        })
      }
    )
    this.addStream(
      getSettingsSlideDeckFields(this.repository),
      (deckFields) => {
        this.settingsSlideDeckFields.replaceModel(deckFields)
      },
      {
        name: 'settings-slide-deck-fields',
      }
    )
    this.addStream(getSettingsSlideDeckTags(this.repository), (tags) => {
      this.settingsSlideDeckTags.replaceModels(tags)
    })
  }

  @computed
  get teachingPlanModulesForSlideDeckByCatalogId() {
    const startData = this.slideDeckModulesForSlideDecksOfTypeByCatalogId
    const newData: Record<
      string,
      { teachingPlan: TeachingPlan; module: TeachingPlanModule }[]
    > = {}
    for (const key in startData) {
      const moduleDataForCatalog = startData[key]
      newData[key] = moduleDataForCatalog
        .filter((moduleData) => moduleData.slideDeck.id === this.slideDeckId)
        .map((moduleData) => ({
          teachingPlan: moduleData.teachingPlan,
          module: moduleData.teachingPlanModule,
        }))
    }
    return newData
  }

  @computed
  get moduleSlideDecksOfTypeDataLoading() {
    return (
      this._catalogs.isLoading ||
      this._moduleSlideDecks.isLoading ||
      this.slideDecksOfType.isLoading ||
      Object.values(this._teachingPlanDataByIds).some(
        (plan) => plan.isLoading
      ) ||
      Object.values(this._teachingPlanModulesByIds).some(
        (module) => module.isLoading
      )
    )
  }

  @computed
  get slideDeckModulesForSlideDecksOfTypeByCatalogId() {
    const moduleBundleByCatalogId: Record<
      string,
      {
        teachingPlanModuleSlideDeck: TeachingPlanModuleSlideDeck
        teachingPlanModule: TeachingPlanModule
        teachingPlan: TeachingPlan
        catalog: Catalog
        slideDeck: SlideDeck
      }[]
    > = {}
    this._moduleSlideDecks.models.forEach((moduleSlideDeck) => {
      const { catalogId, teachingPlanId, moduleId } = moduleSlideDeck.data
      const moduleKey = this.getTeachingPlanModuleKey({
        catalogId,
        teachingPlanId,
        moduleId,
      })
      const teachingPlanKey = this.getTeachingPlanKey({
        catalogId,
        teachingPlanId,
      })
      if (!moduleBundleByCatalogId[catalogId])
        moduleBundleByCatalogId[moduleSlideDeck.data.catalogId] = []
      moduleBundleByCatalogId[catalogId].push({
        teachingPlanModuleSlideDeck: moduleSlideDeck,
        teachingPlanModule:
          moduleKey in this._teachingPlanModulesByIds
            ? this._teachingPlanModulesByIds[moduleKey]
            : TeachingPlanModule.empty(this.repository),
        teachingPlan:
          teachingPlanKey in this._teachingPlanDataByIds
            ? this._teachingPlanDataByIds[teachingPlanKey]
            : TeachingPlan.empty(this.repository),
        catalog:
          this._catalogs.models.find((catalog) => catalog.id === catalogId) ||
          Catalog.empty(this.repository),
        slideDeck:
          this.slideDecksOfType.models.find(
            (slideDeck) => slideDeck.id === moduleSlideDeck.id
          ) || SlideDeck.empty(this.repository),
      })
    })
    return moduleBundleByCatalogId
  }

  /**
   * does not include the current slide deck
   */
  @computed
  get existingSlideDeckVersions() {
    const existingVersions: Set<string> = new Set()
    for (const slideDeck of this.slideDecksOfType.models) {
      // ignore self
      if (slideDeck.id === this.slideDeckId) continue
      existingVersions.add(slideDeck.data.slideDeckVersion)
    }
    return existingVersions
  }

  /**
   * does not include the current slide deck
   */
  @computed
  get slideDecksOfTypeByCatalogId() {
    const slideDecksByCatalog: Record<string, SlideDeck[]> = {}
    for (const slideDeck of this.slideDecksOfType.models) {
      // ignore self
      if (slideDeck.id === this.slideDeckId) continue
      for (const catalogId of slideDeck.data.catalogIds) {
        if (!slideDecksByCatalog[catalogId]) {
          slideDecksByCatalog[catalogId] = []
        }
        slideDecksByCatalog[catalogId].push(slideDeck)
      }
    }
    return slideDecksByCatalog
  }

  @computed
  get catalogs() {
    const name = 'catalogs'
    if (!this.hasStream(name)) {
      this.addStream(
        getCatalogs(this.repository),
        (catalogs) => {
          this._catalogs.replaceModels(catalogs)
        },
        { name }
      )
    }
    return this._catalogs
  }

  @computed
  get questions(): StaticModelCollection<SlideQuestion> {
    if (this.hasStream('questions')) {
      return this._questions
    }

    this.addStream(
      getSlideQuestions(this.repository, { slideDeckId: this.slideDeckId }),
      (questions) => {
        this._questions.replaceModels(questions)
      },
      {
        name: 'questions',
      }
    )

    return this._questions
  }

  @computed
  get sortedQuestions(): SlideQuestion[] {
    const slideIds = this.slides.models.map((e) => e.id)

    // Ensure the pre-meeting quiz is always first.
    slideIds.unshift('preMeetingQuizKey')

    return this.questions.models.sort((a, b) => {
      const aIndex = slideIds.indexOf((a.data.slideId || a.data.groupSlideId)!)
      const bIndex = slideIds.indexOf((b.data.slideId || b.data.groupSlideId)!)

      if (aIndex === bIndex) {
        // Compare the question strings of a and b.
        return a.data.question.localeCompare(b.data.question)
      }

      return aIndex - bIndex
    })
  }

  @computed
  get slides(): StaticModelCollection<SlideModel> {
    const slidesKey = 'admin-slides'
    if (this.hasStream(slidesKey)) {
      return this._slides
    }

    this.addStream(
      getSlides(this.repository, { slideDeckId: this.slideDeckId }),
      (slides) => {
        this._slides.replaceModels(slides)
      },
      {
        name: slidesKey,
      }
    )

    return this._slides
  }

  @computed
  get references() {
    if (this.hasStream('references')) {
      return this._references
    }
    this.addStream(
      getSlideDeckReferences(this.repository, {
        slideDeckId: this.slideDeckId,
      }),
      (references) => {
        this._references.replaceModels(references)
      },
      {
        name: 'references',
      }
    )

    return this._references
  }

  @computed
  get rubrics() {
    const rubricsKey = 'rubrics'
    if (!this.hasStream(rubricsKey)) {
      this.addStream(
        getSlideRubrics(this.repository, {
          slideDeckId: this.slideDeckId,
        }),
        (rubrics) => {
          this._rubrics.replaceModels(rubrics)
        },
        {
          name: rubricsKey,
        }
      )
    }
    // sort rubrics by slideId
    return this._rubrics
  }

  @computed
  get rubricsSortedBySlideId() {
    // empty string is 'All Slides' option, must be first
    const slideIds = ['', ...this.slides.models.map((slide) => slide.id)]
    return this.rubrics.models.sort((a, b) => {
      const aIndex = slideIds.indexOf(a.data.slideId || '')
      const bIndex = slideIds.indexOf(b.data.slideId || '')
      if (aIndex === bIndex) {
        return a.data.rubric.localeCompare(b.data.rubric)
      }
      return aIndex - bIndex
    })
  }

  @computed
  get meetsFeaturedRequirements() {
    const hasFeaturedImageMaterial = this.materials.models.some(
      (material) =>
        material.data.materialType === SlideDeckMaterialType.featuredLarge &&
        !!material.data.materialLink
    )

    const slideDeckInHealthyState =
      !!this.slideDeck.data.slideDeckName &&
      !!this.slideDeck.data.slideDeckImageURL &&
      ![SlideDeckState.deleted, SlideDeckState.hidden].includes(
        this.slideDeck.slideDeckState
      )
    return (
      this.slideDeck.data.slideDeckFeatured ||
      (hasFeaturedImageMaterial && slideDeckInHealthyState)
    )
  }

  @computed
  get featuredImageMaterial() {
    return this.materials.models.find(
      (material) =>
        material.data.materialType === SlideDeckMaterialType.featuredLarge &&
        !!material.data.materialLink
    )
  }

  @action
  changeTab(tab: AdminSlideDeckTab): void {
    this.tab = tab
  }

  @action
  checkForReasonsNotToPublish = async () => {
    const context = {
      slideDeck: this.slideDeck.data,
      slides: this.slides.models.map((slide) => slide.data),
      materials: this.materials.models.map((material) => material.data),
      authors: this.authors.models.map((author) => author.data),
      exhibits: this.exhibits.models.map((exhibit) => exhibit.data),
      featuredImageMaterial: this.featuredImageMaterial?.data,
      repository: this.repository,
      slideDeckId: this.slideDeckId,
      firstSlideId: this.slides.models[0]?.id,
      mediaObjects: Object.fromEntries(this.mediaObjects.entries()),
    }

    return validateSlideDeck(context, this.repository)
  }

  @computed
  get featuredSlideDecksOfType() {
    return this.slideDecksOfType.models.filter(
      (slideDeck) =>
        slideDeck.data.slideDeckFeatured && slideDeck.id !== this.slideDeckId
    )
  }

  touchSlide(slideId: string) {
    return touchSlide(this.repository, {
      slideDeckId: this.slideDeckId,
      slideId,
    })
  }

  touchSlideDeck() {
    return touchSlideDeck(this.repository, { slideDeckId: this.slideDeckId })
  }

  withTouchSlideDeck = async <T>(fn: () => Promise<T>): Promise<T> => {
    const result = await fn()
    await this.touchSlideDeck()
    return result
  }

  /****************************************************************
   *  Materials
   ***************************************************************/

  reorderSlideDeckMaterials = async (
    currentOrder: string[],
    { oldIndex, newIndex }: { oldIndex: number; newIndex: number }
  ): Promise<void> => {
    await sortSlideDeckMaterials(this.repository, {
      currentOrder,
      oldIndex,
      newIndex,
      slideDeckId: this.slideDeckId,
    })
  }

  deleteSlideDeckMaterial = async (
    materialId: string,
    materialType: SlideDeckMaterialType
  ): Promise<void> => {
    await deleteSlideDeckMaterial(this.repository, {
      slideDeckId: this.slideDeckId,
      materialId,
      materialType,
    })
  }

  /** field name is the name of the material field which holds the URL for the uploaded resource
   * should be **materialLink** in all cases except podcast images
   */
  deleteSlideDeckMaterialFile = async ({
    fieldName,
    materialId,
    materialType,
  }: {
    fieldName: 'materialLink' | 'imageUrl'
    materialId: string
    materialType: SlideDeckMaterialType
  }) => {
    await deleteSlideDeckMaterialFile(this.repository, {
      slideDeckId: this.slideDeckId,
      fieldName,
      materialId,
      materialType,
    })
  }

  /** if materialId is not present, creates a new material document and returns the ID */
  saveSlideDeckMaterial = async ({
    materialFields,
    materialId,
  }: Omit<Parameters<typeof saveSlideDeckMaterial>[1], 'slideDeckId'>) => {
    return await saveSlideDeckMaterial(this.repository, {
      slideDeckId: this.slideDeckId,
      materialId,
      materialFields,
    })
  }

  /**
   * returns the url of the uploaded material
   */
  uploadSlideDeckMaterialFile = async ({
    materialId,
    materialType,
    file,
  }: Omit<
    Parameters<typeof uploadSlideDeckMaterialFile>[1],
    'slideDeckId'
  >) => {
    return await uploadSlideDeckMaterialFile(this.repository, {
      slideDeckId: this.slideDeckId,
      materialId,
      materialType,
      file,
    })
  }

  /****************************************************************
   *  Slide
   ***************************************************************/
  saveSlide = async (
    params: Omit<Parameters<typeof saveSlide>[1], 'slideDeckId'>
  ) => {
    return this.withTouchSlideDeck(() => {
      return saveSlide(this.repository, {
        ...params,
        slideDeckId: this.slideDeckId,
      })
    })
  }

  deleteSlide = async (
    params: Omit<Parameters<typeof deleteSlide>[1], 'slideDeckId'>
  ) => {
    return await deleteSlide(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  uploadSlideVideo = async (
    params: Omit<Parameters<typeof uploadSlideVideo>[1], 'slideDeckId'>
  ) => {
    return await uploadSlideVideo(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  uploadSlideImage = async (
    params: Omit<Parameters<typeof uploadSlideImage>[1], 'slideDeckId'>
  ) => {
    return await uploadSlideImage(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  deleteSlideVideo = async (
    params: Omit<Parameters<typeof deleteSlideVideo>[1], 'slideDeckId'> & {
      fileExtension: string
    }
  ) => {
    return await deleteSlideVideo(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  deleteSlideImage = async (
    params: Omit<Parameters<typeof deleteSlideImage>[1], 'slideDeckId'> & {
      fileExtension: string
    }
  ) => {
    return await deleteSlideImage(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  sortSlides = async (
    params: Omit<Parameters<typeof sortSlides>[1], 'slideDeckId'>
  ) => {
    return await sortSlides(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  /****************************************************************
   *  Author
   ***************************************************************/
  uploadSlideDeckAuthorImage = async ({
    authorId,
    file,
  }: {
    authorId: string
    file: File
  }) => {
    return await uploadSlideDeckAuthorImage(this.repository, {
      slideDeckId: this.slideDeckId,
      authorId,
      file,
    })
  }

  /**
   * Returns an authorId.
   */
  saveSlideDeckAuthor = async ({
    authorFields,
    authorId,
  }: {
    authorId?: string
    authorFields: AuthorFieldsForUpload
  }) => {
    return await saveSlideDeckAuthor(this.repository, {
      slideDeckId: this.slideDeckId,
      authorFields,
      authorId,
    })
  }

  deleteSlideDeckAuthor = async (authorId: string) => {
    await deleteSlideDeckAuthor(this.repository, {
      slideDeckId: this.slideDeckId,
      authorId,
    })
  }

  deleteSlideDeckAuthorImage = async (authorId: string) => {
    await deleteSlideDeckAuthorImage(this.repository, {
      slideDeckId: this.slideDeckId,
      authorId,
    })
  }

  reorderSlideDeckAuthors = async (
    currentOrder: string[],
    { oldIndex, newIndex }: { oldIndex: number; newIndex: number }
  ): Promise<void> => {
    await sortSlideDeckAuthors(this.repository, {
      currentOrder,
      oldIndex,
      newIndex,
      slideDeckId: this.slideDeckId,
    })
  }

  uploadSlideDeckExhibitImage = async ({
    exhibitId,
    file,
  }: {
    exhibitId: string
    file: File
  }) => {
    return await uploadSlideDeckExhibitImage(this.repository, {
      slideDeckId: this.slideDeckId,
      exhibitId,
      file,
    })
  }

  /****************************************************************
   *  Exhibits
   ***************************************************************/

  saveSlideDeckExhibit = async ({
    exhibitFields,
    exhibitId,
  }: {
    exhibitId?: string
    exhibitFields: ExhibitFieldsForUpload
  }) => {
    return await saveSlideDeckExhibit(this.repository, {
      slideDeckId: this.slideDeckId,
      exhibitFields,
      exhibitId,
    })
  }

  deleteSlideDeckExhibit = async (exhibitId: string) => {
    await deleteSlideDeckExhibit(this.repository, {
      slideDeckId: this.slideDeckId,
      exhibitId,
    })
  }

  deleteSlideDeckExhibitImage = async (exhibitId: string) => {
    await deleteSlideDeckExhibitImage(this.repository, {
      slideDeckId: this.slideDeckId,
      exhibitId,
    })
  }

  /****************************************************************
   *  Questions/Interactive Elements
   ***************************************************************/

  saveSlideDeckQuestion = async ({
    questionFields,
    questionId,
  }: {
    questionId?: string
    questionFields: QuestionFieldsForUpload
  }) => {
    return await saveSlideDeckQuestion(this.repository, {
      slideDeckId: this.slideDeckId,
      questionFields,
      questionId,
    })
  }

  deleteSlideDeckQuestion = async (questionId: string) => {
    await deleteSlideDeckQuestion(this.repository, {
      slideDeckId: this.slideDeckId,
      questionId,
    })
  }

  deleteSlideRubric = async (
    params: Omit<Parameters<typeof deleteSlideRubric>[1], 'slideDeckId'>
  ) => {
    return await deleteSlideRubric(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  /****************************************************************
   *  Rubric
   ***************************************************************/

  saveSlideRubric = async (
    params: Omit<Parameters<typeof saveSlideRubric>[1], 'slideDeckId'>
  ) => {
    return await saveSlideRubric(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  assignSlideRubricToSlide = async (
    params: Omit<Parameters<typeof assignSlideRubricToSlide>[1], 'slideDeckId'>
  ) => {
    return await assignSlideRubricToSlide(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  /****************************************************************
   *  Slide Details
   ***************************************************************/

  saveSlideDeckForm = async (
    params: Partial<Parameters<typeof saveSlideDeckForm>[2]>
  ) => {
    const payload = {
      slideDeckDescription: this.slideDeck.data.slideDeckDescription,
      slideDeckDisciplines: this.slideDeck.data.slideDeckDisciplines,
      slideDeckLearningObjectives:
        this.slideDeck.data.slideDeckLearningObjectives,
      slideDeckIndustries: this.slideDeck.data.slideDeckIndustries,
      slideDeckFeatured: this.slideDeck.data.slideDeckFeatured,
      slideDeckGoogleTemplateURL:
        this.slideDeck.data.slideDeckGoogleTemplateURL,
      slideDeckName: this.slideDeck.data.slideDeckName,
      slideDeckPrice: this.slideDeck.data.slideDeckPrice,
      slideDeckTeaser: this.slideDeck.data.slideDeckTeaser,
      slideDeckVersion: this.slideDeck.data.slideDeckVersion,
      slideDeckImageURL: this.slideDeck.data.slideDeckImageURL,
      ...params,
    }
    return await saveSlideDeckForm(this.repository, this.slideDeckId, payload)
  }

  deepCopySlideDeck = async (
    params: Omit<Parameters<typeof deepCopySlideDeck>[1], 'slideDeck'>
  ) => {
    return await deepCopySlideDeck(this.repository, {
      ...params,
      slideDeck: this.slideDeck,
    })
  }

  uploadSlideDeckImage = async (file: File) => {
    return await uploadSlideDeckImage(this.repository, {
      slideDeckId: this.slideDeckId,
      file,
    })
  }

  deleteSlideDeckImage = async () => {
    return await deleteSlideDeckImage(this.repository, this.slideDeckId)
  }

  addCatalogToSlideDeck = async (
    params: Omit<Parameters<typeof addCatalogToSlideDeck>[1], 'slideDeckId'>
  ) => {
    return await addCatalogToSlideDeck(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  removeCatalogFromSlideDeck = async (
    params: Omit<
      Parameters<typeof removeCatalogFromSlideDeck>[1],
      'slideDeckId'
    > & {
      moduleData: {
        teachingPlan: TeachingPlan
        module: TeachingPlanModule
      }[]
    }
  ) => {
    return await runTransaction(
      this.repository.firestore,
      async (transaction) => {
        const catalogReq = removeCatalogFromSlideDeck(this.repository, {
          ...params,
          slideDeckId: this.slideDeckId,
          transaction,
        })
        const removeModuleReqs = params.moduleData.map((moduleData) => {
          return deleteTeachingPlanModuleSlideDeck(this.repository, {
            slideDeckId: this.slideDeckId,
            transaction,
            teachingPlanId: moduleData.teachingPlan.id,
            moduleId: moduleData.module.id,
            catalogId: params.catalogId,
          })
        })
        await Promise.all([catalogReq, ...removeModuleReqs])
      }
    )
  }

  replaceVersionsInCatalog = async ({
    catalogId,
    existingVersions,
    slideDeckIdsToUnFeature,
    moduleSlideDeckConfirmationResult,
  }: {
    catalogId: string
    existingVersions: string[]
    slideDeckIdsToUnFeature: string[]
    moduleSlideDeckConfirmationResult: {
      result: 'replace' | 'remove' | 'notShown'
      modulesToEdit: {
        catalogId: string
        moduleId: string
        teachingPlanId: string
        moduleSlideDeckId: string
        order: number | undefined
      }[]
    }
  }) => {
    const moduleEditError = new Error(
      'if modulesToEdit are present, module slide deck confirmation result must be replace or remove'
    )
    if (
      moduleSlideDeckConfirmationResult.modulesToEdit.length &&
      moduleSlideDeckConfirmationResult.result === 'notShown'
    )
      throw moduleEditError

    // get the intersection of the existing versions and the slideDeckIdsToUnFeature
    // so we can do it in the same request (transaction order is not consistent)
    const slideDeckIdsToUnFeatureAndRemoveFromCatalog =
      slideDeckIdsToUnFeature.filter((id) => {
        return existingVersions.includes(id)
      })

    // AFAIK there's no path for this list to be populated as of now but
    // keeping it here for future proofing
    const slideDeckIdsToUnFeatureAndMaintainCatalogs =
      slideDeckIdsToUnFeature.filter((id) => {
        return !slideDeckIdsToUnFeatureAndRemoveFromCatalog.includes(id)
      })

    const slideDeckIdsToRemoveFromCatalog = existingVersions.filter((id) => {
      return !slideDeckIdsToUnFeatureAndRemoveFromCatalog.includes(id)
    })

    await runTransaction(this.repository.firestore, async (transaction) => {
      const unFeatureRequests = slideDeckIdsToUnFeatureAndMaintainCatalogs.map(
        (slideDeckId) =>
          updateSlideDeckFeatured(this.repository, {
            slideDeckId,
            slideDeckFeatured: false,
            transaction,
          })
      )
      const unFeatureAndRemoveCatalogRequests =
        slideDeckIdsToUnFeatureAndRemoveFromCatalog.map((slideDeckId) =>
          removeCatalogFromSlideDeckAndUnFeature(this.repository, {
            slideDeckId,
            catalogId,
            transaction,
          })
        )
      const removeCatalogRequests = slideDeckIdsToRemoveFromCatalog.map(
        (slideDeckId) =>
          removeCatalogFromSlideDeck(this.repository, {
            slideDeckId,
            catalogId,
            transaction,
          })
      )

      // current slide deck id comes from the cubit/page
      const addCatalogToCurrentSlideDeck = addCatalogToSlideDeck(
        this.repository,
        { slideDeckId: this.slideDeckId, catalogId, transaction }
      )

      // replace or remove module slide decks
      const removeModules = moduleSlideDeckConfirmationResult.modulesToEdit.map(
        (moduleData) => {
          if (moduleSlideDeckConfirmationResult.result === 'notShown')
            throw moduleEditError
          return deleteTeachingPlanModuleSlideDeck(this.repository, {
            slideDeckId: moduleData.moduleSlideDeckId,
            transaction: transaction,
            teachingPlanId: moduleData.teachingPlanId,
            moduleId: moduleData.moduleId,
            catalogId: moduleData.catalogId,
          })
        }
      )

      // don't add the new modules unless user confirmed replace
      const addModules =
        moduleSlideDeckConfirmationResult.result === 'replace'
          ? moduleSlideDeckConfirmationResult.modulesToEdit.map(
              (moduleData) => {
                return saveTeachingPlanModuleSlideDeck(this.repository, {
                  transaction: transaction,
                  teachingPlanId: moduleData.teachingPlanId,
                  moduleId: moduleData.moduleId,
                  catalogId: moduleData.catalogId,
                  slideDeckOrder:
                    moduleData.order === undefined ? 999 : moduleData.order,
                  slideDeckId: this.slideDeckId,
                  slideDeckTypeId: this.slideDeck.data.slideDeckTypeId,
                })
              }
            )
          : []

      await Promise.all([
        ...unFeatureRequests,
        ...unFeatureAndRemoveCatalogRequests,
        ...removeCatalogRequests,
        addCatalogToCurrentSlideDeck,
        ...removeModules,
        ...addModules,
      ])
    })
  }

  /**
   * @param slideDeckId - if not provided, will fork the current slide deck
   */
  forkSlideDeck = async (slideDeckId?: string) => {
    const slideDeckIdToFork = slideDeckId || this.slideDeckId
    await forkSlideDeck(this.repository, { slideDeckId: slideDeckIdToFork })
  }

  setSlideDeckHidden = async () => {
    await hideSlideDeck(this.repository, {
      slideDeckId: this.slideDeckId,
    })
  }

  setSlideDeckVisible = async () => {
    await showSlideDeck(this.repository, {
      slideDeckId: this.slideDeckId,
    })
  }

  updateSlideDeckFeatured = async (
    params: Parameters<typeof updateSlideDeckFeatured>[1]
  ) => {
    return await updateSlideDeckFeatured(this.repository, {
      ...params,
    })
  }

  deleteSlideDeck = async (params: Parameters<typeof deleteSlideDeck>[1]) => {
    return await deleteSlideDeck(this.repository, {
      ...params,
    })
  }

  /****************************************************************
   *  References
   ***************************************************************/
  saveReference = async (
    params: Omit<Parameters<typeof saveSlideDeckReference>[1], 'slideDeckId'>
  ) => {
    return await saveSlideDeckReference(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  deleteReference = async (
    params: Omit<Parameters<typeof deleteSlideDeckReference>[1], 'slideDeckId'>
  ) => {
    return await deleteSlideDeckReference(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  sortReferences = async (
    params: Omit<Parameters<typeof sortSlideDeckReferences>[1], 'slideDeckId'>
  ) => {
    return await sortSlideDeckReferences(this.repository, {
      ...params,
      slideDeckId: this.slideDeckId,
    })
  }

  /****************************************************************
   *  teaching plans / modules
   ***************************************************************/
  private getTeachingPlanKey = ({
    catalogId,
    teachingPlanId,
  }: {
    catalogId: string
    teachingPlanId: string
  }) => `teachingPlan-${catalogId}-${teachingPlanId}`

  private getTeachingPlanModuleKey = ({
    catalogId,
    teachingPlanId,
    moduleId,
  }: {
    catalogId: string
    teachingPlanId: string
    moduleId: string
  }) => `module-${catalogId}-${teachingPlanId}-${moduleId}`

  private initTeachingPlanStreams = async (
    streamData: { catalogId: string; teachingPlanId: string }[]
  ) => {
    const streamDataWithKeys = streamData.map((data) => ({
      ...data,
      key: this.getTeachingPlanKey(data),
    }))

    streamDataWithKeys.forEach(({ catalogId, teachingPlanId, key }) => {
      if (this.hasStream(key)) return
      this._teachingPlanDataByIds[key] = TeachingPlan.empty(this.repository)
      this.addStream(
        getTeachingPlan(this.repository, {
          catalogId,
          teachingPlanId,
        }),
        (teachingPlan) => {
          this._teachingPlanDataByIds[key].replaceModel(teachingPlan)
        },
        { name: key, namespace: 'teaching-plan' }
      )
    })
  }

  private initTeachingPlanModuleStreams = async (
    streamData: {
      catalogId: string
      teachingPlanId: string
      moduleId: string
    }[]
  ) => {
    const streamDataWithKeys = streamData.map((data) => ({
      ...data,
      key: this.getTeachingPlanModuleKey(data),
    }))

    streamDataWithKeys.forEach(
      ({ catalogId, teachingPlanId, key, moduleId }) => {
        if (this.hasStream(key)) return
        this._teachingPlanModulesByIds[key] = TeachingPlanModule.empty(
          this.repository
        )
        this.addStream(
          getTeachingPlanModule(this.repository, {
            catalogId,
            teachingPlanId,
            moduleId,
          }),
          (module) => {
            this._teachingPlanModulesByIds[key].replaceModel(module)
          },
          { name: key, namespace: 'teaching-plan-module' }
        )
      }
    )
  }
}
