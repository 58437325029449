import { Spinner } from 'components/Spinner'
import { useBreakoutUser } from 'hooks/profile'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const ProfileTransactions = observer(function ProfileTransactions() {
  const { t } = useTranslation()
  const breakoutUser = useBreakoutUser()

  const tokensAvailable = breakoutUser.availableTokens
  const tokensConsumed = breakoutUser.consumedTokens

  const mappedSlideDecksToConsumedTokens =
    breakoutUser.mappedSlideDecksToConsumedTokens

  const consumedTokens = useMemo(
    () =>
      breakoutUser.tokens
        // todo(ashold12): modify transaction UI to support section passes
        .filter(
          (token) =>
            token.data.consumed && token.data.purchaseType !== 'sectionPass'
        )
        .sort((a, b) => {
          if (!a.data.purchaseDate || !b.data.purchaseDate) {
            return 0
          }
          return (
            new Date(b.data.purchaseDate).getTime() -
            new Date(a.data.purchaseDate).getTime()
          )
        }),
    [breakoutUser.tokens]
  )
  const loadingMappedSlideDecks =
    typeof mappedSlideDecksToConsumedTokens === 'undefined' &&
    consumedTokens.length > 0

  return (
    <div>
      <h1 className="text-headline-large text-core-on-tertiary">
        {t('profile.transactions')}
      </h1>
      <p className="mt-1 text-body-large">
        {t('profile.transactions_description')}
      </p>
      <div className="mt-1 grid grid-cols-2 text-label-medium text-core-on-tertiary">
        <div className="flex items-center justify-center px-4 py-2">
          {t('profile.tokens_available')}{' '}
          <strong className="ml-1 text-title-medium">
            {tokensAvailable ?? 0}
          </strong>
        </div>
        <div className="flex items-center justify-center px-4 py-2">
          {t('profile.tokens_consumed')}{' '}
          <strong className="ml-1 text-title-medium text-core-error">
            {tokensConsumed ?? 0}
          </strong>
        </div>
      </div>
      <div className="mt-6 space-y-2">
        {loadingMappedSlideDecks && <Spinner size={3} />}
        {!loadingMappedSlideDecks &&
          consumedTokens.map((token) => {
            const slideDeck = mappedSlideDecksToConsumedTokens?.[token.id]
            if (!slideDeck) {
              return null
            }

            // Ex. 28 Jul 2024
            const formattedDate = token.data.purchaseDate
              ? DateTime.fromJSDate(new Date(token.data.purchaseDate)).toFormat(
                  'dd LLL yyyy'
                )
              : ''

            return (
              <div
                key={token.id}
                tabIndex={0}
                className="flex h-[75px] w-full items-center justify-between rounded-2xl bg-surface px-5"
              >
                <div className="flex items-center">
                  <div className="shrink-0 p-2.5">
                    <img
                      alt={slideDeck.data.slideDeckName}
                      src={slideDeck.data.slideDeckImageURL}
                      className="h-5 w-5"
                    />
                  </div>
                  <div className="overflow-hidden ">
                    <div className="max-w-[150px] overflow-hidden truncate text-body-medium text-on-surface-var lg:max-w-[300px]">
                      {slideDeck.data.slideDeckName}
                    </div>
                    <div className="max-w-[150px] overflow-hidden truncate text-label-large text-core-on-tertiary lg:max-w-[300px]">
                      {slideDeck.data.slideDeckTeaser}
                    </div>
                    <div className="max-w-[150px] overflow-hidden truncate text-body-medium text-on-surface-var lg:max-w-[300px]">
                      {formattedDate}
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <div className="text-title-large text-core-on-tertiary">
                    {token.data.consumedCount}
                  </div>
                  <div className="text-body-small text-on-surface-var">
                    {t('profile.tokens')}
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
})
