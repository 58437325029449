import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { doc } from 'firebase/firestore'
import { getDocWithError } from '../../firestore-mobx/fetch'
import { schema } from './schema'

export * from './schema'

export const fetchCSVHeaders = async (repository: FirebaseRepository) => {
  const ref = doc(repository.firestore, 'settings/csv_headers')
  const headersDoc = await getDocWithError(ref, 'fetchCSVHeaderError')
  if (!headersDoc.exists()) return {}
  return schema.parse(headersDoc.data())
}
