import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useStudentAssignmentCubit } from 'hooks/cubits/studentAssignment'

export const StudentAssignmentInFuture = observer(
  function StudentAssignmentInFuture() {
    const cubit = useStudentAssignmentCubit()
    const { t } = useTranslation()

    return (
      <div className="flex h-full w-full items-center justify-center rounded-3xl bg-core-tertiary px-9">
        <div className="text-center">
          <p className="text-body-medium text-fixed-grey">
            {t('student_assignment.future_date_assignment')}
          </p>
          <h2 className="mt-3 text-title-large text-core-primary">
            {t('student_assignment.future_date_assignment')}
          </h2>
          <p className="mt-1 max-w-[406px] text-body-medium text-core-primary">
            {t('student_assignment.future_date_assignment_description', {
              date: cubit.assignment.formattedAssignedAt,
            })}
          </p>
        </div>
      </div>
    )
  }
)
