import classNames from 'classnames'
import type { DialogStoreEntry } from 'components/contexts/dialogs'
import { AnimatePresence, m } from 'framer-motion'
import { useDialogs } from 'hooks/dialogs'
import { useRootStore } from 'hooks/rootStore'
import { useSettings } from 'hooks/settings'
import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useRef, useState } from 'react'

// sync means that animations happen at the same time, so you get a crossfade effect
// wait means that the exit animation happens before the enter animation
const animationMode: 'sync' | 'wait' = 'wait'

// 0.2 works well for a fast fade in/out
// for sync, 0.3 works better
const animationDuration = 0.2

const animation = {
  unmount: {
    // if you want the drop in effect, uncomment this
    // y: -50,
    opacity: 0,
    transition: {
      duration: animationDuration,
    },
  },
  mount: {
    // if you want the drop out effect, uncomment this
    // y: 0,
    opacity: 1,
    transition: {
      duration: animationDuration,
    },
  },
}

export const DialogMount = observer(function DialogMount() {
  const rootStore = useRootStore()
  const { animationsEnabled } = useSettings()
  const { dialogStore } = useDialogs()
  const containerRef = useRef<HTMLDivElement | null>(null)

  const [controller, setController] = useState<{
    exiting: boolean
    currentDialog: DialogStoreEntry | null
  }>({
    exiting: false,
    currentDialog: null,
  })

  const clearDialogTimeout = useRef<NodeJS.Timeout | undefined>(undefined)

  useEffect(() => {
    // if the route changes, remove the dialog
    return reaction(
      () => rootStore.router.currentRoute,
      (route) => {
        if (route !== undefined) {
          setController({ exiting: false, currentDialog: null })
          dialogStore.clearAllDialogs()
        }
      }
    )
  }, [rootStore, dialogStore])

  useEffect(() => {
    const dispose = reaction(
      () => ({
        dialogs: dialogStore.dialogs,
        dialogCount: dialogStore.dialogs.length,
      }),
      ({ dialogs, dialogCount }) => {
        if (dialogCount > 0) {
          const entry = dialogs[dialogCount - 1]
          if (clearDialogTimeout.current) {
            clearTimeout(clearDialogTimeout.current)
            clearDialogTimeout.current = undefined
          }
          setController({ exiting: false, currentDialog: entry })
        } else {
          // we're exiting - we need to wait for the exit animation
          // to finish before unmounting the last dialog and the backdrop
          setController((previous) => {
            // if we are already closed, do nothing
            // this kicks in on initial load
            if (previous.exiting === false && previous.currentDialog === null) {
              return previous
            }

            clearDialogTimeout.current = setTimeout(
              () => {
                setController({ exiting: false, currentDialog: null })
              },
              animationsEnabled ? 300 : 0
            )

            return { exiting: true, currentDialog: null }
          })
        }
      },
      { fireImmediately: true }
    )

    return () => {
      dispose()
    }
  }, [dialogStore, animationsEnabled])

  // Disable body scrolling when a dialog is open
  // The modal steals all scrolling input, so we need to disable it on the body
  useEffect(() => {
    if (controller.currentDialog) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [controller.currentDialog])

  useEffect(() => {
    // if the route changes, remove the dialog
    return reaction(
      () => dialogStore.dialogs.length,
      (count) => {
        if (count === 0) {
          const main = document.getElementById('main-disabled')
          if (main && main.tagName.toLowerCase() === 'main') {
            main.id = 'main'
          }
        } else if (count > 0) {
          const main = document.getElementById('main')
          if (main && main.tagName.toLowerCase() === 'main') {
            main.id = 'main-disabled'
          }
        }
      }
    )
  }, [dialogStore])

  const captureTabbing = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const div = containerRef.current
      if (!div) return

      if (e.key === 'Tab') {
        const tabbables: HTMLElement[] = []

        // find all tabbable elements in the dialog context
        div
          .querySelectorAll(
            'button:not([disabled]), [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
          )
          .forEach((el) => {
            tabbables.push(el as HTMLElement)
          })

        const first = tabbables[0]
        const last = tabbables[tabbables.length - 1]
        const el = document.activeElement

        // Shift-Tab is moving backwards
        if (e.shiftKey) {
          if (el === first) {
            last.focus()
            e.preventDefault()
          }
        } else {
          if (el === last) {
            first.focus()
            e.preventDefault()
          }
        }
      }
    },
    [containerRef]
  )

  const currentDialog = controller.currentDialog
  if (currentDialog || controller.exiting) {
    const remove = async () => currentDialog?.remove()

    const component = currentDialog ? currentDialog.builder({ remove }) : null

    return (
      // this is the backdrop - Material Dialogs have their own backdrop, but they fade in and out
      // when we switch between Dialogs, and we want to maintain the backdrop as the stack of Dialogs changes
      <div
        id="main"
        ref={containerRef}
        onKeyDown={captureTabbing}
        className={classNames(
          'absolute left-0 top-0 z-[100] grid h-dvh w-dvw place-items-center bg-dialog-background',
          // blur is expensive, so we only apply it when animations are enabled
          // for now, I'm disabling blur
          animationsEnabled ? 'animate-fade-in' : '',
          // the fadeout animation is applied when we're exiting
          controller.exiting && animationsEnabled ? 'animate-fade-out' : ''
        )}
      >
        {animationsEnabled && (
          <AnimatePresence mode={animationMode} initial={false}>
            <m.div
              key={currentDialog?.id || 'exiting'}
              initial="unmount"
              exit="unmount"
              animate={'mount'}
              variants={animation}
            >
              {component}
            </m.div>
          </AnimatePresence>
        )}
        {!animationsEnabled && component}
      </div>
    )
  }

  return <div></div>
})
