import { observer } from 'mobx-react-lite'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useTranslation } from 'react-i18next'
import { GraphBarIcon } from 'components/icons/GraphBar'
import { AirplayVideoIcon } from 'components/icons/AirplayVideo'
import { useCallback, useEffect } from 'react'
import classNames from 'classnames'
import { useDialogs } from 'hooks/dialogs'
import { QuizModal } from '../QuizModal'
import { Button } from 'components/Button'
import { useWalkthroughDialog } from './WalkthroughDialog.hooks'
import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import { BreakoutAsyncButton } from 'components/design-system/BreakoutButton'
import { RoomStateStatus } from '@breakoutlearning/firebase-repository/models/RoomState'

export const StudentAssignmentJoinSessionDialog = observer(
  function StudentAssignmentJoinSessionDialog({
    cubit,
  }: {
    cubit: StudentAssignmentCubit
  }) {
    const { popDialog, showDialog } = useDialogs()
    const roomId = cubit.roomStateId
    const { showWalkthroughDialogIfNecessary } = useWalkthroughDialog({
      roomId,
      roomStateStatus: cubit.libraryObject.roomStateStatus,
    })
    const showQuizElements = !cubit.allPreWorkQuestionsAnswered

    const openQuizDialog = useCallback(() => {
      showDialog(() => (
        <QuizModal
          cubit={cubit}
          closeModal={() => {
            popDialog()
          }}
        />
      ))
    }, [cubit, popDialog, showDialog])

    const onStartClick = useCallback(() => {
      if (showQuizElements) {
        openQuizDialog()
        // open quiz
      } else {
        if (cubit.roomStateId === null) {
          throw new Error('no ID on RoomState')
        }

        showWalkthroughDialogIfNecessary(() => cubit.setJoiningSession())
      }
    }, [
      cubit,
      openQuizDialog,
      showQuizElements,
      showWalkthroughDialogIfNecessary,
    ])

    // set visible true on mount / false on unmount
    useEffect(() => {
      cubit.onDialogMountUnmount('mount', 'join')
      return () => cubit.onDialogMountUnmount('unmount', 'join')
    }, [cubit])

    return (
      <JoinSessionDialog
        onStartClick={onStartClick}
        quizView={showQuizElements}
        continuingSuspendedSession={
          cubit.roomStateStatus === RoomStateStatus.suspended
        }
      />
    )
  }
)

export const AdminDemoJoinSessionDialog = ({
  joinMeeting,
}: {
  joinMeeting: () => void
}) => {
  return (
    <JoinSessionDialog
      quizView={false}
      onStartClick={joinMeeting}
      useAsyncButton={true}
    />
  )
}

const JoinSessionDialog = observer(function JoinSessionDialog({
  onStartClick,
  quizView,
  useAsyncButton = false,
  continuingSuspendedSession = false,
}: {
  onStartClick: () => void | Promise<void>
  quizView: boolean
  useAsyncButton?: boolean
  continuingSuspendedSession?: boolean
}) {
  const { t } = useTranslation()
  const titleTextKey = continuingSuspendedSession
    ? 'student_assignment.continue_suspended_session'
    : 'student_assignment.time_to_join'
  const paragraphTextKey = quizView
    ? 'student_assignment.quiz_required'
    : 'student_assignment.please_join_now'
  const buttonTextKey = quizView
    ? 'student_assignment.start_quiz'
    : continuingSuspendedSession
      ? 'student_assignment.continue_session'
      : 'student_assignment.join_session'
  const ButtonIcon = quizView ? GraphBarIcon : AirplayVideoIcon
  return (
    <Dialog
      testId="join-session-dialog"
      size="sm"
      innerClassName="flex h-full flex-col items-center justify-center text-center"
    >
      <DialogCloseButton className="absolute right-0 top-0 p-5 pb-0" />
      <h1 className="mb-3 text-headline-large">{t(titleTextKey)}</h1>
      <div className="mb-3 flex flex-grow flex-col">
        {!continuingSuspendedSession && (
          <h2 className="mb-3 text-title-large">
            {t('student_assignment.session_live')}
          </h2>
        )}
        <p className="text-body-medium">{t(paragraphTextKey)}</p>
      </div>
      {useAsyncButton ? (
        <BreakoutAsyncButton
          size="large"
          className={classNames(
            '!text-core-on-primary',
            quizView ? '!bg-core-primary' : '!bg-breakout-dark-green'
          )}
          onClick={async () => await onStartClick()}
          icon={<ButtonIcon className="mr-1 inline" size={14} />}
        >
          {t(buttonTextKey)}
        </BreakoutAsyncButton>
      ) : (
        <Button
          className={classNames(
            'text-core-on-primary',
            quizView ? 'bg-core-primary' : 'bg-breakout-dark-green'
          )}
          onClick={onStartClick}
        >
          <ButtonIcon className="mr-1 inline" size={14} />
          {t(buttonTextKey)}
        </Button>
      )}
    </Dialog>
  )
})
