import * as i18n from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'

import pl from './locale/pl.json'
import en from './locale/en.json'

export function setLanguage(lang: string) {
  localStorage.setItem('language', lang)
  i18n.changeLanguage(lang)
}

// Right now we load the languages directly, down the line we can do this dynamically
const resources = {
  en: {
    translation: en,
  },
  pl: {
    translation: pl,
  },
}

export function initI18n(lang: string) {
  i18n.use(initReactI18next)
  i18n.init({
    resources,
    lng: lang,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })
}

export type Translation = i18n.TFunction<'en', undefined> // English locale

export function useTranslationTyped() {
  const i18n = useTranslation()

  /*
   * tt is a typesafe version of t.
   * Use like tt.login.title() or tt.login.title({ name: 'John' })
   */
  return { ...i18n, tt: structuredEnglishLocale(i18n.t) }
}

let structuredEnglishLocaleMemo: NestedObject<typeof en> | null = null

function structuredEnglishLocale(
  translation: Translation
): NestedObject<typeof en> {
  if (structuredEnglishLocaleMemo) {
    return structuredEnglishLocaleMemo
  }

  structuredEnglishLocaleMemo = buildTranslationPaths(translation, en)
  return structuredEnglishLocaleMemo
}

type NestedObject<T> = {
  [K in keyof T]: T[K] extends object
    ? NestedObject<T[K]>
    : (params?: Record<string, unknown>) => string
}

function buildTranslationPaths<T extends object>(
  t: Translation,
  locale: T,
  prefix = ''
): NestedObject<T> {
  return Object.entries(locale).reduce((acc, [key, value]) => {
    const path = prefix ? `${prefix}.${key}` : key
    if (typeof value === 'object' && value !== null) {
      return {
        ...acc,
        [key]: buildTranslationPaths(t, value, path),
      }
    }

    return {
      ...acc,
      [key]: (params?: Record<string, unknown>) => t(path, params),
    }
  }, {} as NestedObject<T>)
}
