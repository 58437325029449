import { computed, makeObservable } from 'mobx'
import { MeetingResultsSlide } from './MeetingResultsSlide'

type EndOfSessionQuizPhase = 'loading' | 'quiz' | 'processing' | 'results'

export class EndOfSessionQuizSlide extends MeetingResultsSlide {
  afterConstructor() {
    makeObservable(this, {
      questions: computed,
      phase: computed,
    })
  }

  get questions() {
    return this.meeting.questions
      .filter((q) => q.slideId === this.meeting.currentSlide?.id && q.isQuiz)
      .sort((a, b) => a.question.localeCompare(b.question))
  }

  get titleIndex(): number {
    // loop over currentSlide.questions and find out if all are submitted
    for (const q of this.questions) {
      if (!q.isSubmitted) {
        return 0
      }
    }
    return 1
  }

  get canProceed() {
    return (
      this.meeting.endOfSessionQuizSlideCanProceed || this.meeting.hasFeedback
    )
  }

  get phase(): EndOfSessionQuizPhase {
    if (this.meeting.slideDeckQuestions.isLoading) return 'loading'

    const allAnswered = this.questions.every(
      (question) => question.isAnswered && question.isSubmitted
    )

    if (this.questions.length > 0 && !allAnswered) return 'quiz'
    if (this.questions.length === 0 || allAnswered) {
      if (this.meeting.endOfSessionQuizSlideShouldProceed) return 'results'
      // if someone has proceeded, go to results, otherwise go to processing
      return 'processing'
    }

    return 'results'
  }
}
