import { useTranslation } from 'react-i18next'
import { CameraAlt } from 'components/icons/CameraAlt'

export function PermissionsErrorBanner({
  onDismiss,
}: {
  onDismiss: () => void
}) {
  const { t } = useTranslation()
  return (
    <div className="z-100 fixed left-0 top-0 flex w-full items-center border-b-1 bg-light-grey p-2 text-core-on-secondary">
      <div className="flex w-full flex-row justify-between px-1">
        <div className="flex flex-row">
          <CameraAlt
            size={24}
            className="mr-2 fill-core-on-tertiary stroke-core-secondary"
          />
          <p className="mr-2 text-body-large">
            {t('dart.video_not_readable_error_popup')}
          </p>
        </div>
        <div className="flex flex-row">
          <button
            className="mr-4 text-label-large text-grey-text"
            onClick={() =>
              window.open(
                'https://support.breakoutlearning.com/help-center/audio-video',
                '_blank'
              )
            }
          >
            Help
          </button>
          <button
            className="mr-1 text-label-large text-grey-text"
            onClick={onDismiss}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  )
}
