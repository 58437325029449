import { observer } from 'mobx-react-lite'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useTranslation } from 'react-i18next'
import { useDialogs } from 'hooks/dialogs'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { Controller, useForm } from 'react-hook-form'
import { useEffect, useMemo, useState } from 'react'
import {
  BreakoutAsyncButton,
  BreakoutButton,
  BreakoutIconButton,
} from 'components/design-system/BreakoutButton'
import type {
  StaticModelCollection,
  OrganizationInstructorDetails,
} from '@breakoutlearning/firebase-repository/types'
import type { OrganizationCubit } from '@breakoutlearning/firebase-repository/cubits/OrganizationCubit'
import { noTryAsync } from '@breakoutlearning/firebase-repository/util'
import { type Catalog } from '@breakoutlearning/firebase-repository/models/Catalog'
import { Spinner } from 'components/Spinner'
import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { TrashCanIcon } from 'components/icons/TrashCan'
import { BreakoutCheckbox } from 'components/design-system/BreakoutCheckbox'
import { toast } from 'react-hot-toast'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import classNames from 'classnames'

export const EditProfessorDialog = observer(function EndClassDialog({
  orgId,
  orgInstructor,
  publicUser,
  removeInstructorFromOrganization,
  updateOrgInstructor,
  getCatalogsForInstructor,
  updateCatalogsForUser,
  catalogs,
}: {
  orgId: string
  orgInstructor: OrganizationInstructorDetails['orgInstructor']
  publicUser: OrganizationInstructorDetails['publicUser']
  removeInstructorFromOrganization: OrganizationCubit['removeInstructor']
  updateOrgInstructor: OrganizationCubit['updateOrgInstructor']
  getCatalogsForInstructor: OrganizationCubit['getCatalogsForInstructor']
  updateCatalogsForUser: OrganizationCubit['updateCatalogsForUser']
  catalogs: StaticModelCollection<Catalog>
}) {
  const { t } = useTranslation()
  const [generalError, setGeneralError] = useState<string>()
  const { popDialog } = useDialogs()

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      autoApproveInvoice: orgInstructor.autoApproveInvoice ?? false,
    },
  })

  const [selectedCatalog, setSelectedCatalog] = useState<string | null>(null)
  const [catalogIdForDelete, setCatalogIdForDelete] = useState<string | null>(
    null
  )
  const [catalogsToAdd, setCatalogsToAdd] = useState<string[]>([])
  const [catalogsToRemove, setCatalogsToRemove] = useState<string[]>([])
  const [instructorCatalogs, setInstructorCatalogs] = useState<
    | {
        catalog: Catalog
        managedByOrganization: boolean
      }[]
    | null
  >(null)

  const catalogsToRenderForInstructor = useMemo(() => {
    const newCatalogs = catalogsToAdd
      .map((catalogId) => catalogs.models.find((c) => c.id === catalogId))
      .filter((c) => c !== undefined)

    const currentCatalogs = (instructorCatalogs || []).filter(
      ({ catalog }) =>
        !catalogsToRemove.includes(catalog.id) &&
        !catalogsToAdd.includes(catalog.id)
    )
    return [
      ...newCatalogs.map((c) => ({ catalog: c, managedByOrganization: true })),
      ...currentCatalogs,
    ] as {
      catalog: Catalog
      managedByOrganization: boolean
    }[]
  }, [catalogsToAdd, catalogsToRemove, instructorCatalogs, catalogs.models])

  const catalogsToRenderForSelect = useMemo(() => {
    const selected = new Set(
      catalogsToRenderForInstructor.map(({ catalog }) => catalog.id)
    )
    return catalogs.models.filter((c) => !selected.has(c.id))
  }, [catalogsToRenderForInstructor, catalogs.models])

  useEffect(() => {
    const stream = getCatalogsForInstructor(orgInstructor.userId)
    const sub = stream.listen((catalogs) => {
      setInstructorCatalogs(
        catalogs.map((c) => ({
          catalog: c,
          managedByOrganization: c.managingOrganizationId === orgId,
        }))
      )
    })
    return () => sub.cancel()
  }, [getCatalogsForInstructor, orgInstructor.userId, orgId])

  const onSubmit = async (values: { autoApproveInvoice: boolean }) => {
    setGeneralError(undefined)
    const [, err] = await noTryAsync(async () => {
      await Promise.all([
        updateOrgInstructor(orgInstructor.userId, {
          autoApproveInvoice: values.autoApproveInvoice,
        }),
        updateCatalogsForUser({
          userId: orgInstructor.userId,
          catalogsToAdd,
          catalogsToRemove,
        }),
      ])
    })

    if (err) {
      console.error(err)
      setGeneralError(t('organization.failed_to_save'))
      return
    }

    popDialog()
    toast.success(t('organization.instructor_updated'))
  }

  return (
    <Dialog className="w-full" size="lg" innerClassName="h-full flex">
      <DialogCloseButton />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex min-h-[450px] flex-col justify-between"
      >
        <div>
          <div className="mb-5 text-headline-large">
            {t('organization.professor_account_settings')}
          </div>

          <div className="grid grid-cols-2 gap-3">
            <div className="space-y-2">
              <div className="text-title-large">
                {t('organization.details')}
              </div>
              <BreakoutTextInput
                value={publicUser.fullName}
                disabled={true}
                label={t('organization.professor_full_name')}
              />
              <div className="text-title-large">
                {t('organization.invoice_class_settings')}
              </div>
              <div className="px-4">
                <Controller
                  name="autoApproveInvoice"
                  control={control}
                  render={({ field, fieldState }) => (
                    <BreakoutCheckbox
                      {...field}
                      onChange={() => {
                        field.onChange(!field.value)
                        setGeneralError(undefined)
                      }}
                      error={fieldState.error}
                      labelPosition="left"
                      label={t('organization.auto_approve_label')}
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <div className="mb-2 text-title-large">
                {t('organization.catalogs')}
              </div>

              {catalogs.isLoading || instructorCatalogs === null ? (
                <Spinner />
              ) : (
                <>
                  <div className="flex w-full flex-row items-center gap-1">
                    <BreakoutSelect
                      options={catalogsToRenderForSelect.map((c) => ({
                        label: c.data.catalogName,
                        value: c.id,
                      }))}
                      className="flex-grow"
                      placeholder={t('organization.select_catalog')}
                      kind="secondary"
                      onChange={(value) => {
                        if (value) setSelectedCatalog(value)
                      }}
                      disabled={isSubmitting}
                      value={selectedCatalog}
                    />
                    <BreakoutButton
                      kind="secondary"
                      className="h-13 !min-w-0"
                      disabled={isSubmitting || !selectedCatalog}
                      onClick={() => {
                        if (!selectedCatalog) return
                        // if catalogs to remove contains the value, remove it
                        if (catalogsToRemove.includes(selectedCatalog)) {
                          return setCatalogsToRemove((old) =>
                            old.filter((id) => id !== selectedCatalog)
                          )
                        }
                        setCatalogsToAdd((old) => [...old, selectedCatalog])
                        setSelectedCatalog(null)
                      }}
                    >
                      {t('organization.add')}
                    </BreakoutButton>
                  </div>
                  <div className="mt-2 flex flex-col gap-2">
                    {catalogsToRenderForInstructor.map((c) => {
                      return (
                        <div
                          key={c.catalog.id}
                          className={classNames(
                            'flex w-full flex-row items-center justify-between gap-3 overflow-y-auto rounded-2xl bg-light-grey px-5 py-3 text-label-medium',
                            {
                              'text-on-surface-disabled':
                                !c.managedByOrganization,
                            }
                          )}
                        >
                          <span>{c.catalog.data.catalogName}</span>
                          {
                            <BreakoutIconButton
                              size="small"
                              kind="secondary"
                              className={classNames({
                                invisible: !c.managedByOrganization,
                              })}
                              disabled={
                                isSubmitting || !c.managedByOrganization
                              }
                              icon={<TrashCanIcon size={15} />}
                              onClick={async () => {
                                setCatalogIdForDelete(c.catalog.id)
                              }}
                            />
                            //(
                            //   <BreakoutTooltip
                            //     content={t(
                            //       'organization.catalog_not_managed_by_organization'
                            //     )}
                            //   >
                            //     <BreakoutIconButton
                            //       size="small"
                            //       kind="secondary"
                            //       disabled={true}
                            //       icon={<InfoIcon size={15} />}
                            //     />
                            //   </BreakoutTooltip>
                            // )
                          }
                        </div>
                      )
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="text-right">
          {/* This should only show if we 500 on our update call. */}
          <div className="mb-3 text-title-medium text-core-error">
            {generalError}
          </div>
          <div className="flex flex-row justify-between">
            <BreakoutAsyncButton
              size="large"
              className="bg-core-secondary !text-core-error"
              onClick={async () => {
                await removeInstructorFromOrganization(orgInstructor.userId)
                popDialog()
              }}
            >
              {t('organization.remove_user_from_organization')}
            </BreakoutAsyncButton>
            <BreakoutButton
              kind="primary"
              size="large"
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {t('organization.save')}
            </BreakoutButton>
          </div>
        </div>
      </form>
      {catalogIdForDelete && (
        <ConfirmationDialog
          inline={true}
          text={t('organization.remove_catalog_access_dialog_title')}
          subtitle={t('organization.remove_catalog_access_dialog_subtitle')}
          buttonText={t('organization.remove_catalog')}
          onConfirm={async () => {
            if (catalogsToAdd.includes(catalogIdForDelete)) {
              return setCatalogsToAdd((old) =>
                old.filter((id) => id !== catalogIdForDelete)
              )
            }
            setCatalogsToRemove((old) => [...old, catalogIdForDelete])
          }}
          dismiss={() => setCatalogIdForDelete(null)}
        />
      )}
    </Dialog>
  )
})
