import { InfoIcon } from 'components/icons/Info'
import { useRootStore } from 'hooks/rootStore'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

export function NewVersionBanner() {
  const { t } = useTranslation()
  const store = useRootStore()

  const [visible, setVisible] = useState(false)
  const [versionUpdateAvailable, setVersionUpdateAvailable] = useState(false)
  const [bannerThrottled, setBannerThrottled] = useState(false)
  const bannerPopTimeout = useRef<NodeJS.Timeout | null>(null)
  const ineligibleRoutes = useMemo(() => ['meeting'], []) //todo: login/welcome from dart. what are they now?

  useEffect(() => {
    const listener = () => {
      setVersionUpdateAvailable(true)
    }
    window.addEventListener('breakout:version-update', listener)
    return () => {
      window.removeEventListener('breakout:version-update', listener)
    }
  }, [])

  useEffect(() => {
    if (
      !versionUpdateAvailable ||
      ineligibleRoutes.includes(store.router.currentRoute?.title || '') ||
      bannerThrottled ||
      visible
    ) {
      return
    }
    setVisible(true)
  }, [
    bannerThrottled,
    ineligibleRoutes,
    store.router.currentRoute,
    versionUpdateAvailable,
    visible,
  ])

  if (!visible) return null

  return (
    <div className="absolute left-0 top-0 z-50 flex w-full flex-row items-center gap-4 border-b-1 bg-surface p-3">
      <InfoIcon size={28} />
      <strong className="mr-2 text-body-large">
        {t('banners.new_app_version_popup')}
      </strong>
      <div className="flex-grow" />
      <button
        className="rounded-md p-1.5 text-label-large hover:bg-surface-dim"
        onClick={() => {
          setBannerThrottled(true)
          bannerPopTimeout.current = setTimeout(
            () => {
              setBannerThrottled(false)
            },
            1000 * 60 * 20
          ) // pop banner again in 20 minutes on dismiss
          setVisible(false)
        }}
      >
        {t('banners.do_later')}
      </button>
      <button
        className="rounded-md p-1.5 text-label-large hover:bg-surface-dim"
        onClick={() => window.location.reload()}
      >
        {t('banners.new_app_version_popup_refresh')}
      </button>
    </div>
  )
}
