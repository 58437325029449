import { useRootStore } from '../../hooks/rootStore'
import { observer } from 'mobx-react-lite'
import { getRouteName, routes } from 'config/routes'
import type { MouseEvent } from 'react'
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { BreakoutTooltip } from '../../components/design-system/BreakoutTooltip'
import classNames from 'classnames'
import { useNavigationCubit } from 'hooks/cubits/navigation'
import { useSettings } from 'hooks/settings'

export const NavLink = observer(function NavLink({
  to,
  testId,
  activeOn,
  onClick,
  navigateOverride,
  showWhen,
  Icon,
  label,
  alwaysShowLabel,
  href,
  iconStrokeWidth,
  disableHoverState,
  ...rest
}: {
  to: string | null
  testId?: string
  activeOn?: string
  navigateOverride?: () => void
  showWhen?: boolean
  Icon: React.ComponentType<{
    size: number
    className?: string
    strokeWidth?: number
  }>
  label: string | null
  alwaysShowLabel?: boolean
  iconStrokeWidth?: number
  disableHoverState?: boolean
} & Pick<
  React.HTMLProps<HTMLAnchorElement>,
  'target' | 'href' | 'onClick' | 'rel'
>) {
  const baseClassName = `text-on-surface-var stroke-on-surface-var inline-block cursor-pointer rounded-lg ${!disableHoverState ? 'group hover:bg-surface-bright' : ''}`
  const activeClassName = `bg-surface-bright !stroke-on-surface !text-on-surface`

  const store = useRootStore()

  const route = to ? routes[to] : null
  const path = route?.path

  const [isTruncated, setIsTruncated] = useState(false)
  const { isMobile, isOpen, animationDuration } = useNavigationCubit()
  const { animationsEnabled } = useSettings()

  // if isOpen changes in desktop view with animations enabled
  // do NOT re-render the component w/ or w/o label
  // if this updates during the component lifecycle it is on the way out
  // and a new one will render in its place
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isOpenOnMount = useMemo(() => isOpen, [])
  const isOpenToUse = animationsEnabled && !isMobile ? isOpenOnMount : isOpen

  const activeOnArray = useMemo(() => {
    if (activeOn) {
      return activeOn.split(',').map((s) => s.trim())
    }
    return [to]
  }, [activeOn, to])

  const labelRef = useRef<HTMLDivElement>(null)

  const onClickHandler = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      navigateOverride ? navigateOverride() : to && store.navigateTo(to)
      onClick && onClick(e)
    },
    [store, to, onClick, navigateOverride]
  )

  // if label is truncated then show tooltip
  // todo: maybe add window resize listener?
  useLayoutEffect(() => {
    // check for truncation after animation finished
    const timeoutId = setTimeout(
      () => {
        if (!labelRef.current) return
        setIsTruncated(
          labelRef.current.scrollWidth > labelRef.current.clientWidth
        )
      },
      animationsEnabled ? animationDuration : 0
    )
    return () => clearTimeout(timeoutId)
  }, [label, isOpen, animationsEnabled, animationDuration])

  const isActive = useMemo(() => {
    const routeName = getRouteName(store.router.currentRoute)
    return routeName && activeOnArray.includes(routeName)
  }, [store.router.currentRoute, activeOnArray])

  const hasIconStrokeProps = iconStrokeWidth || isActive
  const iconStrokeProps =
    iconStrokeWidth || isActive ? { strokeWidth: iconStrokeWidth ?? 2 } : {}

  if (showWhen === false) {
    return null
  }

  if (isMobile) {
    return (
      <a
        data-testid={testId ?? to ?? undefined}
        aria-label={label ?? undefined}
        href={href ?? path}
        onClick={onClickHandler}
        className={classNames(
          'group inline-block w-full cursor-pointer truncate px-4 py-6',
          { 'bg-surface': isActive, 'hover:bg-surface': !disableHoverState }
        )}
        role={to || href ? 'link' : 'button'}
        {...rest}
      >
        <Icon
          size={25}
          className={classNames('mr-3 inline', {
            'stroke-[1.6]': !hasIconStrokeProps,
          })}
          {...iconStrokeProps}
        />
        {label}
      </a>
    )
  }

  if (!isOpenToUse && !alwaysShowLabel) {
    return (
      <BreakoutTooltip
        ariaHidden
        enabled={Boolean(label)}
        content={label || ''}
        placement="right"
      >
        <a
          data-testid={testId ?? to ?? undefined}
          aria-label={label ?? undefined}
          href={href ?? path}
          onClick={href ? undefined : onClickHandler}
          className={classNames(
            baseClassName,
            'py-2.5 text-center leading-none',
            {
              [activeClassName]: isActive,
            }
          )}
          role={to || href ? 'link' : 'button'}
          {...rest}
        >
          <div className="inline-block h-5 text-center align-middle">
            <Icon
              size={20}
              className={classNames({
                'stroke-[1.6]': !hasIconStrokeProps,
              })}
              {...iconStrokeProps}
            />
          </div>
        </a>
      </BreakoutTooltip>
    )
  }

  return (
    <BreakoutTooltip
      ariaHidden
      enabled={Boolean(label) && isTruncated}
      content={label || ''}
      placement="right"
    >
      <a
        data-testid={testId ?? to ?? undefined}
        aria-label={label ?? undefined}
        href={href ?? path}
        onClick={href ? undefined : onClickHandler}
        className={classNames(
          baseClassName,
          'flex flex-row items-center gap-2 py-2.5',
          {
            [activeClassName]: isActive,
            'px-3 text-left': label,
            'justify-center': !label,
          }
        )}
        role={to || href ? 'link' : 'button'}
        {...rest}
      >
        <div className="inline-block h-5 text-center align-middle">
          <Icon
            size={20}
            className={classNames({
              'stroke-[1.6]': !hasIconStrokeProps,
            })}
            {...iconStrokeProps}
          />
        </div>
        {label && (
          <strong
            ref={labelRef}
            className={classNames('truncate', {
              'text-body-medium': !isActive,
              'text-label-medium': isActive,
            })}
          >
            {label}
          </strong>
        )}
      </a>
    </BreakoutTooltip>
  )
})
