import { useMemo, useState } from 'react'
import { useBreakoutUser } from './profile'
import type { Section } from '@breakoutlearning/firebase-repository/models/Section'
import { useRootStore } from './rootStore'
import type { BreakoutUser } from '@breakoutlearning/firebase-repository/models/BreakoutUser'
import { observable } from 'mobx'

export const useUserPromotions = (section?: Section): ReturnType => {
  const user = useBreakoutUser()
  const rootStore = useRootStore()

  const [selectedUserPromotion, setSelectedUserPromotion] = useState<
    string | null
  >(null)
  const [selectedUserPromotionIds, setSelectedUserPromotionIds] = useState<
    string[]
  >([])

  const userPromotionRedemptionsArray = user.userPromotionRedemptionsArray
  const userPromotions = user.userPromotions.models
  const userPromotionRedemptions = user.userPromotionRedemptions

  const redemptionsForSection = useMemo(() => {
    if (!section) return []
    return userPromotionRedemptionsArray.filter(
      (redemption) => redemption.data.sectionId === section.id
    )
  }, [userPromotionRedemptionsArray, section])

  const usedPromotionIds = useMemo(() => {
    const fromRedemptions = redemptionsForSection.map(
      (redemption) => redemption.data.userPromotionId
    )
    return [...fromRedemptions, ...selectedUserPromotionIds]
  }, [redemptionsForSection, selectedUserPromotionIds])

  const promotionIsActive = useMemo(() => {
    if (!selectedUserPromotion) return false
    const userPromotion = userPromotions?.find(
      (p) => p.id === selectedUserPromotion
    )
    return !!userPromotion?.isActive
  }, [selectedUserPromotion, userPromotions])

  const promotionIsConsumable = useMemo(() => {
    if (!selectedUserPromotion) return false
    const userPromotion = userPromotions?.find(
      (p) => p.id === selectedUserPromotion
    )
    const redemptions =
      userPromotionRedemptions?.get(selectedUserPromotion) || []
    if (!userPromotion) return false
    if (!userPromotion.data.consumableCount) return false
    const consumedCount = redemptions.length
    return consumedCount < userPromotion.data.consumableCount
  }, [selectedUserPromotion, userPromotions, userPromotionRedemptions])

  const userPromotionOptions = useMemo(() => {
    const unusedPromotions = userPromotions?.filter(
      (p) => !usedPromotionIds.includes(p.id)
    )
    return (
      unusedPromotions?.map((userPromotion) => ({
        label: userPromotion.data.promotionName,
        value: userPromotion.id,
      })) || []
    )
  }, [userPromotions, usedPromotionIds])

  const hasPromotions = !!userPromotions && userPromotions.length > 0

  const returnValue = useMemo(
    () => ({
      userPromotions,
      selectedUserPromotion,
      selectedUserPromotionIds,
      usedPromotionIds,
      promotionIsActive,
      promotionIsConsumable,
      userPromotionOptions,
      hasPromotions,
      userPromotionRedemptions,
      setSelectedUserPromotion,
      setSelectedUserPromotionIds,
    }),
    [
      usedPromotionIds,
      promotionIsActive,
      promotionIsConsumable,
      selectedUserPromotion,
      selectedUserPromotionIds,
      userPromotionOptions,
      userPromotions,
      hasPromotions,
      userPromotionRedemptions,
    ]
  )

  if (rootStore.impersonationInfo) {
    // Return empty values — we don't want to risk allowing an admin to redeem one of these promotions.

    return {
      userPromotions: [],
      selectedUserPromotion: null,
      selectedUserPromotionIds: [],
      usedPromotionIds: [],
      promotionIsActive: false,
      promotionIsConsumable: false,
      userPromotionOptions: [],
      hasPromotions: false,
      userPromotionRedemptions: observable.map(),
      setSelectedUserPromotion: () => {},
      setSelectedUserPromotionIds: () => {},
    }
  }

  return returnValue
}

type ReturnType = {
  userPromotions: BreakoutUser['userPromotions']['models']
  selectedUserPromotion: string | null
  selectedUserPromotionIds: string[]
  usedPromotionIds: string[]
  promotionIsActive: boolean
  promotionIsConsumable: boolean
  userPromotionOptions: Array<{ label: string; value: string }>
  hasPromotions: boolean
  userPromotionRedemptions: BreakoutUser['userPromotionRedemptions']
  setSelectedUserPromotion: (promotion: string | null) => void
  setSelectedUserPromotionIds: (ids: string[]) => void
}
