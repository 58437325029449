import { useMemo, memo, useCallback } from 'react'
import { useRepository } from 'hooks/auth'
import { useCubitBuilder } from 'hooks/cubits'
import { RoomStateEventsCubit } from '@breakoutlearning/firebase-repository/cubits/RoomStateEventsCubit'
import { useEvents } from './useEvents'
import type { RubricDisplay, Event } from './types'
import { RubricResultDetail } from './RubricResultDetail'
import type { FirestoreRoomStateRubricResultDetail } from '@breakoutlearning/firebase-repository/types'

export const JustificationDetails = memo(function JustificationDetails({
  rubricResultDetails,
  className,
  hideEvents = false,
  roomId,
}: RubricDisplay) {
  const repository = useRepository()
  const cubit = useCubitBuilder(
    () => new RoomStateEventsCubit(repository, roomId),
    [repository, roomId]
  )

  const { showRubricArgumentEventMatchMinimum } = repository.featureFlags.data

  const eventIds = useMemo(
    () => rubricResultDetails.map((detail) => detail.eventId),
    [rubricResultDetails]
  )

  const [loadingEvents, events] = useEvents({
    eventIds,
    hideEvents,
    roomId,
    fetchRoomStateEvents: cubit.fetchRoomStateEvents,
  })

  const getEventsForDetail = useCallback(
    (detail: FirestoreRoomStateRubricResultDetail) =>
      events.models
        .filter(
          (event) =>
            event.id === detail.eventId &&
            detail.match >= showRubricArgumentEventMatchMinimum
        )
        .map(
          (event) =>
            ({
              id: event.id,
              transcript: event.data.transcript,
              eventTime: event.data.eventTime,
              confidence: event.data.confidence,
            }) as Event
        ),
    [events.models, showRubricArgumentEventMatchMinimum]
  )

  const sortedRubricResultDetails = useMemo(() => {
    return rubricResultDetails.sort((a, b) => {
      const timeA = a.eventTime instanceof Date ? a.eventTime.getTime() : 0
      const timeB = b.eventTime instanceof Date ? b.eventTime.getTime() : 0
      return timeA - timeB
    })
  }, [rubricResultDetails])

  const eventNumbers = useMemo(() => {
    const map: Record<string, number> = {}
    const transcriptToNumber: Record<string, number> = {}
    let counter = 1

    // First pass - assign numbers to unique transcripts
    sortedRubricResultDetails.forEach((detail) => {
      const detailEvents = getEventsForDetail(detail)
      detailEvents.forEach((event) => {
        if (!transcriptToNumber[event.transcript]) {
          transcriptToNumber[event.transcript] = counter++
        }
      })
    })

    // Second pass - use the same number for matching transcripts
    sortedRubricResultDetails.forEach((detail) => {
      const detailEvents = getEventsForDetail(detail)
      detailEvents.forEach((event) => {
        map[event.transcript + event.id] = transcriptToNumber[event.transcript]
      })
    })

    return map
  }, [sortedRubricResultDetails, getEventsForDetail])

  if (loadingEvents) {
    return null
  }

  return (
    <div className={className}>
      <ul className="mt-1 list-disc space-y-1 pl-4 text-body-medium text-on-surface">
        {sortedRubricResultDetails.map((detail, index) => {
          const detailEvents = getEventsForDetail(detail)

          // if (detailEvents.length === 0) {
          //   return null
          // }

          return (
            <RubricResultDetail
              key={detail.eventId + index}
              rubricResultDetail={detail}
              events={detailEvents}
              eventNumbers={eventNumbers}
            />
          )
        })}
      </ul>
    </div>
  )
})
