import { type SectionAssignment } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

export const GradingTooltipFinalGrade = ({
  scalars,
}: {
  scalars: NonNullable<SectionAssignment['data']['assignmentGradingScalars']>
}) => {
  const { t } = useTranslation()

  const {
    quizScore,
    attendanceScore,
    participationScore,
    totalRubricScore,
  }: typeof scalars = {
    quizScore: Math.round(scalars.quizScore * 100),
    attendanceScore: Math.round(scalars.attendanceScore * 100),
    participationScore: Math.round(scalars.participationScore * 100),
    totalRubricScore: Math.round(scalars.totalRubricScore * 100),
  }

  return (
    <TooltipWrapper className="max-w-[200px]">
      <ul className="list-outside list-disc pl-3">
        <li>
          {t('instructor_class.final_grade_tooltip.quiz_score', { quizScore })}
        </li>
        <li>
          {t('instructor_class.final_grade_tooltip.attendance', {
            attendanceScore,
          })}
        </li>
        <li>
          {t('instructor_class.final_grade_tooltip.completion', {
            participationScore,
          })}
        </li>
        <li>
          {t('instructor_class.final_grade_tooltip.critical_thinking', {
            totalRubricScore,
          })}
        </li>
      </ul>
    </TooltipWrapper>
  )
}

export const GradingTooltipAttendance = () => {
  const { t } = useTranslation()
  return (
    <TooltipWrapper>
      <ul className="list-outside list-disc pl-3">
        <li>{t('instructor_class.attendance_tooltip.1')}</li>
        <li>{t('instructor_class.attendance_tooltip.2')}</li>
      </ul>
    </TooltipWrapper>
  )
}

export const GradingToolTipQuizScore = () => {
  const { t } = useTranslation()
  return (
    <TooltipWrapper>{t('instructor_class.quiz_score_tooltip')}</TooltipWrapper>
  )
}

export const GradingTooltipCompletion = () => {
  const { t } = useTranslation()
  return (
    <TooltipWrapper className="max-w-[350px]">
      <ul className="list-outside list-disc pl-3">
        <li>{t('instructor_class.completion_tooltip.1')}</li>
        <li>{t('instructor_class.completion_tooltip.2')}</li>
        <li>{t('instructor_class.completion_tooltip.3')}</li>
      </ul>
    </TooltipWrapper>
  )
}

export const GradingTooltipCriticalThinking = () => {
  const { t } = useTranslation()
  return (
    <TooltipWrapper className="max-w-[300px]">
      <span>{t('instructor_class.critical_thinking_tooltip.1')}</span>
      <ul className="list-outside list-disc pl-5">
        <li>{t('instructor_class.critical_thinking_tooltip.2')}</li>
      </ul>
    </TooltipWrapper>
  )
}

const TooltipWrapper = ({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { children: React.ReactNode }) => {
  return (
    <div
      className={classNames(
        'rounded-lg bg-on-surface-var px-4 py-2 text-body-medium text-core-on-primary',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}
