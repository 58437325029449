import type { FirebaseRepository } from './FirebaseRepository'
import type { MobxDocument } from '../types'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  SlideDeckMaterialType,
  SlideDeckMaterialWorkType,
  empty,
  type FirestoreSlideDeckMaterial,
} from '../firestore/SlideDeckMaterial/schema'

export class SlideDeckMaterial extends ObservableModelWithDecorators<FirestoreSlideDeckMaterial> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreSlideDeckMaterial>
  ) {
    super(repository, doc)
  }

  get isPreWork() {
    return this.data.materialWorkType === SlideDeckMaterialWorkType.preWork
  }

  get isPostWork() {
    return this.data.materialWorkType === SlideDeckMaterialWorkType.postWork
  }

  get isPodcast() {
    return this.data.materialType === SlideDeckMaterialType.mp3
  }

  get isPDF() {
    return this.data.materialType === SlideDeckMaterialType.pdf
  }

  /// podcasts have imageURL field, as of now this is the only material type with
  /// two file urls/file types
  get uploadFieldsWithExtensions() {
    return slideDeckMaterialUploadFieldsWithExtensionsFromMaterialType(
      this.data.materialType
    )
  }

  safeFileName() {
    const { materialLink: { extension } = {} } = this.uploadFieldsWithExtensions
    if (!extension) return ''
    if (this.data.materialMediaFilename) {
      return `${this.data.materialMediaFilename}.${extension}`
    }
    // if no defined file name, make one
    const safeName = this.data.materialName
      .replace(/[^a-zA-Z0-9._-]/g, '_')
      .replace(/_{2,}/g, '_')
      .replace(/^_+|_+$/g, '')
      .substring(0, 255)

    return `${safeName}.${extension}`
  }
}

export const slideDeckMaterialUploadFieldsWithExtensionsFromMaterialType = (
  materialType: SlideDeckMaterialType
) => {
  switch (materialType) {
    case SlideDeckMaterialType.mp3:
      return {
        materialLink: {
          extension: 'mp3',
          mimeType: 'audio/mpeg',
        },
        imageUrl: {
          extension: 'jpg',
          mimeType: 'image/jpeg',
        },
      }
    case SlideDeckMaterialType.pdf:
      return {
        materialLink: {
          extension: 'pdf',
          mimeType: 'application/pdf',
        },
      }
    case SlideDeckMaterialType.webm:
      return {
        materialLink: {
          extension: 'webm',
          mimeType: 'video/webm',
        },
      }
    case SlideDeckMaterialType.mp4:
      return {
        materialLink: {
          extension: 'mp4',
          mimeType: 'video/mp4',
        },
      }
    case SlideDeckMaterialType.featuredLarge:
    case SlideDeckMaterialType.jpg:
      return {
        materialLink: {
          extension: 'jpg',
          mimeType: 'image/jpeg',
        },
      }
    case SlideDeckMaterialType.url:
      return {}
  }
}
