import { computed } from 'mobx'
import type { MobxDocument } from '../firestore-mobx/document'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import { empty, type FirestoreSlideDeck } from '../firestore/SlideDeck/schema'
import type { FirebaseRepository } from './FirebaseRepository'
import { SlideDeckState } from '../types'

export { SlideDeckState }

export class SlideDeck extends ObservableModelWithDecorators<FirestoreSlideDeck> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreSlideDeck>
  ) {
    super(repository, doc)
  }

  @computed
  get isArchived() {
    return this.isPublished && this.data.catalogIds.length === 0
  }

  @computed
  get isPublished() {
    return this.data.slideDeckState === SlideDeckState.published
  }

  @computed
  get readOnly() {
    return this.data.slideDeckState >= SlideDeckState.published
  }

  @computed
  get slideDeckState() {
    if (this.isArchived) {
      return SlideDeckState.hidden
    }
    return this.data.slideDeckState
  }

  @computed
  get priceInDollars() {
    const amount = this.data.slideDeckPrice * 0.1
    // Check if the amount is an integer
    if (Number.isInteger(amount)) {
      return `${amount}`
    } else {
      return `${amount.toFixed(2)}`
    }
  }

  safeFileName() {
    // if no defined file name, make one
    const safeName = (this.data.slideDeckName || 'slide-deck')
      .replace(/[^a-zA-Z0-9._-]/g, '_')
      .replace(/_{2,}/g, '_')
      .replace(/^_+|_+$/g, '')
      .substring(0, 255)

    return safeName
  }
}
