import type { FC } from 'react'
import type { FieldError } from 'react-hook-form'
import classNames from 'classnames'
import { ErrorIcon } from 'components/icons/Error'

export const FormError: FC<{
  errorClass?: string
  error?: FieldError
}> = ({ error, errorClass }) => {
  if (!error) return null

  return (
    <div
      className={classNames(
        'text-error mb-2 mt-1 px-4 text-xs font-medium text-core-error',
        errorClass
      )}
    >
      <div className="flex items-center">
        <ErrorIcon size={16} className="mr-1 shrink-0" />
        {error.message}
      </div>
    </div>
  )
}
