import { action, makeObservable, observable, reaction } from 'mobx'
import { DisposerTracker } from '../../util/DisposerTracker'
import type { MeetingCubit } from '../MeetingCubit'
import type { MeetingLivekitController } from './MeetingLivekitController'
import { SlideType } from '../../firestore/Slide/types'

/**
 * The SequestrationController class is responsible for sequestering users when entering
 * appropriate states
 *
 */
export class SequestrationController {
  disposers: DisposerTracker

  isSequestered: boolean = false

  constructor(
    private meeting: MeetingCubit,
    private livekitController: MeetingLivekitController
  ) {
    this.disposers = new DisposerTracker()

    makeObservable(this, {
      isSequestered: observable,
      sequester: action,
      unsequester: action,
    })
  }

  initialize() {
    this.disposers.add(
      reaction(
        // when the sequestration status changes, re-run sequesterIfNecessary
        () => this.shouldSequester(),
        () => {
          this.sequesterIfNecessary()
        }
      )
    )
    this.disposers.add(
      reaction(
        () => this.meeting.livekitRoom,
        () => {
          if (this.meeting.livekitRoom) {
            // when the room has changes, make sure to replace the listeners
            // to avoid creating multiple listeners
            this.removeListeners()
            this.addListeners()
          }
          this.sequesterIfNecessary()
        }
      )
    )
    // run on initialization to get the initial state correct
    this.sequesterIfNecessary()
  }

  addListeners() {
    this.meeting.livekitRoom?.on('trackSubscribed', this.sequesterIfNecessary)
    this.meeting.livekitRoom?.on(
      'localTrackPublished',
      this.sequesterIfNecessary
    )
  }

  removeListeners() {
    this.meeting.livekitRoom?.off('trackSubscribed', this.sequesterIfNecessary)
    this.meeting.livekitRoom?.off(
      'localTrackPublished',
      this.sequesterIfNecessary
    )
  }

  dispose() {
    this.removeListeners()
    this.disposers.dispose()
  }

  sequesterIfNecessary = () => {
    const shouldSequester = this.shouldSequester()

    // undefined means we can't answer the question if necessary
    // so we bail
    if (shouldSequester === undefined) return

    if (shouldSequester) {
      this.sequester()
    } else {
      this.unsequester()
    }
  }

  shouldSequester() {
    const currentSlide = this.meeting.currentSlide
    if (!currentSlide) return undefined

    if (currentSlide.type === SlideType.soloQuiz) {
      const allQuestionsAnswered = this.meeting.currentSlideQuestions.every(
        (q) => q.isAnswered && q.isSubmitted
      )
      if (allQuestionsAnswered) return false
      return true
    }

    return false
  }

  sequester() {
    const room = this.meeting.livekitRoom
    if (!room) return

    // loop over participants and disconnect from them
    // because there there are questions to answer
    for (const participant of room.remoteParticipants.values()) {
      for (const publication of participant.trackPublications.values()) {
        if (publication.isSubscribed) {
          publication.setSubscribed(false)
        }
      }
    }

    if (this.livekitController.isAudioEnabled()) this.livekitController.mute()
    if (this.livekitController.isVideoEnabled())
      this.livekitController.disableVideo()

    this.isSequestered = true
  }

  unsequester() {
    const room = this.meeting.livekitRoom
    if (!room) return

    // no need to unsequester if we are not sequestered
    // otherwise we will unmute and enable video on every slide change
    if (!this.isSequestered) return

    // // do not unsequester if the user is hidden
    if (this.meeting.currentUserIsHidden) return

    // loop over participants and disconnect from them
    // because there there are questions to answer
    for (const participant of room.remoteParticipants.values()) {
      for (const publication of participant.trackPublications.values()) {
        if (!publication.isSubscribed) {
          publication.setSubscribed(true)
        }
      }
    }

    if (!this.livekitController.isAudioEnabled())
      this.livekitController.unmute()
    if (!this.livekitController.isVideoEnabled())
      this.livekitController.enableVideo()

    this.isSequestered = false
  }
}
