import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import type { ValidLibraryObject } from '@breakoutlearning/firebase-repository/stores/ValidLibraryObject'
import { useDialogs } from 'hooks/dialogs'
import { useBreakoutUser } from 'hooks/profile'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { PurchaseDialogBody } from './PurchaseDialogBody'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { toast } from 'react-hot-toast'
import { useState } from 'react'
import { Spinner } from 'components/Spinner'

const SlideDeckDetailsWidget = function ({
  libraryObject,
}: {
  libraryObject: ValidLibraryObject
}) {
  const { t } = useTranslation()
  return (
    <div className="bg-lightGray flex h-[250px] w-[250px] flex-col items-center justify-between rounded-2xl p-[20px] shadow-2xl">
      <div className="mb-4 flex flex-grow items-end justify-center rounded-lg">
        <img
          src={libraryObject.slideDeckImageURL || ''}
          alt="Slide Deck Logo"
          className="max-h-[50px]"
        />
      </div>
      <div>
        {libraryObject.slideDeckTeaser && (
          <p className="mb-1 text-center text-body-medium text-gray-500">
            {libraryObject.slideDeckName}
          </p>
        )}
        <p className="line-clamp-2 text-center text-title-large">
          {libraryObject.slideDeckTeaser || libraryObject.slideDeckName}
        </p>
      </div>
      <div className="mt-4 rounded-full bg-core-primary px-4 py-1 text-center text-label-medium text-core-on-primary">
        {libraryObject.slideDeckPrice}{' '}
        {libraryObject.slideDeckPrice > 1
          ? t('student_assignment.tokens')
          : t('student_assignment.token')}
      </div>
    </div>
  )
}

export const EnrollDialogBody = observer(function EnrollDialogBody({
  cubit,
}: {
  cubit: StudentAssignmentCubit
}) {
  const { availableTokens } = useBreakoutUser()
  const tokensRequired = cubit.slideDeck.data.slideDeckPrice
  //const totalPrice = (Math.max(((tokensRequired - tokensAvailable) * 10), 0) / 100).toFixed(2);
  const { showDialog, popDialog } = useDialogs()
  const { t } = useTranslation()
  const [enrollInProgress, setEnrollInProgress] = useState(false)

  if (enrollInProgress) {
    return (
      <div className="flex flex-grow items-center justify-center">
        <Spinner />
      </div>
    )
  }

  return (
    <>
      <DialogCloseButton />
      <h1 className="text-center text-headline-large">
        {t('student_assignment.enroll_in_case')}
      </h1>
      <p className="text-center text-body-medium">
        {getEnrollOutcome(tokensRequired, availableTokens)}
      </p>
      <SlideDeckDetailsWidget libraryObject={cubit.libraryObject} />
      <div className="text-body-medium">
        <div className="flex-row justify-between">
          <span>{t('student_assignment.tokens_available_colon')}</span>
          <span> </span>
          <strong
            className={classNames(
              'text-title-small',
              tokensRequired > availableTokens ? 'text-red-500' : ''
            )}
          >
            {availableTokens} {availableTokens === 1 ? 'Token' : 'Tokens'}
          </strong>
        </div>
      </div>
      <EnrollOrPurchaseButton
        onEnroll={async () => {
          try {
            setEnrollInProgress(true)
            const { success, message } = await cubit.enroll()
            setEnrollInProgress(false)
            if (success) return popDialog()
            if (message) toast.error(message)
          } catch (e) {
            toast.error(t('student_assignment.enroll_failure'))
            popDialog()
            // rethrow to bubble to global error handler (sentry)
            throw e
          }
        }}
        onPurchase={() => {
          //close this dialog and show purchase dialog
          popDialog()
          showDialog(() => <PurchaseDialogBody cubit={cubit} />)
        }}
        slideDeckPrice={cubit.slideDeck.data.slideDeckPrice}
        requiresPurchase={tokensRequired > availableTokens}
      />
    </>
  )
})

function getEnrollOutcome(tokensRequired: number, tokensAvailable: number) {
  if (tokensRequired > tokensAvailable) {
    return `Breakout Learning works with a Token based system. You need to buy ${tokensRequired - tokensAvailable} Tokens to access this experience.`
  }
  return 'Breakout Learning works with a Token based system. You have enough tokens to access this experience.'
}

const EnrollOrPurchaseButton = function ({
  onEnroll,
  onPurchase,
  slideDeckPrice,
  requiresPurchase,
}: {
  onEnroll: () => void
  onPurchase: () => void
  slideDeckPrice: number
  requiresPurchase: boolean
}) {
  const buttonText = requiresPurchase
    ? 'Purchase Tokens'
    : `Enroll (${slideDeckPrice} ${slideDeckPrice > 1 ? 'Tokens' : 'Token'})`
  const onClick = requiresPurchase ? onPurchase : onEnroll
  return (
    <button
      className={classNames(
        'mb-[12px] rounded-2xl bg-core-primary px-6 py-4 text-label-large text-core-on-primary',
        requiresPurchase ? '' : 'w-full'
      )}
      onClick={onClick}
    >
      {buttonText}
    </button>
  )
}
