import {
  collection,
  doc,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import {
  convertDocumentSnapshotToModel,
  documentSnapshots,
  modelItemStream,
} from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { SettingsSectionPassPricing } from '../../models/SettingsSectionPassPricing'
import type { FirestoreSettingsSectionPassPricing } from './schema'
import { schema } from './schema'
import { getDocWithError } from '../../firestore-mobx/fetch'

const converter: FirestoreDataConverter<FirestoreSettingsSectionPassPricing> = {
  toFirestore: (user: FirestoreSettingsSectionPassPricing) => user,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    return schema.parse(snapshot.data())
  },
}

export const getDocRef = (firestore: Firestore) => {
  return doc(
    collection(firestore, 'settings').withConverter(converter),
    'pricing_section_pass'
  )
}

export const fetchSettingsSectionPassPricing = async (
  repository: FirebaseRepository
): Promise<
  | { enabled: false }
  | { enabled: true; pricingModel: SettingsSectionPassPricing }
> => {
  const docRef = getDocRef(repository.firestore)

  const doc = await getDocWithError(
    docRef,
    'FetchFirestoreSettingsSectionPassPricingError'
  )

  if (!doc.exists()) return { enabled: false }

  // parse into schema
  const { success, data } = schema.safeParse(doc.data())

  if (!success || data.sectionPassPrice <= 0) return { enabled: false }

  return {
    enabled: true,
    pricingModel: convertDocumentSnapshotToModel(
      repository,
      doc,
      SettingsSectionPassPricing
    ),
  }
}

export const getSectionPassPaymentEnabled = (
  repository: FirebaseRepository
) => {
  const docRef = getDocRef(repository.firestore)
  return documentSnapshots(docRef, {
    wrapped: true,
    skipUndefinedCacheResults: true,
  }).map((snapshot) => {
    if (!snapshot.exists()) return false
    const { success, data } = schema.safeParse(snapshot.data())
    if (!success || data.sectionPassPrice <= 0) return false
    return success
  })
}

export const getSettingsSectionPassPricing = (
  repository: FirebaseRepository
) => {
  const docRef = getDocRef(repository.firestore)
  return modelItemStream(repository, docRef, SettingsSectionPassPricing)
}
