import { Breadcrumb } from 'components/Breadcrumb'
import { LibraryObjectBadge } from 'components/LibraryObjectBadge'
import { useStudentAssignmentCubit } from 'hooks/cubits/studentAssignment'
import { useBreakoutUser } from 'hooks/profile'
import { useRootStore } from 'hooks/rootStore'
import { useCanvasMode } from 'hooks/use-canvas-mode'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const StudentAssignmentHeader = observer(
  function StudentAssignmentHeader() {
    const cubit = useStudentAssignmentCubit()
    const store = useRootStore()
    const user = useBreakoutUser()
    const { canvasMode } = useCanvasMode()
    const { t } = useTranslation()

    const onClick = useCallback(() => {
      const instructorAssignmentId =
        store.router.queryParams &&
        typeof store.router.queryParams.backToInstructorAssignment === 'string'
          ? decodeURIComponent(
              store.router.queryParams.backToInstructorAssignment
            )
          : undefined
      if (
        (user.isFaculty || user.isInternal) &&
        typeof instructorAssignmentId === 'string' &&
        instructorAssignmentId.includes('+')
      ) {
        const [sectionId, assignmentId] = instructorAssignmentId.split('+')
        return store.navigateToInstructorAssignmentPage(
          sectionId,
          assignmentId,
          { tab: 'students' }
        )
      }
      store.navigateTo('home')
    }, [store, user.isFaculty, user.isInternal])

    const label = `${cubit.slideDeck.data.slideDeckName} | ${cubit.slideDeck.data.slideDeckTeaser}`

    return (
      <div className="mb-3 flex flex-grow-0 flex-col items-center justify-between md:flex-row">
        <Breadcrumb
          aria-label={[label, t('student_assignment.back_to_class_list')].join(
            ', '
          )}
          className="min-w-[50%] flex-1"
          onClick={canvasMode ? undefined : onClick}
        >
          {label}
        </Breadcrumb>
        <div className="mt-3 md:mt-0">
          <LibraryObjectBadge libraryObject={cubit.libraryObject} />
        </div>
      </div>
    )
  }
)
