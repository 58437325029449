import { initI18n } from 'i18n/i18n'
import { useMemo } from 'react'

export function I18nWrapper({ children }: { children: JSX.Element }) {
  useMemo(() => {
    const lang = localStorage.getItem('language') || 'en'

    initI18n(lang)
  }, [])

  return <>{children}</>
}

export function I18nTestWrapper({ children }: { children: JSX.Element }) {
  useMemo(() => {
    initI18n('en')
  }, [])

  return <>{children}</>
}
