import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  linkWithPopup,
} from 'firebase/auth'
import { useCallback, useEffect, useState } from 'react'
import { useAuthContext, useRepository } from '../../hooks/auth'
import { DevLoginButton } from './DevLoginButton'
import type { SlideDeck } from '@breakoutlearning/firebase-repository/models/SlideDeck'
import type { SlideDeckMaterial } from '@breakoutlearning/firebase-repository/models/SlideDeckMaterial'
import { LoginCubit } from '@breakoutlearning/firebase-repository/cubits/LoginCubit'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { AnimatePresence, m } from 'framer-motion'
import { Trans } from 'react-i18next'
import { MultiLineText } from 'components/breakout/MultiLineText'
import { useCubitBuilder } from 'hooks/cubits'
import { useThrottledCallback } from 'hooks/functions'
import { FirebaseError } from 'firebase/app'
import { noTryAsync } from '@breakoutlearning/firebase-repository/util'
import { useDialogs } from 'hooks/dialogs'
import { getDialogConfirmation } from 'util/getDialogConfirmation'
import { useTranslationTyped } from 'i18n/i18n'

export const LoginPage = observer(function Login() {
  const repository = useRepository()
  const authContext = useAuthContext()
  const [error, setError] = useState<string | null>()
  const [loginInProgress, setLoginInProgress] = useState(false)
  const { t, tt } = useTranslationTyped()
  const { showDialog, popDialog } = useDialogs()

  const loginCubit = useCubitBuilder(
    () => new LoginCubit(repository),
    [repository]
  )
  const [showSlideDecks, setShowSlideDecks] = useState(false)

  const onLoginClick = useCallback(
    async (useProvider: 'google' | 'microsoft') => {
      const provider =
        useProvider === 'google'
          ? getProviderFromId('google.com')
          : getProviderFromId('microsoft.com')
      setError(null)
      const setErrorMessage = (error: unknown) =>
        setError(
          // @ts-expect-error can't cast as any, but its an error
          'message' in error && typeof error.message === 'string'
            ? error.message
            : 'Unknown error'
        )

      try {
        setLoginInProgress(true)
        await signInWithPopup(repository.auth, provider)
      } catch (error: unknown) {
        if (
          !(error instanceof FirebaseError) ||
          error.code !== 'auth/account-exists-with-different-credential' ||
          typeof error.customData?.email !== 'string'
        )
          return setErrorMessage(error)

        // When we fail login because account exists we get valid oauth data back in error.customData._tokenResponse
        // we should be able to use this to link the account sans the second popup but it doesn't work for unknown reasons
        // so I'm leaving this here for future reference

        // const { providerId, oauthIdToken, oauthAccessToken }  = error.customData._tokenResponse as Record<string, unknown>
        // if (typeof providerId !== 'string' || typeof oauthIdToken !== 'string' || typeof oauthAccessToken !== 'string' || !providerId || !oauthIdToken || !oauthAccessToken)
        //   return setErrorMessage(error)
        // const credential = new OAuthProvider(providerId).credential({idToken: oauthIdToken, accessToken: oauthAccessToken})

        // existing account with different provider, try to reconcile
        const { email } = error.customData

        // right now we only have google and microsoft so we can just switch between them
        // if we add more providers we'll probably need to add a button for each provider in the first confirmation dialog
        const otherSupportedLinkedProvider = getProviderFromId(
          useProvider === 'google' ? 'microsoft.com' : 'google.com'
        )
        otherSupportedLinkedProvider.setCustomParameters({ login_hint: email })

        // show confirmation to login with other provider
        const [signInResult, signInErr] = await noTryAsync(() =>
          getDialogConfirmation({
            showDialog,
            buttonKind: 'primary',
            text: t('login.auth_link.existing_provider_dialog_title'),
            subtitle: (
              <p className="text-body-medium">
                <Trans
                  i18nKey="login.auth_link.existing_provider_dialog_sub"
                  values={{ provider: otherSupportedLinkedProvider.providerId }}
                  components={{
                    bold: <strong className="font-bold" />,
                  }}
                />
              </p>
            ),
            onConfirm: async () => {
              repository.lockEntrypoint()
              return await signInWithPopup(
                repository.auth,
                otherSupportedLinkedProvider
              )
            },
            dismiss: () => {
              popDialog()
            },
          })
        )

        if (signInErr || !signInResult?.confirmed) {
          if (signInErr) setErrorMessage(signInErr)
          return
        }

        const { confirmed: didConfirmSignIn } = signInResult

        if (!didConfirmSignIn) return

        const { value: userCredential } = signInResult

        const [, linkErr] = await noTryAsync(() =>
          getDialogConfirmation({
            showDialog,
            text: t('login.auth_link.link_provider_dialog_title'),
            buttonKind: 'primary',
            subtitle: (
              <p className="text-body-medium">
                <Trans
                  i18nKey="login.auth_link.link_provider_dialog_sub"
                  values={{ provider: provider.providerId }}
                  components={{
                    bold: <strong className="font-bold" />,
                  }}
                />
              </p>
            ),
            dismiss: () => {
              popDialog()
            },
            onConfirm: async () =>
              await linkWithPopup(userCredential.user, provider),
          })
        )

        if (linkErr) {
          setErrorMessage(linkErr)

          // sign out the user if they confirmed linking but it failed
          await repository.auth.signOut()
        }
      } finally {
        repository.unlockEntrypoint()
        setLoginInProgress(false)
      }
    },
    [repository, popDialog, showDialog, t]
  )

  useEffect(() => {
    const onResize = () => {
      const sufficientSizeForCarousel = window.innerWidth > 1300
      if (sufficientSizeForCarousel) {
        loginCubit.initSlideDecks()
      }
      setShowSlideDecks(sufficientSizeForCarousel)
    }
    window.addEventListener('resize', onResize)
    onResize()
    return () => window.removeEventListener('resize', onResize)
  }, [loginCubit])

  return (
    <main id="main" className="flex h-full w-full flex-row gap-8">
      <div
        className={classNames(
          'box-border flex h-full flex-row rounded-3xl bg-surface-bright px-10 py-11 md:w-full',
          { 'max-w-[600px]': showSlideDecks }
        )}
      >
        <div className="items-left flex h-full flex-col justify-between">
          <div>
            <img
              // disabled until we have a dark mode version
              // className="dark:invert"
              src="/assets/images/logo_large_trimmed.png"
              alt="Breakout Learning Logo"
              width={161}
            />
          </div>
          <div className="max-w-[600px] text-on-surface">
            <h3 className=" mb-4 text-display-medium font-bold leading-10 tracking-tighter">
              <MultiLineText string={tt.login.title()} />
            </h3>
            <p className="text-body-large">{t('login.title_sub')}</p>
            <div>
              <div className="mt-6 flex flex-wrap gap-4">
                <button
                  disabled={loginInProgress}
                  className="flex w-[250px] flex-row items-center justify-center rounded-[4px] bg-core-secondary px-1 py-[0.625rem] text-label-large disabled:opacity-30"
                  onClick={() => onLoginClick('google')}
                >
                  <img
                    alt="Google Logo"
                    aria-hidden
                    className="mr-3 inline"
                    src="/assets/images/google.png"
                    width={30}
                    style={{ imageRendering: 'crisp-edges' }}
                  />
                  {t('login.google_login')}
                </button>
                <button
                  disabled={loginInProgress}
                  className="flex w-[250px] flex-row items-center justify-center rounded-[4px] bg-core-secondary px-1 py-[0.625rem] text-label-large disabled:opacity-30"
                  onClick={() => onLoginClick('microsoft')}
                >
                  <img
                    alt="Microsoft Logo"
                    aria-hidden
                    className="mr-3 inline disabled:opacity-30"
                    src="/assets/images/microsoft.png"
                    width={30}
                    style={{ imageRendering: 'crisp-edges' }}
                  />
                  {t('login.microsoft_login')}
                </button>
              </div>
              {error && <p className="mt-4 text-breakout-red">{error}</p>}
            </div>
            {authContext.usingLocalEmulator && (
              <div className="mt-6 flex flex-row flex-wrap items-center justify-start gap-2">
                <div>Development login as</div>
                <DevLoginButton role="admin" />
                <DevLoginButton role="instructor" />
                <DevLoginButton role="student" />
                <DevLoginButton role="ta" />
              </div>
            )}
          </div>
          <div>
            <strong className="text-label-medium">
              {t('login.login_terms')}{' '}
              <a
                className="text-title-small"
                target="_blank"
                href="https://breakoutlearning.com/legal/terms-of-use"
                rel="noreferrer"
              >
                {t('login.terms_and_conditions')}
              </a>
            </strong>
          </div>
        </div>
      </div>
      {showSlideDecks && loginCubit.slideDecksWithMaterials.length > 0 && (
        <SlideDeckCarousel cubit={loginCubit} />
      )}
    </main>
  )
})

const SlideDeckCarousel = observer(function SlideDeckCarousel({
  cubit,
}: {
  cubit: LoginCubit
}) {
  const [currentSlideDeckIndex, setCurrentSlideDeckIndex] = useState(0)
  const slideDeckWithMaterials =
    cubit.slideDecksWithMaterials[currentSlideDeckIndex]
  const animationDuration = 2

  const nextSlide = useThrottledCallback(
    () => {
      setCurrentSlideDeckIndex((index) => {
        const nextIndex = index + 1
        const nextIndexOrStart =
          nextIndex >= cubit.slideDecksWithMaterials.length ? 0 : nextIndex

        return nextIndexOrStart
      })
    },
    animationDuration * 1000,
    [cubit.slideDecksWithMaterials]
  )

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000)
    return () => clearInterval(interval)
  }, [nextSlide, currentSlideDeckIndex])

  return (
    <div className="relative h-full w-full">
      <AnimatePresence key="foo" initial={true} mode="sync">
        <m.div
          key={currentSlideDeckIndex.toString()}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: animationDuration }}
          className="absolute inset-0 left-0 top-0 h-full w-full "
        >
          <SlideDeckCarouselInner
            slideDeckWithMaterial={slideDeckWithMaterials}
            onClick={nextSlide}
            currentSlideDeckIndex={currentSlideDeckIndex}
            slideCount={cubit.slideDecksWithMaterials.length}
          />
        </m.div>
      </AnimatePresence>
    </div>
  )
})

const SlideDeckCarouselInner = ({
  slideDeckWithMaterial,
  onClick,
  currentSlideDeckIndex,
  slideCount,
}: {
  slideDeckWithMaterial: {
    slideDeck: SlideDeck
    material: SlideDeckMaterial
  }
  currentSlideDeckIndex: number
  slideCount: number
  onClick?: () => void
}) => {
  const { slideDeck, material } = slideDeckWithMaterial

  const materialLink = material.data.materialLink

  return (
    <div
      id="main"
      style={{ backgroundImage: `url(${materialLink})` }}
      className={classNames(
        'flex h-full w-full place-items-end overflow-hidden rounded-3xl bg-cover bg-center bg-no-repeat p-10',
        { 'cursor-pointer': !!onClick && slideCount > 1 }
      )}
      onClick={onClick}
    >
      <div
        className={classNames('flex w-full flex-row place-items-end', {
          'justify-between': slideCount > 1,
          'justify-end': slideCount === 1,
        })}
      >
        <CarouselTabs
          activeTab={currentSlideDeckIndex}
          totalTabs={slideCount}
        />
        <div className="flex flex-col place-items-end justify-end">
          <div className="mb-[9px] flex flex-row">
            <img
              src={slideDeck.data.slideDeckImageURL}
              className="mr-1 h-5 object-contain"
              alt={slideDeck.data.slideDeckName}
            />
            <h2 className="text-title-large text-core-tertiary">
              {slideDeck.data.slideDeckName}
            </h2>
          </div>
          <h3 className="text-headline-medium text-core-tertiary">
            {slideDeck.data.slideDeckTeaser}
          </h3>
        </div>
      </div>
    </div>
  )
}

const CarouselTabs = ({
  activeTab,
  totalTabs,
}: {
  activeTab: number
  totalTabs: number
}) => {
  if (totalTabs < 2) return null
  return (
    <div className="flex flex-row justify-center gap-1">
      {Array.from({ length: totalTabs }).map((_, i) => (
        <div
          key={i}
          className={classNames('h-0.5 w-[22px] rounded-[100px]', {
            'bg-fixed-grey': activeTab !== i,
            'bg-core-secondary': activeTab === i,
          })}
        />
      ))}
    </div>
  )
}

function getProviderFromId(providerId: 'google.com' | 'microsoft.com') {
  switch (providerId) {
    case 'google.com':
      return new GoogleAuthProvider()
    case 'microsoft.com':
      return new OAuthProvider('microsoft.com')
  }
}
