import { RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import type { SectionAssignment } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import { AssignmentGroupingType } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import { zodResolver } from '@hookform/resolvers/zod'
import classNames from 'classnames'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutDateTimeInput } from 'components/design-system/BreakoutDateTimeInput'
import { BreakoutRadioButton } from 'components/design-system/BreakoutRadioButton'
import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { FormError } from 'components/design-system/form/FormError'
import { InfoIcon } from 'components/icons/Info'
import { TriangleAlertIcon } from 'components/icons/TriangleAlert'
import { FullPageSpinner } from 'components/Spinner'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { useInstructorSectionCubit } from 'hooks/cubits/instructorSection'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { ConfigureGradingScalars } from 'pages/instructor/assignment/dialogs/ConfigureGradingScalars'
import { GroupingMethodDetails } from 'pages/instructor/slide_deck/GroupingMethodDetails'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { Control, UseFormSetValue } from 'react-hook-form'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { dateSchema } from 'util/schema-date'
import { z } from 'zod'

const getSchema = (t: ReturnType<typeof useTranslation>['t']) =>
  z.object({
    groupingType: z.nativeEnum(AssignmentGroupingType),
    groupingSize: z.coerce
      .number()
      .min(2, {
        message: t('instructor_library.grouping_size_invalid'),
      })
      .max(RoomState.maxAllowedUsers, {
        message: t('instructor_library.grouping_size_invalid'),
      })
      .optional(),
  })

const getSchemaWithExpiration = (
  t: ReturnType<typeof useTranslation>['t'],
  minExpiration: DateTime
) => {
  return z.object({
    groupingType: z.nativeEnum(AssignmentGroupingType),
    groupingSize: z.coerce
      .number()
      .min(2, {
        message: t('instructor_library.grouping_size_invalid'),
      })
      .max(RoomState.maxAllowedUsers, {
        message: t('instructor_library.grouping_size_invalid'),
      })
      .optional(),
    expiresAt: dateSchema({
      required: t('instructor_library.deadline_required'),
      min: {
        date: minExpiration,
        message: t(
          'instructor_assignment.deadline_must_be_one_day_after_start_date'
        ),
      },
    }),
  })
}

type FormValues = z.infer<ReturnType<typeof getSchema>>
type FormValuesWithExpiresAt = z.infer<
  ReturnType<typeof getSchemaWithExpiration>
>
type Mode = 'simple' | 'with-expired-at'

export const AssignmentConfiguration = observer(
  function AssignmentConfiguration() {
    const assignmentCubit = useInstructorAssignmentCubit()
    const [lockedMode, setLockedMode] = useState<Mode | undefined>()
    const expiresAt = assignmentCubit.assignment?.data.expiresAt
    const assignedAt = assignmentCubit.assignment?.data.assignedAt
    const mode = useMemo(() => {
      if (lockedMode) return lockedMode

      // if expires at is 24 hours after assigned at, we should show the simple form
      if (
        expiresAt &&
        assignedAt &&
        expiresAt.getTime() - assignedAt.getTime() > 24 * 60 * 60 * 1000
      ) {
        return 'simple'
      }

      return 'with-expired-at'
      // return expiresAt ? 'simple' : 'with-expired-at'
    }, [expiresAt, assignedAt, lockedMode])

    if (!assignmentCubit.assignment) {
      return <FullPageSpinner />
    }

    if (mode === 'with-expired-at') {
      return (
        <AssignmentConfigurationWithExpiredAt
          onSaving={(saving) => {
            setLockedMode(saving ? 'with-expired-at' : undefined)
          }}
        />
      )
    } else {
      return (
        <AssignmentConfigurationSimple
          onSaving={(saving) => {
            setLockedMode(saving ? 'simple' : undefined)
          }}
        />
      )
    }
  }
)

// Simpler form, does not impact dates at all
const AssignmentConfigurationSimple = observer(
  function AssignmentConfigurationSimple({
    onSaving,
  }: {
    onSaving?: (saving: boolean) => void
  }) {
    const { t } = useTranslation()
    const sectionCubit = useInstructorSectionCubit()
    const assignmentCubit = useInstructorAssignmentCubit()
    const [isSaving, setIsSaving] = useState(false)

    const [phase, setPhase] = useState<'basic' | 'grading'>('basic')
    const scalarsRef =
      useRef<SectionAssignment['data']['assignmentGradingScalars']>(undefined)
    const [gradingState, setGradingState] = useState<{
      gradingSumValid: boolean
      configureGrading: boolean
    }>({ gradingSumValid: false, configureGrading: false })

    const schema = useMemo(() => {
      return getSchema(t)
    }, [t])

    const { control, handleSubmit, setValue, watch } = useForm<FormValues>({
      resolver: zodResolver(schema),
      mode: 'onChange',
      defaultValues: {
        groupingType: AssignmentGroupingType.manual,
        groupingSize: 4,
      },
    })

    const onSubmit = useCallback(
      async (data: FormValues) => {
        if (
          gradingState.configureGrading === false ||
          (gradingState.configureGrading && !gradingState.gradingSumValid)
        ) {
          return
        }
        const scalars = scalarsRef.current
        setIsSaving(true)
        onSaving?.(true)
        try {
          await assignmentCubit.updateAssignmentGrouping({
            groupingType: data.groupingType,
            groupingSize:
              data.groupingType === AssignmentGroupingType.automaticRandom
                ? data.groupingSize
                : undefined,
          })
          if (scalars) {
            await assignmentCubit.updateGradingScalars({
              assignmentGradingScalars: scalars,
            })
          }
          await assignmentCubit.markAsActive()
          await sectionCubit.markAsInProgress()
        } finally {
          setIsSaving(false)
          onSaving?.(false)
        }
      },
      [assignmentCubit, sectionCubit, onSaving, gradingState]
    )

    const { groupingType } = watch()

    const expiresAtDate = assignmentCubit.assignment?.data.expiresAt
    const expiresAt = expiresAtDate
      ? DateTime.fromJSDate(expiresAtDate)
      : undefined

    return (
      <div
        className={classNames(
          'assignment flex min-h-screen w-full flex-col items-start justify-center',
          phase === 'basic' ? 'assignment' : 'assignment2'
        )}
      >
        <div className="ml-[5vw] max-w-[400px]">
          <Header phase={phase} />
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-5"
          >
            <div className={phase === 'basic' ? '' : 'hidden'}>
              <GroupingMethodInput
                assignmentCubit={assignmentCubit}
                control={control}
                setValue={setValue}
                groupingType={groupingType}
                expiresAt={expiresAt}
              />
            </div>

            <div className={phase === 'grading' ? '' : 'hidden'}>
              <GradingSection
                assignmentCubit={assignmentCubit}
                scalarsRef={scalarsRef}
                onChange={setGradingState}
              />
            </div>

            <Footer
              phase={phase}
              isSaving={isSaving}
              setPhase={setPhase}
              gradingState={gradingState}
              expiresAtValue={expiresAt}
              setExpiresAtError={() => {}}
            />
          </form>
        </div>
      </div>
    )
  }
)

const AssignmentConfigurationWithExpiredAt = observer(
  function AssignmentConfigurationWithExpiredAt({
    onSaving,
  }: {
    onSaving?: (saving: boolean) => void
  }) {
    const { t } = useTranslation()
    const assignmentCubit = useInstructorAssignmentCubit()
    const sectionCubit = useInstructorSectionCubit()
    const [isSaving, setIsSaving] = useState(false)

    const assignedAt = DateTime.fromJSDate(
      assignmentCubit.assignment?.data.assignedAt ?? new Date()
    )
    const expiresAt = assignmentCubit.assignment?.data.expiresAt

    const minScheduleDate = useMemo(() => {
      const now = DateTime.now()

      // set min date to start of tomorrow
      const min = now.plus({ days: 1 }).startOf('day')

      return min
    }, [])

    const schema = useMemo(() => {
      return getSchemaWithExpiration(t, minScheduleDate)
    }, [t, minScheduleDate])

    const [phase, setPhase] = useState<'basic' | 'grading'>('basic')
    const scalarsRef =
      useRef<SectionAssignment['data']['assignmentGradingScalars']>(undefined)
    const [gradingState, setGradingState] = useState<{
      gradingSumValid: boolean
      configureGrading: boolean
    }>({ gradingSumValid: false, configureGrading: false })

    const { control, handleSubmit, setValue, watch, setError } =
      useForm<FormValuesWithExpiresAt>({
        resolver: zodResolver(schema),
        mode: 'onChange',
        defaultValues: {
          groupingType: AssignmentGroupingType.manual,
          groupingSize: 4,
          expiresAt: expiresAt ? DateTime.fromJSDate(expiresAt) : undefined,
        },
      })

    const setExpiresAtError = useCallback(() => {
      setError('expiresAt', {
        type: 'required',
        message: t('instructor_library.deadline_required'),
      })
    }, [setError, t])

    const onSubmit = useCallback(
      async (data: FormValuesWithExpiresAt) => {
        if (!expiresAt && !data.expiresAt) {
          setExpiresAtError()
          return
        }

        if (gradingState.configureGrading && !gradingState.gradingSumValid) {
          // halt
          return
        }

        // if both are set, and expiresAt is less than 24 hours from assignedAt, show error
        if (
          assignedAt &&
          data.expiresAt &&
          data.expiresAt.diff(assignedAt, 'hours').hours < 24
        ) {
          const min = assignedAt
            .plus({ days: 1 })
            .toLocaleString(DateTime.DATETIME_MED)
          setError('expiresAt', {
            type: 'min',
            message: t('instructor_assignment.deadline_must_be_after', { min }),
          })
          return
        }

        setIsSaving(true)
        onSaving?.(true)
        try {
          const scalars = scalarsRef.current
          await assignmentCubit.updateAssignment({
            expiresAt: data.expiresAt,
            assignedAt: assignedAt,
            groupingType: data.groupingType,
            groupingSize:
              data.groupingType === AssignmentGroupingType.automaticRandom
                ? data.groupingSize
                : 4,
            assignmentGradingScalars: scalars,
          })
          await assignmentCubit.markAsActive()
          await sectionCubit.markAsInProgress()
        } finally {
          setIsSaving(false)
          onSaving?.(false)
        }
      },
      [
        assignmentCubit,
        sectionCubit,
        expiresAt,
        setError,
        t,
        assignedAt,
        onSaving,
        scalarsRef,
        gradingState,
        setExpiresAtError,
      ]
    )

    const { groupingType } = watch()
    const expiresAtValue = watch('expiresAt')

    return (
      <div className="assignment flex min-h-screen w-full flex-col items-start justify-center">
        <div className="ml-[5vw] max-w-[400px]">
          <Header phase={phase} />
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-5"
          >
            <div className={phase === 'basic' ? '' : 'hidden'}>
              <Controller
                control={control}
                name="expiresAt"
                render={({ field, fieldState }) => {
                  return (
                    <BreakoutDateTimeInput
                      {...field}
                      error={fieldState.error}
                      type="datetime-local"
                      label={t('instructor_assignment.assignment_deadline')}
                      kind="secondary"
                      id="meeting-time"
                      hideNowButton
                      min={assignedAt?.plus({ days: 1, minutes: 1 })}
                      max={assignedAt?.plus({ days: 365 })}
                      className="mb-2"
                      // Set initial view to one day after assignedAt
                      initialView={assignedAt?.plus({ days: 1, minutes: 1 })}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )
                }}
              />

              <GroupingMethodInput
                assignmentCubit={assignmentCubit}
                control={control}
                setValue={setValue}
                expiresAt={expiresAtValue}
                groupingType={groupingType}
              />
            </div>

            <div className={phase === 'grading' ? '' : 'hidden'}>
              <GradingSection
                assignmentCubit={assignmentCubit}
                scalarsRef={scalarsRef}
                onChange={setGradingState}
              />
            </div>

            <Footer
              phase={phase}
              isSaving={isSaving}
              setPhase={setPhase}
              gradingState={gradingState}
              expiresAtValue={expiresAtValue}
              setExpiresAtError={setExpiresAtError}
            />
          </form>
        </div>
      </div>
    )
  }
)

function GroupingMethodInput({
  assignmentCubit,
  control,
  setValue,
  groupingType,
  expiresAt,
}: {
  assignmentCubit: ReturnType<typeof useInstructorAssignmentCubit>
  control:
    | Control<FormValues, unknown>
    | Control<FormValuesWithExpiresAt, unknown>
  setValue:
    | UseFormSetValue<FormValues>
    | UseFormSetValue<FormValuesWithExpiresAt>
  groupingType: AssignmentGroupingType
  expiresAt: DateTime | undefined
}) {
  const { t } = useTranslation()
  // react-hook-form typing is pretty bad, so we need to cast it
  // FormValues is enough to cover what we need in this component
  const safeControl = control as Control<FormValues, unknown>
  const safeSetValue = setValue as UseFormSetValue<FormValues>
  const assignment = assignmentCubit.assignment

  return (
    <>
      <Controller
        control={safeControl}
        name="groupingType"
        render={({ field, fieldState }) => {
          return (
            <BreakoutSelect
              {...field}
              onChange={(value) => {
                // if we switch to manual, we should clear the grouping size
                if (value === AssignmentGroupingType.manual) {
                  safeSetValue('groupingSize', undefined, {
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                }
                safeSetValue('groupingType', value, {
                  shouldValidate: true,
                  shouldDirty: true,
                })
              }}
              label={t('lti.configuration.grouping_method')}
              kind="secondary"
              options={[
                {
                  value: AssignmentGroupingType.manual,
                  label: t('lti.configuration.grouping_method_self_grouping'),
                },
                {
                  value: AssignmentGroupingType.automaticRandom,
                  label: t(
                    'lti.configuration.grouping_method_automatic_randomized_grouping'
                  ),
                },
              ]}
              error={fieldState.error}
            />
          )
        }}
      />
      {groupingType === AssignmentGroupingType.automaticRandom && (
        <Controller
          control={safeControl}
          name="groupingSize"
          render={({ field, fieldState }) => {
            return (
              <BreakoutTextInput
                {...field}
                error={fieldState.error}
                label={t('lti.configuration.grouping_size')}
                type="number"
              />
            )
          }}
        />
      )}

      <div className="mt-4">
        <GroupingMethodDetails
          groupingMethod={groupingType}
          expiresAt={expiresAt || null}
          assignedAt={
            assignment.assignedAt
              ? DateTime.fromJSDate(assignment.assignedAt)
              : null
          }
        />
      </div>
    </>
  )
}

function Footer({
  phase,
  isSaving,
  setPhase,
  gradingState,
  expiresAtValue,
  setExpiresAtError,
}: {
  phase: 'basic' | 'grading'
  isSaving: boolean
  setPhase: (phase: 'basic' | 'grading') => void
  expiresAtValue?: DateTime
  gradingState: {
    gradingSumValid: boolean
    configureGrading: boolean
  }
  setExpiresAtError: () => void
}) {
  const { t } = useTranslation()
  return (
    <>
      <div className={phase === 'basic' ? '' : 'hidden'}>
        <BreakoutButton
          kind="accent"
          type="button"
          size="medium"
          className="mb-4 bg-fixed-accent-color"
          loading={isSaving}
          onClick={() => {
            if (!expiresAtValue) {
              return setExpiresAtError()
            }
            setPhase('grading')
          }}
        >
          {t('lti.configuration.continue')}
        </BreakoutButton>
      </div>

      <div className={phase === 'grading' ? 'flex justify-between' : 'hidden'}>
        <BreakoutButton
          kind="secondary"
          type="button"
          size="medium"
          className="mb-4"
          onClick={(e) => {
            e.preventDefault()
            setPhase('basic')
          }}
        >
          {t('lti.configuration.back')}
        </BreakoutButton>
        <BreakoutButton
          kind="accent"
          type="submit"
          size="medium"
          className="mb-4"
          disabled={
            gradingState.configureGrading && !gradingState.gradingSumValid
          }
          loading={isSaving}
        >
          {t('lti.configuration.confirm')}
        </BreakoutButton>
      </div>
    </>
  )
}

function Header({ phase }: { phase: 'basic' | 'grading' }) {
  const { t } = useTranslation()
  const assignmentCubit = useInstructorAssignmentCubit()
  const slideDeck = assignmentCubit.slideDeck

  return (
    <>
      <div className="mb-5">
        {phase === 'basic' && (
          <>
            <h2 className="mb-1 text-title-large">
              {t('lti.configuration.step_1')}
            </h2>
            <h1 className="mb-1 text-headline-large">
              {t('lti.configuration.step_1_assignment_configuration')}
            </h1>
            <div className="text-body-large">
              {t(
                'lti.configuration.step_1_assignment_configuration_description'
              )}
            </div>
          </>
        )}
        {phase === 'grading' && (
          <>
            <h2 className="mb-1 text-title-large">
              {t('lti.configuration.step_2')}
            </h2>
            <h1 className="mb-1 text-headline-large">
              {t('lti.configuration.step_2_grading_configuration')}
            </h1>
            <div className="text-body-large">
              {t('lti.configuration.step_2_grading_configuration_description')}
            </div>
          </>
        )}
      </div>
      {phase === 'basic' && (
        <div className="mb-3 flex flex-row items-center gap-3 rounded-2xl bg-core-secondary px-2 py-3">
          <div className="pl-3">
            <img
              className="max-h-5 max-w-5"
              src={slideDeck.data.slideDeckImageURL}
              alt={slideDeck.data.slideDeckName}
            />
          </div>
          <div>
            <div className="text-body-medium text-on-surface-var">
              {slideDeck.data.slideDeckName}
            </div>
            <div className="text-label-large">
              {slideDeck.data.slideDeckTeaser}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
function GradingSection({
  assignmentCubit,
  scalarsRef,
  onChange,
}: {
  assignmentCubit: ReturnType<typeof useInstructorAssignmentCubit>
  scalarsRef: React.MutableRefObject<
    SectionAssignment['data']['assignmentGradingScalars']
  >
  onChange: (payload: {
    gradingSumValid: boolean
    configureGrading: boolean
  }) => void
}) {
  const { t } = useTranslation()
  const hasQuestions = assignmentCubit.questionsCollection.models.some(
    (q) => q.isGraded
  )
  const hasRubrics = assignmentCubit.slideRubrics.models.length > 0

  const [{ gradingSumValid, gradingSum }, setGradingSum] = useState<{
    gradingSumValid: boolean
    gradingSum: number
  }>({ gradingSumValid: false, gradingSum: 0 })

  const [configureGradingError, _setConfigureGradingError] =
    useState<boolean>(false)

  const [configureGrading, _setConfigureGrading] = useState(false)

  const setConfigureGrading = (b: boolean) => {
    _setConfigureGrading(b)
    _setConfigureGradingError(false)
  }

  const setGradingSumCallback = useCallback(
    ({ isValid, sumAsInt }: { isValid: boolean; sumAsInt: number }) => {
      setGradingSum({
        gradingSum: sumAsInt,
        gradingSumValid: isValid,
      })
    },
    []
  )

  useEffect(() => {
    // Trigger the callback when values change
    onChange({
      gradingSumValid,
      configureGrading,
    })
  }, [onChange, configureGrading, gradingSumValid])

  return (
    <>
      <div className="mb-4 w-full">
        <h3 className="mb-1 text-title-medium">
          {t('instructor_library.grade_your_students')}
        </h3>
        <>
          <div className="flex w-full flex-row gap-1">
            <BreakoutRadioButton
              checked={configureGrading === true}
              label={t('instructor_library.yes')}
              value={undefined}
              onChange={(e) => {
                setConfigureGrading(e.target.checked)
              }}
            />
            <BreakoutRadioButton
              checked={configureGrading === false}
              label={t('instructor_library.no')}
              value={undefined}
              onChange={(e) => {
                setConfigureGrading(!e.target.checked)
              }}
            />
          </div>
          <FormError
            error={
              configureGradingError
                ? {
                    message: t('instructor_library.grading_required'),
                    type: 'custom',
                  }
                : undefined
            }
          />
        </>
      </div>

      <div className="min-h-[330px] w-fit">
        <div
          className={classNames(
            'text-body-large text-on-surface-var',
            configureGrading && 'invisible h-0'
          )}
        >
          {t('lti.configuration.grading_disabled_explanation')}
        </div>

        <div className={configureGrading ? '' : 'invisible h-0'}>
          <ConfigureGradingScalars
            disableAll={false}
            hasQuizQuestions={hasQuestions}
            hasRubrics={hasRubrics}
            ref={scalarsRef}
            onChange={setGradingSumCallback}
          />
          <div className="flex flex-col gap-1 border-t border-outline-variant pt-4">
            <div className="flex flex-row justify-between">
              <strong className="text-title-medium">
                {t('instructor_library.total')}
              </strong>
              <div
                className={classNames(
                  'flex flex-row items-center gap-1 text-title-medium',
                  {
                    'text-core-error': !gradingSumValid,
                  }
                )}
              >
                {!gradingSumValid && <TriangleAlertIcon size={12.5} />}
                <strong>{gradingSum + '%'}</strong>
              </div>
            </div>
            <div className="flex flex-row items-center gap-1 text-label-medium text-on-surface-var">
              <InfoIcon size={15} />
              <span>
                {t('instructor_library.select_grading_weights_description')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
