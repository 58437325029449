import { useObjectUrlCacheManager } from 'hooks/objectUrl'
import { forwardRef, type ForwardedRef, useEffect, useState } from 'react'
import { captureException } from '@sentry/react'
import { useRepository } from 'hooks/auth'
import { observer } from 'mobx-react-lite'

type GoogleStorageVideoProps = {
  src?: string
  alt: string
  loader?: React.ReactNode
  namespace: string
  decoding?: 'sync' | 'async' | 'auto'
  disablePictureInPicture?: boolean
  disableRemotePlayback?: boolean
} & React.MediaHTMLAttributes<HTMLVideoElement>

const GoogleStorageNoObserver = forwardRef(function GoogleStorageVideo(
  { src, namespace, loader, ...rest }: GoogleStorageVideoProps,
  ref: ForwardedRef<HTMLVideoElement>
) {
  const { authToken } = useRepository()
  const objectUrlCache = useObjectUrlCacheManager()
  const initialObjectUrl = objectUrlCache.get(namespace, src)
  const [objectUrl, setObjectUrl] = useState<string | undefined>(
    initialObjectUrl
  )
  const [retries, setRetries] = useState(0)

  useEffect(() => {
    if (!src || !authToken) return
    if (objectUrl) return
    if (retries >= 3) return

    const options = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }

    setTimeout(
      () => {
        fetch(src, options)
          .then((res) => res.blob())
          .then((blob) => {
            const newObjectURL = URL.createObjectURL(blob)
            objectUrlCache.set(namespace, src, newObjectURL)
            setObjectUrl(newObjectURL)
          })
          .catch((err) => {
            captureException(err)
            if (retries < 3) {
              setRetries(retries + 1)
            } else {
              console.error('Failed to fetch image', err)
            }
          })
      },
      1000 * retries * 2 // exponential backoff - 0s, 2s, 4s, 8s
    )
  }, [authToken, src, objectUrlCache, namespace, objectUrl, retries])

  if (!objectUrl) return loader || null

  return <video ref={ref} src={objectUrl} {...rest} />
})

export const GoogleStorageVideo = observer(function GoogleStorageVideo(
  props: GoogleStorageVideoProps & { ref?: ForwardedRef<HTMLVideoElement> }
) {
  return <GoogleStorageNoObserver {...props} ref={props.ref} />
})
