import z from 'zod'
import {
  nullSafeOptionalBoolean,
  nullSafeOptionalFirestoreTimestamp,
} from '../shared/schema'

export const schema = z.object({
  assignmentId: z.string(),
  endedAt: nullSafeOptionalFirestoreTimestamp(),
  roomId: z.string(),
  sectionId: z.string(),
  startedAt: nullSafeOptionalFirestoreTimestamp(),
  success: nullSafeOptionalBoolean(false),
  userId: z.string().default(''),
})

export const empty = Object.freeze(
  schema.parse({
    success: false,
    endedAt: null,
    roomId: '',
    startedAt: null,
    assignmentId: '',
    sectionId: '',
    userId: '',
  })
)

// extends schema but ended at MUST be null and start at must not be defined
export const writeSchema = schema
  .extend({
    endedAt: z.null(),
  })
  .omit({
    success: true,
  })

export type FirestoreRoomStateReset = z.infer<typeof schema>
