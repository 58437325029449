import { forwardRef } from 'react'
import classNames from 'classnames'

type BreakoutRadioButtonProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  kind?: 'primary' | 'secondary' | 'tertiary'
  label: string
  disabled?: boolean
}

export const BreakoutRadioButton = forwardRef<
  HTMLInputElement,
  BreakoutRadioButtonProps
>(function BreakoutRadioButton(
  { label, disabled, className, kind, ...rest },
  ref
) {
  const kindOrDefault = kind || 'secondary'
  return (
    <label
      className={classNames(
        'flex h-[52px] flex-1 items-center gap-3 rounded-2xl px-4 py-1',
        {
          'bg-core-primary text-core-on-primary': kindOrDefault === 'primary',
          'bg-core-secondary text-core-on-secondary':
            kindOrDefault === 'secondary',
          'bg-core-tertiary text-core-on-tertiary':
            kindOrDefault === 'tertiary',
          'cursor-not-allowed': disabled,
          'cursor-pointer': !disabled,
          'opacity-50': disabled,
        },
        className
      )}
    >
      <input
        type="radio"
        className="peer hidden"
        disabled={disabled}
        {...rest}
        ref={ref}
      />

      {/* Unchecked */}
      <div className="relative flex h-[15px] w-[15px] min-w-[15px] items-center justify-center rounded-full border border-on-surface-disabled bg-transparent peer-checked:hidden" />

      {/* Checked */}
      <div className="relative hidden h-[15px] w-[15px] min-w-[15px] items-center justify-center rounded-full border border-fixed-accent-color bg-transparent peer-checked:flex">
        <div className="h-[11px] w-[11px] rounded-full bg-fixed-accent-color"></div>
      </div>

      <strong className="text-label-medium">{label}</strong>
    </label>
  )
})
