export function startSkipToMainContentWatcher() {
  const skipMainElement = document.getElementById('skip-main')
  if (!skipMainElement) return
  // We need to set it here because Sortsite is a POS
  skipMainElement.setAttribute('href', '#main')
  skipMainElement.addEventListener('click', () => {
    setTimeout(() => {
      // remove the hash from the URL
      history.replaceState(null, '', ' ')
    }, 16)
  })
  skipMainElement.addEventListener('keydown', (e) => {
    const mainDisabled = document.getElementById('main-disabled')
    if (e.key === 'Tab' && mainDisabled) {
      e.preventDefault()
      // if tab is pressed when main-disabled is present, force jump to main
      skipMainElement.click()

      return
    }
    if (e.key === 'Enter' || e.key === ' ') {
      setTimeout(() => {
        // remove the hash from the URL
        history.replaceState(null, '', ' ')
      }, 16)
    }
  })
}
