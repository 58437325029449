import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import { SlideDeckMaterialType } from '@breakoutlearning/firebase-repository/types'
import { Spinner } from 'components/Spinner'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { observer } from 'mobx-react-lite'
import { t } from 'i18next'
import { CourseMaterialListItem } from 'components/slide-deck-material-components/CourseMaterialListItem'

const MaterialsModalBody = observer(function MaterialsModalBody({
  cubit,
}: {
  cubit: StudentAssignmentCubit
}) {
  const {
    id,
    data: { slideDeckName },
  } = cubit.slideDeck
  const materialsWithoutFeatured = cubit.materials.models.filter(
    (material) =>
      material.data.materialType !== SlideDeckMaterialType.featuredLarge
  )
  return (
    <div className="mx-auto flex h-full flex-col !overflow-hidden">
      <h1 className="mb-1 text-center text-headline-large">
        {t('student_assignment.course_materials')}
      </h1>
      <p className="mb-5 text-center text-body-medium">
        {t('student_assignment.course_materials_info')}
      </p>
      {cubit.materials.isLoading ? (
        <div className="my-10 flex flex-row items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="h-full flex-grow overflow-auto">
          {materialsWithoutFeatured.map((material) => (
            <CourseMaterialListItem
              slideDeckVersion={cubit.slideDeck.data.slideDeckVersion}
              key={material.id}
              className="mb-2 flex h-[62px] flex-row items-center justify-between gap-3 rounded-2xl bg-light-grey p-5"
              slideDeckName={slideDeckName}
              slideDeckId={id}
              material={material}
              onViewTap={() => {
                cubit.logEvent('student_view_course_material', {
                  material_id: material.id,
                })
              }}
            />
          ))}
        </div>
      )}
    </div>
  )
})

export function MaterialsModal({ cubit }: { cubit: StudentAssignmentCubit }) {
  return (
    <Dialog size="md">
      <DialogCloseButton />
      <MaterialsModalBody cubit={cubit} />
    </Dialog>
  )
}
