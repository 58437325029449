import { LibraryObjectState } from '@breakoutlearning/firebase-repository/types'
import { DoubleCheckIcon } from 'components/icons/DoubleCheck'
import { useDialogs } from 'hooks/dialogs'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { ShowExperienceDialog } from './dialogs/ShowExperienceDialog'
import { SummaryDialog } from './dialogs/SummaryDialog'
import { useWalkthroughDialog } from './dialogs/WalkthroughDialog.hooks'
import { useStudentAssignmentCubit } from 'hooks/cubits/studentAssignment'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { XSmallIcon } from 'components/icons/XSmall'
import { MeetingResultsDialog } from './dialogs/MeetingResultsDialog'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { useMemo } from 'react'
import { ValidLibraryObjectActionState } from '@breakoutlearning/firebase-repository/stores/ValidLibraryObject'
import { RoomStateStatus } from '@breakoutlearning/firebase-repository/models/RoomState'

const ExpiredView = observer(function CompletedView() {
  const { t } = useTranslation()
  return (
    <div className={classNames(`mt-1 flex-grow-0 py-5 text-center`)}>
      <div className=" text-body-medium text-grey-text">
        {t('dart.experience')}
      </div>
      <h3 className="mt-3 text-title-large">
        <XSmallIcon
          size={20}
          className="mr-1 inline-block align-top text-breakout-dark-green"
        />
        {t('dart.assignment_expired')}
      </h3>
    </div>
  )
})

const CompletedView = observer(function CompletedView() {
  const cubit = useStudentAssignmentCubit()
  const { t } = useTranslation()
  const { showDialog } = useDialogs()

  const roomCompleted = useMemo(
    () =>
      cubit.roomState.getRoomStateStatus(
        cubit.assignment.expiresAt,
        cubit.section.data.sectionState,
        cubit.slideDeck.data.slideDeckSlideCount,
        cubit.assignment.data.assignmentState
      ) === RoomStateStatus.completed,
    [
      cubit.roomState,
      cubit.assignment.expiresAt,
      cubit.section.data.sectionState,
      cubit.slideDeck.data.slideDeckSlideCount,
      cubit.assignment.data.assignmentState,
    ]
  )

  const summary = cubit.roomStateSummary
  const hasRoomStartedAt = cubit.roomState.data.roomStartedAt !== undefined
  const newMeetingResultsEnabled =
    cubit.repository.featureFlags.data.sessionResultsUseNew
  const canShowNewResults =
    roomCompleted &&
    hasRoomStartedAt &&
    newMeetingResultsEnabled &&
    Boolean(cubit.roomStateId)

  return (
    <div className={classNames(`mt-1 flex-grow-0 py-5 text-center`)}>
      <div className=" text-body-medium text-grey-text">
        {t('dart.experience')}
      </div>
      <h3 className="mt-3 text-title-large">
        <DoubleCheckIcon
          size={20}
          className="mr-1 inline-block align-top text-breakout-dark-green"
        />
        {t('dart.completed')}
      </h3>
      <div className="mt-6">
        <div className="flex flex-row justify-center">
          <BreakoutButton
            onClick={() => {
              showDialog(() => (
                <ShowExperienceDialog
                  slideDeckId={cubit.libraryObject.slideDeckId}
                />
              ))
            }}
            className="mr-1 inline-block whitespace-nowrap rounded-2xl bg-core-secondary px-6 py-2 text-body-large text-core-on-tertiary"
          >
            {t('student_assignment.view_experience')}
          </BreakoutButton>
          {(summary?.hasData || canShowNewResults) && (
            <BreakoutButton
              onClick={() => {
                showDialog(() => {
                  if (canShowNewResults)
                    return <MeetingResultsDialog roomId={cubit.roomStateId!} />

                  return (
                    <SummaryDialog
                      summary={summary.data}
                      roomStateName={cubit.roomState.roomStateName}
                      roomStateActiveSlideChangedAt={
                        cubit.roomState.data.activeSlideChangedAt || null
                      }
                    />
                  )
                })
              }}
              className="ml-1 inline-block whitespace-nowrap rounded-2xl bg-core-secondary px-6 py-2 text-body-large text-core-on-tertiary"
            >
              {t('student_assignment.view_summary')}
            </BreakoutButton>
          )}
        </div>
      </div>
    </div>
  )
})

export const StudentAssignmentStep3 = observer(function StudentAssignmentStep3({
  showStep2Title,
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  showStep2Title: boolean
}) {
  const cubit = useStudentAssignmentCubit()

  const { t } = useTranslation()
  const { showWalkthroughDialogIfNecessary } = useWalkthroughDialog({
    roomId: cubit.roomStateId,
    roomStateStatus: cubit.libraryObject.roomStateStatus,
  })

  const { actionState, libraryObjectState, canJoinTimeFormatted } =
    cubit.libraryObject

  if (libraryObjectState === LibraryObjectState.completed) {
    return <CompletedView />
  }

  if (libraryObjectState === LibraryObjectState.expired) {
    return <ExpiredView />
  }

  const isSuspended =
    cubit.libraryObject.roomStateStatus === RoomStateStatus.suspended
  const startable = actionState === ValidLibraryObjectActionState.startSession

  const joinable =
    actionState === ValidLibraryObjectActionState.joinSession ||
    (isSuspended && cubit.roomState.canStart)

  const showDisabledJoinButton = !startable && !joinable

  const buttonAndTitleText = (() => {
    if (isSuspended) return t('student_assignment.continue_session')
    if (startable) return t('student_assignment.start_session')
    return t('student_assignment.join_session')
  })()

  const fadeOut =
    !cubit.allPreWorkQuestionsAnswered ||
    !cubit.isReady ||
    showDisabledJoinButton

  const tooltip = cubit.roomState.isScheduled
    ? t('student_assignment.join_disabled_starts_at', {
        startsAt: canJoinTimeFormatted,
      })
    : t('student_assignment.join_disabled_please_schedule')

  return (
    <div
      className={classNames(className, fadeOut ? 'opacity-30' : '')}
      {...props}
    >
      <div className="text-body-medium text-grey-text">
        {showStep2Title
          ? t('student_assignment.step_2')
          : t('student_assignment.step_3')}
      </div>
      <h3 className="mt-3 text-title-large">{buttonAndTitleText}</h3>
      <p className="mt-1 text-body-medium">
        {t('dart.join_session_description')}
      </p>
      <div className="mt-3">
        {showDisabledJoinButton && (
          <BreakoutTooltip content={tooltip}>
            <div>
              <BreakoutButton disabled>{buttonAndTitleText}</BreakoutButton>
            </div>
          </BreakoutTooltip>
        )}
        {(joinable || startable) && (
          <BreakoutButton
            data-testid="join-session-button"
            disabled={!cubit.roomState.isScheduled || !cubit.roomStateId}
            onClick={async () => {
              if (!cubit.allPreWorkQuestionsAnswered) return
              if (!cubit.roomState.isScheduled) return

              showWalkthroughDialogIfNecessary(() => {
                cubit.logEvent('room_join')
                cubit.setJoiningSession()
              })
            }}
            className="inline-block w-40 rounded-2xl bg-core-primary px-8 py-2 text-label-large text-core-on-primary disabled:opacity-50"
          >
            {buttonAndTitleText}
          </BreakoutButton>
        )}
      </div>
    </div>
  )
})
