import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
  PartialWithFieldValue,
} from 'firebase/firestore'
import {
  collection,
  deleteField,
  doc,
  query,
  serverTimestamp,
  where,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { type DateTime } from 'luxon'
import type { StreamInterface } from 'tricklejs/dist/types'
import {
  ObservableModelCollection,
  ObservableModelDocument,
  StaticModelCollection,
  StaticModelDocument,
  type ObservableModel,
} from '../../firestore-mobx/model'
import {
  convertDocumentSnapshotToModel,
  modelItemStream,
  modelListStream,
} from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import {
  type AssignmentGroupingType,
  type AssignmentType,
} from '../../models/SectionAssignment'
import { SectionAssignment } from '../../models/SectionAssignment'
import { SectionState } from '../../types'
import { setSectionState, touchSection } from '../Section'
import type {
  draftWriteSchema,
  FirestoreSectionAssignment,
  FirestoreSectionAssignmentWrite,
} from './schema'
import { AssignmentState, empty, schema, writeSchema } from './schema'
import {
  addDocWithError,
  deleteDocWithError,
  getDocsWithError,
  getDocWithError,
  runTransactionWithError,
  updateDocWithError,
} from '../../firestore-mobx/fetch'
import { type z } from 'zod'
import { RoomState } from '../../models/RoomState'

export * from './schema'

export interface SectionAssignmentObservableModel
  extends ObservableModel<FirestoreSectionAssignment> {}

export interface SectionAssignmentObservableModelCollection
  extends ObservableModelCollection<
    SectionAssignmentObservableModel,
    FirestoreSectionAssignment
  > {}

const converter: FirestoreDataConverter<FirestoreSectionAssignment> = {
  toFirestore: (data: PartialWithFieldValue<FirestoreSectionAssignment>) => {
    writeSchema.partial().parse(data)

    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore,
  { sectionId }: { sectionId: string }
): CollectionReference<FirestoreSectionAssignment> => {
  return collection(
    firestore,
    'section',
    sectionId,
    'assignment'
  ).withConverter(converter)
}

const getDocRef = (
  firestore: Firestore,
  { sectionId, assignmentId }: { sectionId: string; assignmentId: string }
): DocumentReference<FirestoreSectionAssignment, DocumentData> => {
  return doc(
    firestore,
    'section',
    sectionId,
    'assignment',
    assignmentId
  ).withConverter(converter)
}

export const getSectionAssignments = (
  repository: FirebaseRepository,
  params: { sectionId: string; onlyPublished?: boolean }
): StreamInterface<SectionAssignment[]> => {
  const ref = getColRef(repository.firestore, params)

  if (params.onlyPublished === true)
    return modelListStream(
      repository,
      query(ref, where('assignmentState', '>', 0)),
      SectionAssignment
    )

  return modelListStream(repository, ref, SectionAssignment)
}

export const fetchSectionAssignments = async (
  repository: FirebaseRepository,
  params: { sectionId: string; onlyPublished?: boolean }
): Promise<SectionAssignment[]> => {
  const ref = getColRef(repository.firestore, params)

  const q =
    params.onlyPublished === true
      ? query(ref, where('assignmentState', '>', 0))
      : query(ref)

  const snapshot = await getDocsWithError(q)
  return snapshot.docs.map((doc) =>
    convertDocumentSnapshotToModel(repository, doc, SectionAssignment)
  )
}

export const getSectionAssignment = (
  repository: FirebaseRepository,
  params: { sectionId: string; assignmentId: string }
): StreamInterface<SectionAssignment> => {
  const ref = getDocRef(repository.firestore, params)

  return modelItemStream(repository, ref, SectionAssignment)
}

export const fetchSectionAssignment = async (
  repository: FirebaseRepository,
  params: { sectionId: string; assignmentId: string }
) => {
  const ref = getDocRef(repository.firestore, params)
  const doc = await getDocWithError(ref, 'FetchSectionAssignmentError')
  return convertDocumentSnapshotToModel(repository, doc, SectionAssignment)
}

// deprecated

export const buildEmptySectionAssignmentModel = (
  repository: FirebaseRepository
) => {
  return new StaticModelDocument({
    repository,
    model: SectionAssignment,
    empty: empty,
  }).model
}

export const buildEmptySectionAssignmentCollection = (
  repository: FirebaseRepository
) => {
  return new StaticModelCollection({
    repository,
    model: SectionAssignment,
    empty: empty,
  })
}

export const buildSectionAssignmentObservableModelDocument = (
  repository: FirebaseRepository,
  { sectionId, assignmentId }: { sectionId: string; assignmentId: string }
) => {
  const ref = getDocRef(repository.firestore, {
    sectionId: sectionId,
    assignmentId: assignmentId,
  })

  return new ObservableModelDocument({
    ref,
    repository,
    model: SectionAssignment,
    empty: empty,
  })
}

export const buildSectionAssignmentObservableModelCollection = (
  repository: FirebaseRepository,
  { sectionId }: { sectionId: string }
) => {
  const ref = getColRef(repository.firestore, {
    sectionId: sectionId,
  })
  const q = query(ref)

  return new ObservableModelCollection({
    ref,
    repository,
    model: SectionAssignment,
    query: () => q,
    empty: empty,
  })
}

export const updateSectionAssignment: (
  repository: FirebaseRepository,
  params: {
    sectionId: string
    assignmentId: string
    expiresAt?: DateTime
    assignedAt?: DateTime
    groupingType?: AssignmentGroupingType
    groupingSize?: number
    publish?: boolean
    assignmentGradingScalars?: FirestoreSectionAssignment['assignmentGradingScalars']
  }
) => Promise<void> = async (
  repository,
  {
    sectionId,
    assignmentId,
    expiresAt,
    assignedAt,
    groupingSize,
    assignmentGradingScalars,
    ...rest
  }
) => {
  const ref = getDocRef(repository.firestore, { sectionId, assignmentId })

  await updateDocWithError(
    ref,
    {
      ...rest,
      expiresAt: expiresAt ? expiresAt.toJSDate() : deleteField(),
      assignedAt: assignedAt ? assignedAt.toJSDate() : deleteField(),
      assignmentGradingScalars: assignmentGradingScalars ?? deleteField(),
      updatedAt: serverTimestamp(),
      groupingSize: groupingSize,
      groupingSizeMinimum: groupingSize ? 2 : undefined,
      groupingSizeMaximum: groupingSize ? RoomState.maxAllowedUsers : undefined,
    },
    'UpdateSectionAssignmentExpirationAndAssignedAtError'
  )
  await touchSection(repository, sectionId)
}

export const updateSectionAssignmentGrouping: (
  repository: FirebaseRepository,
  params: {
    sectionId: string
    assignmentId: string
    groupingType: AssignmentGroupingType
    groupingSize?: number
  }
) => Promise<void> = async (
  repository,
  { sectionId, assignmentId, groupingType, groupingSize }
) => {
  const ref = getDocRef(repository.firestore, { sectionId, assignmentId })

  await updateDocWithError(
    ref,
    {
      groupingSize,
      groupingType,
      groupingSizeMaximum: 8,
      groupingSizeMinimum: 2,
      updatedAt: serverTimestamp(),
    },
    'updateSectionAssignmentGroupingTypeError'
  )
  await touchSection(repository, sectionId)
}

export const updateSectionAssignmentGradingScalars: (
  repository: FirebaseRepository,
  params: {
    sectionId: string
    assignmentId: string
    assignmentGradingScalars?: FirestoreSectionAssignment['assignmentGradingScalars']
  }
) => Promise<void> = async (
  repository,
  { sectionId, assignmentId, assignmentGradingScalars }
) => {
  const ref = getDocRef(repository.firestore, { sectionId, assignmentId })

  await updateDocWithError(
    ref,
    {
      assignmentGradingScalars,
      updatedAt: serverTimestamp(),
    },
    'updateSectionAssignmentGroupingTypeError'
  )
  await touchSection(repository, sectionId)
}

export const updateSectionAssignmentState: (
  repository: FirebaseRepository,
  params: {
    sectionId: string
    assignmentId: string
    state: AssignmentState
  }
) => Promise<void> = async (repository, { sectionId, assignmentId, state }) => {
  const sectionRef = doc(repository.firestore, 'section', sectionId)
  const assignmentRef = getDocRef(repository.firestore, {
    sectionId,
    assignmentId,
  })

  await runTransactionWithError(
    repository.firestore,
    async (transaction) => {
      const doc = await transaction.get(assignmentRef)

      if (!doc.exists) {
        throw new Error('SectionAssignmentNotFound')
      }

      // update assignment state
      transaction.update(assignmentRef, {
        assignmentState: state,
        updatedAt: serverTimestamp(),
      })

      // update section (set state in progress if publishing)
      transaction.update(sectionRef, {
        sectionState:
          state === AssignmentState.active
            ? SectionState.inProgress
            : undefined,
        updatedAt: serverTimestamp(),
      })
    },
    undefined,
    'UpdateSectionAssignmentStateError'
  )
}

export const sendTestInstructorEmail: (
  repository: FirebaseRepository,
  params: { sectionId: string; assignmentId: string }
) => Promise<void> = async (repository, { sectionId, assignmentId }) => {
  const col = collection(
    repository.firestore,
    'section',
    sectionId,
    'assignment',
    assignmentId,
    'email'
  )

  // didn't bother instantiating a model for this since it's a one off
  await addDocWithError(
    col,
    {
      assignmentId: assignmentId,
      emailAddress: repository.currentUser?.email,
      sectionId: sectionId,
      startedAt: serverTimestamp(),
    },
    'SendTestInstructorEmailError'
  )
}

export async function createDraftAssignment(
  repository: FirebaseRepository,
  {
    slideDeckId,
    sectionId,
    assignmentType,
    catalogId,
    assignedAt,
    expiresAt,
    groupingType,
    groupingSize,
    assignmentGradingScalars,
  }: {
    slideDeckId: string
    sectionId: string
    assignmentType: AssignmentType
    catalogId?: string
    assignedAt?: DateTime
    expiresAt?: DateTime
    groupingType: AssignmentGroupingType
    groupingSize?: number
    assignmentGradingScalars?: FirestoreSectionAssignment['assignmentGradingScalars']
  }
) {
  const ref = getColRef(repository.firestore, { sectionId })

  const data: z.infer<typeof draftWriteSchema> = {
    assignmentType: assignmentType,
    assignmentState: AssignmentState.draft,
    groupingType,
    slideDeckId,
    sectionId,
    updatedAt: serverTimestamp(),
    assignmentGradingScalars: assignmentGradingScalars,
  }

  if (assignedAt) data.assignedAt = assignedAt.toJSDate()
  if (expiresAt) data.expiresAt = expiresAt.toJSDate()
  if (catalogId) data.catalogId = catalogId
  if (groupingSize) {
    data.groupingSize = groupingSize
    data.groupingSizeMinimum = 2
    data.groupingSizeMaximum = RoomState.maxAllowedUsers
  }

  const docRef = await addDocWithError(ref, data, 'CreateDraftAssignmentError')
  return docRef.id
}

export async function createSectionAssignment(
  repository: FirebaseRepository,
  params: {
    sectionId: string
    assignmentType: AssignmentType
    assignedAt: DateTime
    expiresAt: DateTime
    groupingType: AssignmentGroupingType
    slideDeckId: string
    catalogId?: string
    groupingSize?: number
    assignmentGradingScalars?: FirestoreSectionAssignment['assignmentGradingScalars']
  }
) {
  const ref = getColRef(repository.firestore, {
    sectionId: params.sectionId,
  })

  const data: FirestoreSectionAssignmentWrite = {
    assignedAt: params.assignedAt.toJSDate(),
    assignmentType: params.assignmentType,
    assignmentState: AssignmentState.active,
    expiresAt: params.expiresAt.toJSDate(),
    groupingType: params.groupingType,
    slideDeckId: params.slideDeckId,
    sectionId: params.sectionId,
    updatedAt: serverTimestamp(),
    assignmentGradingScalars: params.assignmentGradingScalars,
  }

  if (params.groupingSize) {
    data.groupingSize = params.groupingSize
    data.groupingSizeMinimum = 2
    data.groupingSizeMaximum = RoomState.maxAllowedUsers
  }

  if (params.catalogId) {
    data.catalogId = params.catalogId
  }

  const docRef = await addDocWithError(
    ref,
    data,
    'CreateSectionAssignmentError'
  )

  await setSectionState(repository, {
    sectionId: params.sectionId,
    sectionState: SectionState.inProgress,
  })

  return docRef.id
}

export async function deleteSectionAssignment(
  repository: FirebaseRepository,
  params: { sectionId: string; assignmentId: string }
) {
  const ref = getDocRef(repository.firestore, params)

  await deleteDocWithError(ref, 'DeleteSectionAssignmentError')
}
