import { when } from 'mobx'
import type { FirestoreSlide } from '../firestore/Slide'
import { touchSlide } from '../firestore/Slide'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import {
  SlideDeckType,
  SlideType,
  SlideDeckState,
  SlideDeckMaterialType,
  MediaType,
} from '../types'
import { noTryAsync } from './no-try'
import type { FirestoreSlideDeck } from '../firestore/SlideDeck/schema'
import type { FirestoreSlideDeckMaterial } from '../firestore/SlideDeckMaterial/schema'
import type { FirestoreSlideDeckAuthor } from '../firestore/SlideDeckAuthor/schema'
import type { FirestoreSlideDeckExhibit } from '../firestore/SlideDeckExhibit'
import type { Media } from '../models/Media'
import type en from '../../../../apps/web/src/i18n/locale/en.json'

/**
 * Context object containing all necessary data for slide deck validation
 */
interface ValidationContext {
  /** The slide deck being validated */
  slideDeck: FirestoreSlideDeck
  /** All slides in the deck */
  slides: FirestoreSlide[]
  /** All materials (attachments) associated with the deck */
  materials: FirestoreSlideDeckMaterial[]
  /** Authors of the slide deck */
  authors: FirestoreSlideDeckAuthor[]
  /** Exhibits (images/figures) used in the deck */
  exhibits: FirestoreSlideDeckExhibit[]
  /** Unique identifier of the slide deck */
  slideDeckId: string
  /** The id of the first slide if one is present. It is used when we need to recalculate the slide count (by updating the updatedAt of one of the slides) */
  firstSlideId?: string
  // all the mediaObjects
  mediaObjects: {
    [k: string]: Media
  }
}

type AdminDetailKey = keyof (typeof en)['admin_details']

type ValidErrorKeys = {
  [K in string]: AdminDetailKey
}

export const ErrorKeys = {
  EXPERIENCE_MUST_HAVE_TITLE: 'experience_must_have_title',
  EXPERIENCE_MUST_HAVE_SUBTITLE: 'experience_must_have_subtitle',
  EXPERIENCE_MUST_HAVE_DESCRIPTION: 'experience_must_have_description',
  EXPERIENCE_MUST_HAVE_SLIDE_DECK_LOGO: 'experience_must_have_slide_deck_logo',
  EXPERIENCE_MUST_HAVE_VERSION: 'experience_must_have_version',
  EXPERIENCE_MUST_HAVE_LEARNING_OBJECTIVE:
    'experience_must_have_learning_objective',
  EXPERIENCE_MUST_HAVE_DISCIPLINE: 'experience_must_have_discipline',
  EXPERIENCE_SLIDE_DECK_DELETED_OR_HIDDEN:
    'experience_slide_deck_deleted_or_hidden',

  EXPERIENCE_MUST_HAVE_ONE_SLIDE: 'experience_must_have_one_slide',
  EXPERIENCE_MUST_HAVE_AUTHOR: 'experience_must_have_author',
  EXPERIENCE_AUTHOR_WITHOUT_IMAGE: 'experience_author_without_image',
  EXPERIENCE_MUST_HAVE_TRAILER: 'experience_must_have_trailer',
  EXPERIENCE_MUST_HAVE_TRAILER_THUMBNAIL:
    'experience_must_have_trailer_thumbnail',
  EXPERIENCE_SLIDE_COUNT_MISMATCH: 'experience_slide_count_mismatch',

  EXPERIENCE_SLIDE_END_OF_SESSION_QUIZ_NOT_LAST_SLIDE:
    'experience_slide_end_of_session_quiz_not_last_slide',

  // Options: { slideName: string }
  SLIDE_MISSING_IMAGE_ALT_TEXT: 'slide_missing_image_alt_text',
  SLIDE_MISSING_IMAGE_URL: 'slide_missing_image_url',
  SLIDE_MISSING_VIDEO_URL: 'slide_missing_video_url',
  SLIDE_MISSING_VIDEO_DURATION: 'slide_missing_video_duration',
  SLIDE_VIDEO_ERROR: 'slide_video_error',
  SLIDE_MISSING_QUESTION_TEXT: 'slide_missing_question_text',
  SLIDE_MISSING_SLIDE_NAME: 'slide_missing_slide_name',
  SLIDE_MISSING_SLIDE_DESCRIPTION: 'slide_missing_slide_description',
  SLIDE_MISSING_FEEDBACK_TEXT: 'slide_missing_feedback_text',
  SLIDE_MISSING_DISCUSSION_PROMPT: 'slide_missing_discussion_prompt',
  SLIDE_MISSING_AUDIO_URL: 'slide_missing_audio_url',

  // global media errors
  GLOBAL_MEDIA_MISSING: 'global_media_missing',
  GLOBAL_MEDIA_WRONG_TYPE: 'global_media_wrong_type',
  GLOBAL_MEDIA_VIDEO_ERROR: 'global_media_video_error',
  GLOBAL_MEDIA_MISSING_VIDEO_DURATION: 'global_media_missing_video_duration',

  // Options: { exhibitName: string }
  EXHIBIT_MISSING_IMAGE_ALT_TEXT: 'exhibit_missing_image_alt_text',
  EXHIBIT_MISSING_IMAGE_URL: 'exhibit_missing_image_url',
  EXHIBIT_MISSING_SLIDE_ID: 'exhibit_missing_slide_id',

  // Options: { materialName: string }
  MATERIAL_MISSING_LINK: 'material_missing_link',
  MATERIAL_MISSING_IMAGE_URL: 'material_missing_image_url',
} as const satisfies ValidErrorKeys

type ErrorKeysType = typeof ErrorKeys
type ErrorKey = ErrorKeysType[keyof ErrorKeysType]

/**
 * Represents a validation error with a key and optional parameters
 */
interface ValidationError {
  /** Unique identifier for the error type */
  key: ErrorKey
  /** Optional parameters to provide context for the error message */
  options?: Record<string, string>
}

/**
 * Validates a slide deck and returns an array of validation errors
 * @param context - All required data for validation
 * @param repository - Firebase repository instance for async operations
 * @returns Array of validation errors, empty if validation passes
 */
export const validateSlideDeck = async (
  context: ValidationContext,
  repository: FirebaseRepository,
  touchSlideTimeout?: number
): Promise<ValidationError[]> => {
  const errors: ValidationError[] = [
    ...validateGeneralDetails(context),
    ...validateSlides(context),
    ...validateAuthors(context),
    ...validateExhibits(context),
    ...validateTypeSpecificRules(context),
    ...validateMaterials(context),
    ...validateEndOfSessionQuiz(context),
  ]

  // Only run async validation if no other errors
  if (errors.length === 0) {
    const slideCountError = await validateSlideCount({
      repository: repository,
      slideDeckId: context.slideDeckId,
      slides: context.slides,
      slideDeck: context.slideDeck,
      firstSlideId: context.firstSlideId,
      timeout: touchSlideTimeout,
    })

    if (slideCountError) {
      errors.push(slideCountError)
    }
  }

  return errors
}

// Helper functions for different validation aspects
const validateGeneralDetails = ({
  slideDeck,
}: ValidationContext): ValidationError[] => {
  const errors: ValidationError[] = []

  if (!slideDeck.slideDeckName) {
    errors.push({ key: ErrorKeys.EXPERIENCE_MUST_HAVE_TITLE })
  }
  if (!slideDeck.slideDeckTeaser) {
    errors.push({ key: ErrorKeys.EXPERIENCE_MUST_HAVE_SUBTITLE })
  }
  if (!slideDeck.slideDeckDescription) {
    errors.push({ key: ErrorKeys.EXPERIENCE_MUST_HAVE_DESCRIPTION })
  }
  if (!slideDeck.slideDeckImageURL) {
    errors.push({ key: ErrorKeys.EXPERIENCE_MUST_HAVE_SLIDE_DECK_LOGO })
  }
  if (!slideDeck.slideDeckVersion && slideDeck.slideDeckVersion !== '0.0.0') {
    errors.push({ key: ErrorKeys.EXPERIENCE_MUST_HAVE_VERSION })
  }
  if (!slideDeck.slideDeckLearningObjectives.length) {
    errors.push({ key: ErrorKeys.EXPERIENCE_MUST_HAVE_LEARNING_OBJECTIVE })
  }
  if (!slideDeck.slideDeckDisciplines.length) {
    errors.push({ key: ErrorKeys.EXPERIENCE_MUST_HAVE_DISCIPLINE })
  }
  if (
    [SlideDeckState.deleted, SlideDeckState.hidden].includes(
      slideDeck.slideDeckState
    )
  ) {
    errors.push({ key: ErrorKeys.EXPERIENCE_SLIDE_DECK_DELETED_OR_HIDDEN })
  }

  return errors
}

const validateSlides = ({
  mediaObjects,
  slides,
}: ValidationContext): ValidationError[] => {
  const errors: ValidationError[] = []

  if (slides.length === 0) {
    errors.push({ key: ErrorKeys.EXPERIENCE_MUST_HAVE_ONE_SLIDE })
    return errors
  }

  slides.forEach((slide) => {
    errors.push(...validateSlideByType(mediaObjects, slide))
  })

  return errors
}

const validateSlideByType = (
  mediaObjects: {
    [k: string]: Media
  },
  slide: FirestoreSlide
): ValidationError[] => {
  const errors: ValidationError[] = []
  const options = { slideName: slide.slideName }

  if (!slide.slideName) {
    errors.push({ key: ErrorKeys.SLIDE_MISSING_SLIDE_NAME })
  }
  if (!slide.slideDescription) {
    errors.push({ key: ErrorKeys.SLIDE_MISSING_SLIDE_DESCRIPTION })
  }

  switch (slide.slideType) {
    case SlideType.exhibitGrid:
      // Potential future validation: ensure that at least one exhibit is present.
      break
    case SlideType.groupQuiz:
    case SlideType.interactivePoll:
    case SlideType.soloQuiz:
    case SlideType.interactiveQuiz:
      // Potential future validation: validate that there is at least one interactive element.
      break
    case SlideType.sessionResults:
      // Potential future validation: must come after processing data.
      break
    case SlideType.processingData:
      // Potential future validation: must come before session results.
      break
    case SlideType.professorFeedback:
    case SlideType.discussion:
      if (slide.slideImageURL && !slide.slideImageAltText) {
        errors.push({
          key: ErrorKeys.SLIDE_MISSING_IMAGE_ALT_TEXT,
          options,
        })
      }
      break
    case SlideType.image:
      if (!slide.slideImageURL) {
        errors.push({ key: ErrorKeys.SLIDE_MISSING_IMAGE_URL, options })
      }
      if (!slide.slideImageAltText) {
        errors.push({
          key: ErrorKeys.SLIDE_MISSING_IMAGE_ALT_TEXT,
          options,
        })
      }
      break
    case SlideType.video:
      if (!slide.slideVideoURL) {
        errors.push({ key: ErrorKeys.SLIDE_MISSING_VIDEO_URL, options })
      }
      if (slide.mediaId) {
        if (!mediaObjects[slide.mediaId]) {
          errors.push({ key: ErrorKeys.GLOBAL_MEDIA_MISSING, options })
        } else if (
          mediaObjects[slide.mediaId].data.mediaType !== MediaType.Video
        ) {
          errors.push({ key: ErrorKeys.GLOBAL_MEDIA_WRONG_TYPE, options })
        } else if (mediaObjects[slide.mediaId].data.mediaVideoError) {
          errors.push({ key: ErrorKeys.GLOBAL_MEDIA_VIDEO_ERROR, options })
        } else if (
          typeof mediaObjects[slide.mediaId].data.mediaVideoDuration !==
          'number'
        ) {
          errors.push({
            key: ErrorKeys.GLOBAL_MEDIA_MISSING_VIDEO_DURATION,
            options,
          })
        }
      } else if (typeof slide.slideVideoDuration !== 'number') {
        errors.push({ key: ErrorKeys.SLIDE_MISSING_VIDEO_DURATION, options })
      }
      if (slide.slideVideoError) {
        errors.push({ key: ErrorKeys.SLIDE_VIDEO_ERROR, options })
      }
      break
  }

  return errors
}

const validateAuthors = ({
  slideDeck,
  authors,
}: ValidationContext): ValidationError[] => {
  const errors: ValidationError[] = []

  if (
    slideDeck.slideDeckType !== SlideDeckType.dialog &&
    authors.length === 0
  ) {
    errors.push({ key: ErrorKeys.EXPERIENCE_MUST_HAVE_AUTHOR })
  }

  authors.forEach((author) => {
    if (!author.authorImageURL) {
      errors.push({ key: ErrorKeys.EXPERIENCE_AUTHOR_WITHOUT_IMAGE })
    }
  })

  return errors
}

const validateExhibits = ({
  exhibits,
}: ValidationContext): ValidationError[] => {
  const errors: ValidationError[] = []

  exhibits.forEach((exhibit) => {
    if (!exhibit.exhibitAltText) {
      errors.push({
        key: ErrorKeys.EXHIBIT_MISSING_IMAGE_ALT_TEXT,
        options: { exhibitName: exhibit.exhibitName },
      })
    }
    if (!exhibit.exhibitURL) {
      errors.push({
        key: ErrorKeys.EXHIBIT_MISSING_IMAGE_URL,
        options: { exhibitName: exhibit.exhibitName },
      })
    }

    if (!exhibit.slideId) {
      errors.push({
        key: ErrorKeys.EXHIBIT_MISSING_SLIDE_ID,
        options: { exhibitName: exhibit.exhibitName },
      })
    }

    // Potential future validation: ensure that the slideId is present.
  })

  return errors
}

const validateTypeSpecificRules = ({
  slideDeck,
  materials,
}: ValidationContext): ValidationError[] => {
  const errors: ValidationError[] = []

  if (slideDeck.slideDeckType === SlideDeckType.original) {
    const hasTrailer = materials.some(
      (material) =>
        material.materialType === SlideDeckMaterialType.mp4 &&
        material.materialLink &&
        material.materialName === 'Trailer'
    )
    if (!hasTrailer) {
      errors.push({ key: ErrorKeys.EXPERIENCE_MUST_HAVE_TRAILER })
    }

    const hasTrailerThumbnail = materials.some(
      (material) =>
        material.materialType === SlideDeckMaterialType.jpg &&
        material.materialLink &&
        material.materialName === 'Trailer Thumbnail'
    )
    if (!hasTrailerThumbnail) {
      errors.push({ key: ErrorKeys.EXPERIENCE_MUST_HAVE_TRAILER_THUMBNAIL })
    }
  }

  return errors
}

const validateMaterials = ({
  materials,
}: ValidationContext): ValidationError[] => {
  const errors: ValidationError[] = []

  materials.forEach((material) => {
    const options = { materialName: material.materialName }

    switch (material.materialType) {
      case SlideDeckMaterialType.url:
        if (!material.imageUrl) {
          errors.push({
            key: ErrorKeys.MATERIAL_MISSING_IMAGE_URL,
            options,
          })
        }
        break
      case SlideDeckMaterialType.pdf:
      case SlideDeckMaterialType.mp3:
      case SlideDeckMaterialType.webm:
      case SlideDeckMaterialType.jpg:
      case SlideDeckMaterialType.mp4:
      case SlideDeckMaterialType.featuredLarge:
        if (!material.materialLink) {
          errors.push({
            key: ErrorKeys.MATERIAL_MISSING_LINK,
            options,
          })
        }
        break
    }
  })

  return errors
}

export const validateSlideCount = async (
  context: Pick<
    ValidationContext,
    'slideDeckId' | 'slides' | 'slideDeck' | 'firstSlideId'
  > & {
    repository: FirebaseRepository
    timeout?: number
  }
): Promise<ValidationError | null> => {
  const serverSlideCount = context.slideDeck.slideDeckSlideCount
  const {
    repository,
    slideDeckId,
    slides,
    firstSlideId,
    timeout = 5000,
  } = context

  if (slides.length === 0) return null

  const localSlideCount = slides.length
  if (localSlideCount === serverSlideCount) return null

  if (firstSlideId) {
    await touchSlide(repository, {
      slideDeckId,
      slideId: firstSlideId,
    })
  }

  const [, err] = await noTryAsync(() =>
    when(() => serverSlideCount === localSlideCount, { timeout })
  )

  return err ? { key: ErrorKeys.EXPERIENCE_SLIDE_COUNT_MISMATCH } : null
}

export const validateEndOfSessionQuiz = (
  context: ValidationContext
): ValidationError[] => {
  const errors: ValidationError[] = []
  const slides = context.slides

  const endOfSessionQuizIndex = slides.findIndex(
    (slide) => slide.slideType === SlideType.endOfSession
  )

  if (endOfSessionQuizIndex > -1) {
    // End of session quiz should be the last slide, if not - error
    if (endOfSessionQuizIndex !== slides.length - 1) {
      const endOfSessionQuiz = slides[endOfSessionQuizIndex]
      errors.push({
        key: ErrorKeys.EXPERIENCE_SLIDE_END_OF_SESSION_QUIZ_NOT_LAST_SLIDE,
        options: {
          slideName: endOfSessionQuiz.slideName,
        },
      })
    }
  }

  return errors
}
