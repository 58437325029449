import z from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeNumber,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalNumber,
  nullSafeOptionalString,
  nullSafeString,
} from '../shared/schema'
import { SlideType } from './types'

// this is the dart Slide definition
// required String slideName, default: ''
// required SlideType slideType, default: SlideType.none
// required String slideDescription,
// required int slideDuration,
// String? slideImageURL,
// int? slideOrder,
// String? slideStorageURL,
// int? slideVideoDuration,
// String? slideVideoError,
// String? slideVideoURL,
// int? updatedAt,
export const schema = z.object({
  slideName: nullSafeString(''),
  slideType: z.preprocess((val) => {
    return val ?? SlideType.image
  }, z.nativeEnum(SlideType)),
  slideDescription: z.string(),
  slideDuration: z.number(),
  slideImageURL: nullSafeOptionalString(),
  slideImageAltText: nullSafeOptionalString(),
  slideOrder: nullSafeNumber(999),
  slideStorageStreamingURL: nullSafeOptionalString(),
  slideStorageURL: nullSafeOptionalString(),
  slideVideoDuration: nullSafeOptionalNumber(),
  slideVideoError: nullSafeOptionalString(),
  slideVideoStreamingURL: nullSafeOptionalString(),
  slideVideoURL: nullSafeOptionalString(),
  slideMediaFilename: nullSafeOptionalString(),
  mediaId: nullSafeOptionalString(),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    slideName: '',
    slideType: SlideType.image,
    slideDescription: '',
    slideDuration: 999,
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export type FirestoreSlide = z.infer<typeof schema>
