import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  offset,
  flip,
  size,
  autoUpdate,
  FloatingPortal,
} from '@floating-ui/react'
import classNames from 'classnames'
import { ChevronLeft } from 'components/icons/ChevronLeft'
import type { FormFieldDefaults } from './types'
import { FormLabel } from './form/FormLabel'
import { FormError } from './form/FormError'
import { fixedForwardRef } from 'util/refs'
import type { SelectOption } from './BreakoutSelect'
import { CheckIcon } from 'components/icons/Check'
import { useCallback, useMemo, useRef, useState } from 'react'

interface BreakoutMultiSelectProps extends FormFieldDefaults<'select'> {
  options: SelectOption<string>[]
  // kind secondary is the default and represents the "primary" style in figma
  // tertiary is the secondary style in figma
  kind?: 'secondary' | 'tertiary'
  onChange?: (value: string[]) => void
  fullWidth?: boolean
  inputClassName?: string
  labelComponent?: React.ReactNode
  showChevron?: boolean
  labelGenerator?: (value: string[]) => string
  value: string[]
}
const MAX_SELECT_BOX_HEIGHT = 500

export const BreakoutMultiSelect = fixedForwardRef(function BreakoutMultiSelect(
  {
    disabled,
    kind = 'tertiary',
    showChevron = true,
    name,
    label,
    labelComponent,
    labelClass,
    labelGenerator,
    testId,
    options,
    error,
    errorClass,
    required,
    value,
    inputClassName,
    placeholder,
    onChange,
    fullWidth,
    className,
    ...rest
  }: BreakoutMultiSelectProps,
  fwdRef: React.ForwardedRef<HTMLSelectElement>
) {
  const [isOpen, setIsOpen] = useState(false)
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

  const { refs, floatingStyles, context } = useFloating<HTMLElement>({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: (open) => {
      if (disabled) return
      setIsOpen(open)
    },
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${Math.min(availableHeight, MAX_SELECT_BOX_HEIGHT)}px`,
            maxWidth: fullWidth ? undefined : `${rects.reference.width}px`,
            minWidth: `${rects.reference.width}px`,
          })
        },
      }),
    ],
  })

  const listRef = useRef<Array<HTMLElement | null>>([])
  const isTypingRef = useRef(false)

  const click = useClick(context, { event: 'mousedown' })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'listbox' })
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex: hoveredIndex,
    onNavigate: (index) => {
      if (index === null) return
      setHoveredIndex(index)
    },
    loop: false,
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [dismiss, role, listNav, click]
  )

  const handleSelect = useCallback(
    (selectedValue: string) => {
      if (!value) return

      const newValue = value.includes(selectedValue)
        ? value.filter((v) => v !== selectedValue) // Deselect if already selected
        : [...value, selectedValue] // Add new selection

      onChange?.(newValue) // Send the updated array of values to onChange
    },
    [value, onChange]
  )

  const selectedIndices = useMemo(
    () =>
      options.reduce<number[]>((indices, option, index) => {
        if (value.includes(option.value)) indices.push(index)
        return indices
      }, []),
    [value, options]
  )

  const dataTestId = testId || (name ? `${name}-select` : undefined)

  return (
    <div
      className={classNames('flex flex-col items-start gap-1', className, {
        'flex-1': fullWidth,
      })}
    >
      {labelComponent === undefined && (
        <FormLabel
          label={label}
          name={name}
          labelClass={labelClass}
          required={required}
        />
      )}
      {labelComponent}
      {name && (
        <select
          className="hidden"
          multiple
          {...rest}
          ref={fwdRef}
          name={name}
          value={value}
          onChange={() => {}}
        />
      )}
      <div
        data-testid={dataTestId}
        tabIndex={0}
        ref={refs.setReference}
        aria-label={label}
        aria-autocomplete="none"
        className={classNames(
          inputClassName,
          'flex h-[3.25rem] w-full select-none flex-row items-center justify-between rounded-2xl px-4 py-1 text-label-medium',
          {
            'bg-core-secondary': kind === 'secondary' && !disabled,
            'bg-core-tertiary': kind === 'tertiary' && !disabled,
            'bg-surface-dim': disabled,
            'text-on-surface-disabled': disabled,
            'border border-core-error': !!error,
            'cursor-not-allowed': disabled,
            'cursor-pointer': !disabled,
          }
        )}
        {...getReferenceProps()}
      >
        <div className="w-full overflow-hidden truncate">
          {value.length > 0 ? (
            labelGenerator ? (
              labelGenerator(value)
            ) : (
              value.map((selectedValue) => {
                const selectedOption = options.find(
                  (option) => option.value === selectedValue
                )
                return (
                  <span key={selectedValue}>{selectedOption?.label}, </span>
                )
              })
            )
          ) : (
            <span className="text-on-surface-disabled">
              {placeholder || 'Select From Options'}
            </span>
          )}
        </div>
        {showChevron && (
          <span className={classNames(isOpen ? 'rotate-90' : 'rotate-270')}>
            <ChevronLeft size={14} />
          </span>
        )}
      </div>
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              className={classNames(
                'z-[9999] min-w-24 space-y-1 overflow-y-auto overscroll-contain rounded-2xl p-4 text-label-medium shadow-xl',
                {
                  'bg-core-secondary': kind === 'secondary',
                  'bg-core-tertiary': kind === 'tertiary',
                }
              )}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              {options.map((option, i) => {
                const hovered = i === hoveredIndex
                const selected = selectedIndices.includes(i)
                return (
                  <div
                    key={
                      option.key !== undefined
                        ? option.key
                        : (option.value as string | number)
                    }
                    ref={(node) => {
                      listRef.current[i] = node
                    }}
                    role="option"
                    tabIndex={hovered ? 0 : -1}
                    aria-selected={hovered}
                    className="cursor-pointer"
                    {...getItemProps({
                      // Handle pointer select.
                      onClick() {
                        handleSelect?.(option.value)
                      },
                      // Handle keyboard select.
                      onKeyDown(event) {
                        if (event.key === 'Enter') {
                          event.preventDefault()
                          handleSelect?.(option.value)
                        }

                        if (event.key === ' ' && !isTypingRef.current) {
                          event.preventDefault()
                          handleSelect?.(option.value)
                        }
                      },
                    })}
                  >
                    <div
                      data-testid={dataTestId + `-option-${option.value}`}
                      className={classNames(
                        'cursor-pointer rounded-lg border-transparent p-4 py-2 outline-none',
                        {
                          'bg-surface-dim': selected,
                          'bg-core-secondary':
                            (!kind || kind === 'tertiary') &&
                            hovered &&
                            !selected,
                          'bg-core-tertiary':
                            kind === 'secondary' && hovered && !selected,
                        }
                      )}
                    >
                      <div className="flex flex-row">
                        {typeof option.label === 'string' ? (
                          <span>{option.label}</span>
                        ) : (
                          option.label
                        )}
                        {
                          <CheckIcon
                            size={16}
                            className={classNames('ml-auto', {
                              'text-core-primary': selected,
                              'text-transparent': !selected,
                            })}
                          />
                        }
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
      <FormError error={error} errorClass={errorClass} />
    </div>
  )
})
