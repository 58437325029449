import { action, computed, makeObservable, observable } from 'mobx'
import type { MeetingCubit } from '../../MeetingCubit'
import { SlideType } from '../../../firestore/Slide/types'
import type { SlideModel } from '../../../models/SlideModel'
import type { Media } from '../../../models/Media'
import { fetchMedia } from '../../../firestore/Media'

export class Slide {
  id: string
  name: string
  index: number
  duration: number
  slideVideoDuration?: number
  slideVideoURL: string | null = null
  slideVideoStreamingURL: string | null = null
  slideImageURL: string | null = null
  slideImageAltText: string | null = null
  description: string
  type: SlideType
  order: number
  meeting: MeetingCubit
  mediaId: string | null
  media: Media | null

  constructor(meeting: MeetingCubit, model: SlideModel, index: number) {
    this.meeting = meeting
    const data = model.data
    this.id = model.id
    this.index = index
    this.name = data.slideName
    this.duration = data.slideDuration
    this.description = data.slideDescription
    this.type = data.slideType
    this.order = data.slideOrder || 0
    this.slideVideoDuration = data.slideVideoDuration
    this.slideVideoStreamingURL = data.slideVideoStreamingURL || null
    this.slideVideoURL = data.slideVideoURL || null
    this.slideImageURL = data.slideImageURL || null
    this.slideImageAltText = data.slideImageAltText || null
    this.mediaId = data.mediaId || null
    this.media = null

    makeObservable(this, {
      duration: true,
      media: observable,
      mediaId: observable,
      titleIndex: computed,
      hasMedia: computed,
      updateDuration: action,
      setMedia: action,
    })

    this.afterConstructor()
  }

  // This is used un subclasses to not need to super() the constructor
  afterConstructor() {}

  get hasMedia() {
    return !!this.mediaId
  }

  async loadMedia() {
    if (this.mediaId) {
      const media = await fetchMedia(this.meeting.repository, {
        mediaId: this.mediaId,
      })

      this.setMedia(media)
    }
  }

  setMedia(media: Media) {
    this.media = media
  }

  get durationInMinutes() {
    let duration = 0
    if (this.slideVideoDuration) {
      duration = Math.ceil(this.slideVideoDuration / 60)
    } else {
      duration = Math.ceil(this.duration / 60)
    }
    return duration
  }

  updateDuration(duration: number) {
    this.duration = duration
  }

  get isTimedSlide(): boolean {
    if (this.slideVideoDuration && this.isVideoSlide) return true
    return this.duration > 0
  }

  get titleIndex(): number {
    return 0
  }

  get hasImage(): boolean {
    return !!this.slideImageURL
  }

  get altText(): string {
    return this.media?.data.mediaAltText || this.slideImageAltText || this.name
  }

  get isVideoSlide() {
    return (
      this.type === SlideType.video ||
      (this.type === SlideType.processingData && !this.hasImage)
    )
  }
}
